import { __decorate } from "tslib";
import { Mutation, State } from "@/types";
export class Menu {
    constructor() {
        this.mini = true;
    }
    changeMenu() {
        this.mini = !this.mini;
    }
}
__decorate([
    State()
], Menu.prototype, "mini", void 0);
__decorate([
    Mutation()
], Menu.prototype, "changeMenu", null);
