import { __decorate } from "tslib";
import { Getter, Mutation, State } from "@/types";
export class SupportingFilterModule {
    constructor() {
        this.supportFilter = "";
    }
    setSupportFilter(filter) {
        this.supportFilter = filter;
    }
    get getSupportFilter() {
        return this.supportFilter;
    }
}
__decorate([
    State()
], SupportingFilterModule.prototype, "supportFilter", void 0);
__decorate([
    Mutation()
], SupportingFilterModule.prototype, "setSupportFilter", null);
__decorate([
    Getter()
], SupportingFilterModule.prototype, "getSupportFilter", null);
