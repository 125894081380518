import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Absences extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "absences-reasons";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(absences) {
        try {
            await this.$post(this.baseURL, absences);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(absences) {
        try {
            await this.$put(`${this.baseURL}/${absences.id}`, absences);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(absences) {
        try {
            await this.$delete(`${this.baseURL}/${absences}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
