import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, MultiSelect, Icon, Radio, Toggler, Calendar, } from "@/ui-kit";
import "./addReports.scss";
import { addSeconds, modifyingDateToIso } from "@/helpers";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddReports = class AddReports extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            name: "",
            organization: this.store.orgList.currentOrg?.id,
            reportType: 1,
            dateTimeSettingsOption: 1,
            periodType: 1,
            periodSection: 1,
            beginDate: "",
            beginTime: "",
            endDate: "",
            endTime: "",
            departments: null,
            personsWithFIO: null,
        };
        this.reportTypes = [
            {
                id: 1,
                name: this.$l["firstInLastOut"],
            },
            {
                id: 2,
                name: this.$l["notWorkHour"],
            },
        ];
        this.errors = {
            name: "",
            beginDate: "",
            beginTime: "",
            endDate: "",
            endTime: "",
        };
        this.isPeriod = true;
        this.timeInterval = [
            {
                id: 1,
                name: this.$l["day"],
            },
            {
                id: 2,
                name: this.$l["week"],
            },
            {
                id: 3,
                name: this.$l["month"],
            },
        ];
        this.filtersInit = [
            {
                id: 1,
                title: "Подразделение",
                moduleApi: "subdivisions",
                func: (arr) => {
                    this.modalData.departments = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                listToEditValue: this.modalData.departments,
            },
            {
                id: 2,
                title: "Сотрудник",
                moduleApi: "employees",
                func: (arr) => {
                    this.modalData.personsWithFIO = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                filterSearchValue: "surname",
                listToEditValue: this.modalData.personsWithFIO,
            },
        ];
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data: { departments, personsWithFIO, ...data }, } = this.currDialog;
            if (name === "save") {
                this.modalData.id = data.id;
                this.isPeriod = data.dateTimeSettingsOption === 1;
                this.modalData.name = data.name;
                this.modalData.reportType = data.reportType;
                this.modalData.dateTimeSettingsOption = data.dateTimeSettingsOption;
                this.modalData.periodSection = data.periodSection;
                this.modalData.beginDate = data.beginDate;
                this.modalData.endDate = data.endDate;
                this.modalData.beginTime = data.beginTime;
                this.modalData.endTime = data.endTime;
                this.modalData.periodType = data.periodType;
                this.modalData.departments = this.editArrayChips(departments);
                this.modalData.personsWithFIO = this.editArrayChips(personsWithFIO);
                this.filtersInit[0].listToEditValue = this.modalData.departments;
                this.filtersInit[1].listToEditValue = this.modalData.personsWithFIO;
            }
        }
    }
    editArrayChips(data) {
        return data?.length ? data.map(({ id, ...item }) => id) : null;
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get defaultTime() {
        const date = new Date();
        return {
            beginDate: new Date().toISOString().split("T")[0],
            endDate: new Date(date.setDate(date.getDate() + 1))
                .toISOString()
                .split("T")[0],
            beginTime: "00:00",
            endTime: "00:00",
        };
    }
    resetAll() {
        this.isPeriod = true;
        this.filtersInit = this.filtersInit.map((item) => ({
            ...item,
            listToEditValue: null,
        }));
        this.modalData = Object.entries(this.modalData).reduce((acc, [key, val]) => {
            const typeDefault = [
                "reportType",
                "dateTimeSettingsOption",
                "periodType",
                "periodSection",
                "id",
                "organization",
            ];
            if (typeDefault.includes(key)) {
                acc[key] = 1;
                if (key === "id" || key === "organization") {
                    acc[key] = val;
                }
            }
            else {
                acc[key] = null;
            }
            return acc;
        }, {});
        this.errors = {
            ...this.errors,
            beginDate: "",
            beginTime: "",
            endDate: "",
            endTime: "",
        };
    }
    dataTypeHandler() {
        if (this.isPeriod) {
            this.modalData.periodSection = 1;
            this.modalData.periodType = 1;
            this.modalData.beginDate = null;
            this.modalData.beginTime = null;
            this.modalData.endDate = null;
            this.modalData.endTime = null;
            this.modalData.dateTimeSettingsOption = 1;
        }
        else {
            this.modalData.periodSection = null;
            this.modalData.periodType = null;
            this.modalData.beginDate = this.defaultTime.beginDate;
            this.modalData.endDate = this.defaultTime.endDate;
            this.modalData.beginTime = this.defaultTime.beginTime;
            this.modalData.endTime = this.defaultTime.endTime;
            this.modalData.dateTimeSettingsOption = 2;
        }
        this.errors = {
            ...this.errors,
            beginDate: "",
            beginTime: "",
            endDate: "",
            endTime: "",
        };
    }
    async addReport() {
        const success = await this.store.reports.add({
            ...this.modalData,
            beginDate: modifyingDateToIso(this.modalData.beginDate),
            endDate: modifyingDateToIso(this.modalData.endDate),
            beginTime: addSeconds(this.modalData.beginTime),
            endTime: addSeconds(this.modalData.endTime),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editReport() {
        const success = await this.store.reports.edit({
            ...this.modalData,
            beginDate: modifyingDateToIso(this.modalData.beginDate),
            endDate: modifyingDateToIso(this.modalData.endDate),
            beginTime: addSeconds(this.modalData.beginTime),
            endTime: addSeconds(this.modalData.endTime),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editReport()
                : this.addReport());
        }
        catch (e) {
            console.log(e);
        }
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddReports.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddReports.prototype, "addReport", null);
__decorate([
    Emit("success")
], AddReports.prototype, "editReport", null);
AddReports = __decorate([
    Component({
        components: {
            Calendar,
            Toggler,
            Radio,
            MultiSelect,
            Button,
            Input,
            Modal,
            Icon,
        },
    })
], AddReports);
export default AddReports;
