export const colors = {
    "main-color": "#3B429F",
    "main-hover": "#272B67",
    "dark-hover": "#272D79",
    "dark-click": "#10144E",
    "main-clicked": "#10144E",
    "text-color": "#000000",
    "border-color": "#C4C4C4",
    blue: "#C7D9FF",
    "med-blue": "#BBBFEC",
    "light-blue": "#E3E5FF",
    green: "#24B58D",
    "light-grey": "#EBEBEB",
    "light-pink": "#FFE7E7",
    red: "#DF2935",
    yellow: "#FDCA40",
    orange: "#FD8F40",
    white: "#ffffff",
    grey: "#BEBEBE",
};
export const MENU_SECTION_DEFAULT = [
    {
        id: "1",
        name: "Administration",
        icon: "administration",
        route: "Administration",
    },
    {
        id: "2",
        name: "FilingCabinet",
        icon: "filingCabinet",
        route: "FilingCabinet",
    },
    {
        id: "3",
        name: "Monitoring",
        icon: "monitoring",
        route: "Monitoring",
    },
    {
        id: "4",
        name: "TimeAttendance",
        icon: "timeAttendance",
        route: "TimeAttendance",
    },
];
export const Routes = {
    administration: [
        "readers",
        "zones",
        "accessPoints",
        "accessSchedules",
        "organization",
        "users",
    ],
    filingcabinet: ["subdivisions", "employees", "dismissed", "guestPasses"],
    monitoring: ["passlog", "configurations", "syslog"],
    timeattendance: [
        "dayIntervals",
        "workSchedules",
        "holidays",
        "reports",
        "adjustments",
        "absences",
    ],
};
export const ONLY_DIGIT = new RegExp("^[0-9]*$");
