import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, Calendar, Overlay, Search, Radio, Checkbox, SearchApi, } from "@/ui-kit";
import "./addAdjustments.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { addSeconds, modifyingDateToIso } from "@/helpers";
let AddAdjustments = class AddAdjustments extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            person: "",
            date: "",
            arrivalTime: "",
            departureTime: "",
            comment: "",
            personIdentificationType: 1,
        };
        this.employeesData = [];
        this.isClearName = false;
        this.isClearNumber = false;
        this.name = "";
        this.staffNumber = "";
        this.errors = {
            arrivalTime: "",
            departureTime: "",
            person: "",
            date: "",
            staffNumber: "",
            employees: "",
        };
    }
    created() {
        this.getReaderData();
    }
    async getReaderData() {
        try {
            await this.store.employees.get({
                skip: 0,
                filter: `organization eq ${this.store.orgList.currentOrg?.id}`,
            });
            this.employeesData = this.store.employees.list.map((item) => ({
                ...item,
                fio: `${item.surname} ${item.name} ${item.patronymic}`,
            }));
        }
        catch (e) {
            console.log(e, "readersData");
        }
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                if (data.personIdentificationType === 1) {
                    this.isClearName = true;
                    this.name = `${data.surname} ${data.name} ${data.patronymic}`;
                    this.staffNumber = "";
                }
                if (data.personIdentificationType === 2) {
                    this.isClearNumber = true;
                    this.staffNumber = data.staffNumber ?? "";
                    this.name = "";
                }
            }
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
        if (this.modalData.personIdentificationType === 1) {
            this.errors.employees = this.store.validationField.valid["person"];
        }
        if (this.modalData.personIdentificationType === 2) {
            this.errors.staffNumber = this.store.validationField.valid["person"];
        }
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    handleRadioButton(id) {
        if (id === 1) {
            this.staffNumber = "";
        }
        if (id === 2) {
            this.name = "";
        }
        this.modalData.personIdentificationType = id;
        this.modalData.person = "";
        this.errors.employees = "";
        this.errors.staffNumber = "";
    }
    getId(arr) {
        this.modalData.person = arr.id;
    }
    getIdAndName(arr) {
        this.name = `${arr.surname} ${arr.name} ${arr.patronymic}`;
        this.modalData.person = arr.id;
    }
    resetId(val) {
        if (!val.length) {
            this.modalData.person = "";
        }
    }
    async addAdjustment() {
        this.modalData.date = modifyingDateToIso(this.modalData.date);
        this.modalData.arrivalTime = addSeconds(this.modalData.arrivalTime);
        this.modalData.departureTime = addSeconds(this.modalData.departureTime);
        const success = await this.store.adjustments.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editAdjustment() {
        this.modalData.date = modifyingDateToIso(this.modalData.date);
        this.modalData.arrivalTime = addSeconds(this.modalData.arrivalTime);
        this.modalData.departureTime = addSeconds(this.modalData.departureTime);
        const success = await this.store.adjustments.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editAdjustment()
                : this.addAdjustment());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddAdjustments.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddAdjustments.prototype, "addAdjustment", null);
__decorate([
    Emit("success")
], AddAdjustments.prototype, "editAdjustment", null);
AddAdjustments = __decorate([
    Component({
        components: {
            SearchApi,
            Checkbox,
            Overlay,
            Input,
            Button,
            Modal,
            Calendar,
            Search,
            Radio,
        },
    })
], AddAdjustments);
export default AddAdjustments;
