import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Organization extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "organizations";
        this.baseURL_FOR_USER = "organizations-for-user";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getBaseForUser(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL_FOR_USER, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(organization) {
        try {
            await this.$post(this.baseURL, organization);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(organization) {
        try {
            await this.$put(`${this.baseURL}/${organization.id}`, organization);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(organizationId, confirmed = false) {
        try {
            await this.$delete(`${this.baseURL}/${organizationId}?confirm=${confirmed}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
