import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Readers extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "readers";
        this.baseURLSysInfo = "system-information";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getSysInfo() {
        try {
            const url = createGetUrlWithOptions(this.baseURLSysInfo);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getOpenDoor(id) {
        try {
            await this.$get(`${this.baseURL}/${id}/open-door`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getNonRegisteredPhotos(options) {
        try {
            const url = createGetUrlWithOptions(`${this.baseURL}/nonRegisteredPhotos`, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(readers) {
        try {
            await this.$post(this.baseURL, readers);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(reader) {
        try {
            await this.$put(`${this.baseURL}/${reader.id}`, reader);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(readerId, confirmed = false) {
        try {
            await this.$delete(`${this.baseURL}/${readerId}?confirm=${confirmed}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async search() {
        try {
            const { data } = await this.$get(`${this.baseURL}/search`);
            return Promise.resolve(data.items);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
