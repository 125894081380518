import { __decorate } from "tslib";
import "./leftMenu.scss";
import { Component, Vue } from "@/types";
import SectionRoute from "@/components/SectionRoute/SectionRoute.vue";
import { Icon } from "@/ui-kit";
import { childrenRouteRender } from "@/helpers/generateSubRoutes";
let LeftMenu = class LeftMenu extends Vue {
    get renderChildRoutes() {
        return childrenRouteRender(this.parentMenu ?? "administration");
    }
    get parentMenu() {
        return this.$route.matched[0].name;
    }
};
LeftMenu = __decorate([
    Component({
        components: { SectionRoute, Icon },
    })
], LeftMenu);
export default LeftMenu;
