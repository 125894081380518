import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./holidays.scss";
import { Table } from "@/ui-kit";
import { AddHolidays } from "@/components";
let Holidays = class Holidays extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Дата", class: "holidayDate" },
            { name: "Тип дня", class: "holidayType" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeDay(val) {
        if (val?.name === "remove") {
            if (val.data.holidayDate) {
                this.store.dialog.setDetails({
                    itemToConfirm: `${val.data.holidayDate
                        .split("-")
                        .reverse()
                        .join(".")}`,
                });
            }
        }
    }
};
__decorate([
    Ref("table")
], Holidays.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Holidays.prototype, "removeDay", null);
Holidays = __decorate([
    Component({
        components: { AddHolidays, Table },
    })
], Holidays);
export default Holidays;
