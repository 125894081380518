import { __decorate } from "tslib";
import { Component, Prop, useModule, Vue, Emit, Watch, } from "@/types";
import "./photoVerification.scss";
import { Checkbox, Table, Icon } from "@/ui-kit";
import { AddConfiguration } from "@/components";
import { getTime } from "@/helpers/timePattern";
let PhotoVerification = class PhotoVerification extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.isClassNegative = "no-info";
        this.photoData = {
            passPhoto: "/images/photo.jpg",
            referencePhoto: "/images/photo.jpg",
        };
    }
    socketConnect() {
        if (this.data) {
            const { passAllowed, passPhoto, referencePhoto = this.photoData.referencePhoto, } = this.data;
            this.isClassNegative = passAllowed ? "success" : "alarm";
            this.photoData.passPhoto = passPhoto || "/images/photo.jpg";
            this.photoData.referencePhoto = referencePhoto || "/images/photo.jpg";
        }
    }
    get timeStamp() {
        return getTime(this.data.timeStamp);
    }
    onKeyHandle() {
        return;
    }
};
__decorate([
    Prop({ required: true, type: Object })
], PhotoVerification.prototype, "data", void 0);
__decorate([
    Prop({ type: String, default: "" })
], PhotoVerification.prototype, "readerName", void 0);
__decorate([
    Watch("data")
], PhotoVerification.prototype, "socketConnect", null);
__decorate([
    Emit("onKeyHandle")
], PhotoVerification.prototype, "onKeyHandle", null);
PhotoVerification = __decorate([
    Component({
        components: { Icon, Checkbox, AddConfiguration, Table },
    })
], PhotoVerification);
export default PhotoVerification;
