const onFileAction = (data, fileName = "", isDownload = false) => {
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    if (isDownload) {
        link.download = `${fileName}.pdf`;
    }
    else {
        link.target = "_blank";
    }
    link.click();
    link.remove();
};
export default onFileAction;
