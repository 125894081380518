import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Ref } from "@/types";
import { Button, Input, Modal, Loader, Icon } from "@/ui-kit";
import "./cameraCapture.scss";
let CameraCapture = class CameraCapture extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.isLoading = false;
    }
    toggleCamera() {
        if (this.isCameraOpen) {
            this.isCameraOpen = false;
            this.isPhotoTaken = false;
            this.isShotPhoto = false;
            this.stopCameraStream();
        }
        else {
            this.isCameraOpen = true;
            this.createCameraElement();
        }
    }
    createCameraElement() {
        this.isLoading = true;
        const constraints = {
            audio: false,
            video: true,
        };
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
            this.camera.srcObject = stream;
        })
            .catch((error) => {
            this.store.notification.addNotification({
                type: "alert",
                message: "Веб-камера не найдена.",
            });
            this.close();
        })
            .finally(() => {
            this.isLoading = false;
        });
    }
    stopCameraStream() {
        let tracks = this.camera.srcObject.getTracks();
        tracks.forEach((track) => {
            track.stop();
        });
    }
    takePhotoFromCamera() {
        if (!this.isPhotoTaken) {
            this.isShotPhoto = true;
            const FLASH_TIMEOUT = 50;
            setTimeout(() => {
                this.isShotPhoto = false;
            }, FLASH_TIMEOUT);
        }
        const context = this.canvas.getContext("2d");
        this.isPhotoTaken = !this.isPhotoTaken;
        context?.drawImage(this.camera, 0, 0, 450, 337.5);
        const toBase64 = this.canvas.toDataURL();
        return toBase64;
    }
    close() {
        return;
    }
};
__decorate([
    Ref("camera")
], CameraCapture.prototype, "camera", void 0);
__decorate([
    Ref("canvas")
], CameraCapture.prototype, "canvas", void 0);
__decorate([
    Emit("takePhotoFromCamera")
], CameraCapture.prototype, "takePhotoFromCamera", null);
__decorate([
    Emit("close")
], CameraCapture.prototype, "close", null);
CameraCapture = __decorate([
    Component({
        components: { Loader, Icon, Button, Input, Modal },
    })
], CameraCapture);
export default CameraCapture;
