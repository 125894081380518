import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
import { Auth } from "@/helpers/Auth";
import { checkEndUrlConfig } from "@/helpers/checkEndUrlConfig";
export class Users extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = `users`;
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(`${checkEndUrlConfig(Auth.getWebGatewayUri)}api/${this.baseURL}`, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(user) {
        try {
            await this.$post(`${checkEndUrlConfig(Auth.getWebGatewayUri)}api/${this.baseURL}`, user);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(user) {
        try {
            await this.$put(`${checkEndUrlConfig(Auth.getWebGatewayUri)}api/${this.baseURL}/${user.id}`, user);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(userId) {
        try {
            await this.$delete(`${checkEndUrlConfig(Auth.getWebGatewayUri)}api/${this.baseURL}/${userId}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
