import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./workSchedules.scss";
import { Table } from "@/ui-kit";
import { AddSevenWorkSchedules, AddThirtyWorkSchedules } from "@/components";
let WorkSchedules = class WorkSchedules extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "График работы", class: "name" }];
        this.workScheduleEdit = "seven";
    }
    get showDialog() {
        return {
            isSeven: this.store.dialog.curr?.details === "seven",
            isThirty: this.store.dialog.curr?.details === "thirty",
        };
    }
    detailedWorkSchedules(val) {
        if (val?.workScheduleType === 1) {
            this.workScheduleEdit = "thirty";
        }
        if (val?.workScheduleType === 2) {
            this.workScheduleEdit = "seven";
        }
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], WorkSchedules.prototype, "table", void 0);
__decorate([
    Watch("store.tableRow.current", { immediate: true })
], WorkSchedules.prototype, "detailedWorkSchedules", null);
WorkSchedules = __decorate([
    Component({
        components: { AddSevenWorkSchedules, AddThirtyWorkSchedules, Table },
    })
], WorkSchedules);
export default WorkSchedules;
