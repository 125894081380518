/**
 * Обрезка до И. О.
 * @param val - Имя или Отчество
 */
const generateFIO = (val) => {
    if (val === "") {
        return "";
    }
    return isNaN(parseInt(val)) ? val.charAt(0) + "." : val;
};
const createFIO = (surname = "", name = "", patronymic = "") => {
    return `${surname} ${name} ${patronymic}`;
};
export { generateFIO, createFIO };
