import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Column, Button, Input, Modal } from "@/ui-kit";
import "./addOrganization.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddOrganization = class AddOrganization extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            name: "",
            accessSchedule: null,
        };
        this.errors = {
            name: "",
            accessSchedule: "",
        };
        this.accessSchedulesData = [];
    }
    async created() {
        try {
            await this.store.accessSchedules.get({
                skip: 0,
            });
            this.accessSchedulesData = this.store.accessSchedules.list;
        }
        catch (e) {
            console.log(e, "accessSchedulesData");
        }
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
            }
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    setOrganization(result) {
        this.modalData["accessSchedule"] = result;
    }
    async addOrganization() {
        const success = await this.store.organization.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editOrganization() {
        const success = await this.store.organization.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editOrganization()
                : this.addOrganization());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddOrganization.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddOrganization.prototype, "addOrganization", null);
__decorate([
    Emit("success")
], AddOrganization.prototype, "editOrganization", null);
AddOrganization = __decorate([
    Component({
        components: { Column, Button, Input, Modal },
    })
], AddOrganization);
export default AddOrganization;
