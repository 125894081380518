import { __decorate } from "tslib";
import { Component, Prop, Ref, Vue } from "@/types";
import "./tooltip.scss";
let Tooltip = class Tooltip extends Vue {
    constructor() {
        super(...arguments);
        this.trianglePlace = "";
        this.boxPlace = "";
    }
    mounted() {
        return this.$nextTick(() => {
            let tooltip = this.$refs.sizeRef;
            let getSizeTooltip = tooltip.getBoundingClientRect();
            if (tooltip) {
                this.boxPlace = getSizeTooltip.bottom - 10 + "px";
                this.trianglePlace = getSizeTooltip.width / 2 - 10 + "px";
            }
        });
    }
};
__decorate([
    Prop({
        default: "right",
        type: String,
        validator(value) {
            return ["right", "left"].includes(value);
        },
    })
], Tooltip.prototype, "placement", void 0);
__decorate([
    Prop({
        default: "",
        type: String,
    })
], Tooltip.prototype, "className", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], Tooltip.prototype, "hoveredHeader", void 0);
__decorate([
    Prop({
        default: "info",
        type: String,
        validator(value) {
            return ["info", "chosen"].includes(value);
        },
    })
], Tooltip.prototype, "tooltipType", void 0);
__decorate([
    Ref("sizeRef")
], Tooltip.prototype, "sizeRef", void 0);
Tooltip = __decorate([
    Component({
        components: {},
    })
], Tooltip);
export default Tooltip;
