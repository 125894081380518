import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal } from "@/ui-kit";
import "./addZone.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddZone = class AddZone extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            name: "",
        };
        this.errors = {
            name: "",
        };
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
            }
        }
    }
    isValid() {
        this.errors.name = this.store.validationField.valid["name"];
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    async addZones() {
        const success = await this.store.zones.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editZones() {
        const success = await this.store.zones.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editZones()
                : this.addZones());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddZone.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddZone.prototype, "addZones", null);
__decorate([
    Emit("success")
], AddZone.prototype, "editZones", null);
AddZone = __decorate([
    Component({
        components: { Button, Input, Modal },
    })
], AddZone);
export default AddZone;
