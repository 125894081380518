import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./absences.scss";
import { Table } from "@/ui-kit";
import { AddAbsences } from "@/components";
let Absences = class Absences extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Дата начала", class: "beginDate" },
            { name: "Дата окончания", class: "endDate" },
            { name: "Тип", class: "absenceReasonType" },
            { name: "Сотрудник", class: "surname" },
        ];
        this.typeAbsences = {
            1: this.$l["vacation"],
            2: this.$l["healthList"],
            3: this.$l["businessTrip"],
            4: this.$l["offWork"],
        };
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeAbsences(val) {
        if (val?.name === "remove") {
            this.store.dialog.setDetails({
                itemToConfirm: "empty",
            });
        }
    }
};
__decorate([
    Ref("table")
], Absences.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Absences.prototype, "removeAbsences", null);
Absences = __decorate([
    Component({
        components: { AddAbsences, Table },
    })
], Absences);
export default Absences;
