export { default as Icon } from "./Icon/Icon.vue";
export { default as Button } from "./Button/Button.vue";
export { default as Loader } from "./Loader/Loader.vue";
export { default as Input } from "./Input/Input.vue";
export { default as Toggler } from "./Toggler/Toggler.vue";
export { default as InputNumber } from "./InputNumber/InputNumber.vue";
export { default as Modal } from "./Modal/Modal.vue";
export { default as Search } from "./Search/Search.vue";
export { default as Checkbox } from "./Checkbox/Checkbox.vue";
export { default as Select } from "./Select/Select.vue";
export { default as Radio } from "./Radio/Radio.vue";
export { default as Pagination } from "./Table/Pagination/Pagination.vue";
export { default as SearchApi } from "./SearchApi/SearchApi.vue";
//TODO компоненты не подключаются, если импортируется не в том порядке(см.ссылку)
export { default as DropDown } from "./DropDown/DropDown.vue";
export { default as Column } from "./Column/Column.vue";
export { default as MultiSelect } from "./MultiSelect/MultiSelect.vue";
export { default as Overlay } from "./Overlay/Overlay.vue";
export { default as Calendar } from "./Calendar/Calendar.vue";
/////////////////////////////////////////////////////////////////////////////
export { default as Tooltip } from "./Tooltip/Tooltip.vue";
export { default as Table } from "./Table/Table.vue";
export { default as TableWS } from "./TableWS/TableWS.vue";
export { default as Notification } from "./Notification/Notification.vue";
//https://forum.vuejs.org/t/did-you-register-the-component-correctly-for-recursive-components-make-sure-to-provide-the-name/18292
