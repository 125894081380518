import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class ReadersModule {
    constructor() {
        this.readers = [];
        this.searchReaders = [];
        this.count = 0;
        this.axiosError = null;
    }
    setItems(readers) {
        this.readers = readers;
    }
    setSearchReaders(readers) {
        this.searchReaders = readers;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.readers;
    }
    get total() {
        return this.count;
    }
    get listSearchReader() {
        return this.searchReaders;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.readers.get(options);
            this.setItems(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async getSysInfo() {
        try {
            const data = await service.readers.getSysInfo();
            return Promise.resolve(data);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async getNonRegisteredPhotos(options) {
        try {
            const data = await service.readers.getNonRegisteredPhotos(options);
            return [data.photos];
        }
        catch (error) {
            this.setError(error);
            return [true, error];
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async getOpenDoor(id) {
        try {
            await service.readers.getOpenDoor(id);
            return [true];
        }
        catch (error) {
            this.setError(error);
            return [false, error];
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async add(readers) {
        try {
            await service.readers.add(readers);
            return Promise.resolve(true);
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async search() {
        try {
            const data = await service.readers.search();
            this.setSearchReaders(data);
        }
        catch (error) {
            this.setError(error);
        }
    }
    async edit(readers) {
        try {
            await service.readers.edit(readers);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async delete(payloads) {
        try {
            const { id, confirm = false } = payloads;
            await service.readers.delete(id, confirm);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
}
__decorate([
    State()
], ReadersModule.prototype, "readers", void 0);
__decorate([
    State()
], ReadersModule.prototype, "searchReaders", void 0);
__decorate([
    State()
], ReadersModule.prototype, "count", void 0);
__decorate([
    State()
], ReadersModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], ReadersModule.prototype, "setItems", null);
__decorate([
    Mutation()
], ReadersModule.prototype, "setSearchReaders", null);
__decorate([
    Mutation()
], ReadersModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], ReadersModule.prototype, "setError", null);
__decorate([
    Getter()
], ReadersModule.prototype, "list", null);
__decorate([
    Getter()
], ReadersModule.prototype, "total", null);
__decorate([
    Getter()
], ReadersModule.prototype, "listSearchReader", null);
__decorate([
    Getter()
], ReadersModule.prototype, "errorList", null);
__decorate([
    Action()
], ReadersModule.prototype, "get", null);
__decorate([
    Action()
], ReadersModule.prototype, "getSysInfo", null);
__decorate([
    Action()
], ReadersModule.prototype, "getNonRegisteredPhotos", null);
__decorate([
    Action()
], ReadersModule.prototype, "getOpenDoor", null);
__decorate([
    Action()
], ReadersModule.prototype, "add", null);
__decorate([
    Action()
], ReadersModule.prototype, "search", null);
__decorate([
    Action()
], ReadersModule.prototype, "edit", null);
__decorate([
    Action()
], ReadersModule.prototype, "delete", null);
