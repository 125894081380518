import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue } from "@/types";
import { Icon, Loader, Overlay, Button } from "@/ui-kit";
import "./readerPhotos.scss";
let ReaderPhotos = class ReaderPhotos extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.isLoading = true;
        this.indexChoose = -1;
        this.photoChoose = "";
        this.photos = [];
    }
    mounted() {
        this.getData();
    }
    async getData() {
        try {
            let endSplitISO = new Date().toISOString().split("T");
            let endDate = `${endSplitISO[0]}_${endSplitISO[1].slice(0, 8)}`;
            let date = new Date();
            date.setHours(date.getHours() - 1);
            let beginSplitDate = date.toISOString().split("T");
            let beginDate = `${beginSplitDate[0]}_${beginSplitDate[1].slice(0, 8)}`;
            const [photos, error = false] = await this.store.readers.getNonRegisteredPhotos({
                skip: 0,
                filter: `beginTime gt ${beginDate} and endTime lt ${endDate}`,
            });
            if (error) {
                return;
            }
            if (Array.isArray(photos)) {
                this.photos = photos;
            }
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.isLoading = false;
        }
    }
    onClickHandler(photo, index) {
        this.indexChoose = index;
        this.photoChoose = photo;
    }
    takePhotoFromReader() {
        return this.photoChoose;
    }
    close() {
        return;
    }
};
__decorate([
    Emit("takePhotoFromReader")
], ReaderPhotos.prototype, "takePhotoFromReader", null);
__decorate([
    Emit("close")
], ReaderPhotos.prototype, "close", null);
ReaderPhotos = __decorate([
    Component({
        components: {
            Overlay,
            Button,
            Loader,
            Icon,
        },
    })
], ReaderPhotos);
export default ReaderPhotos;
