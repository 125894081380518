import { __decorate } from "tslib";
import { Component, Vue } from "@/types";
import { Icon } from "@/ui-kit";
import { Auth } from "@/helpers/Auth";
let HeaderLogout = class HeaderLogout extends Vue {
    async logout() {
        try {
            await Auth.logout();
        }
        catch (e) {
            console.log(e);
        }
    }
};
HeaderLogout = __decorate([
    Component({
        components: { Icon },
    })
], HeaderLogout);
export default HeaderLogout;
