import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class ZonesModule {
    constructor() {
        this.zones = [];
        this.count = 0;
        this.axiosError = null;
    }
    setItems(zones) {
        this.zones = zones;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.zones;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.zones.get(options);
            this.setItems(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async add(zone) {
        try {
            await service.zones.add(zone);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async edit(zone) {
        try {
            await service.zones.edit(zone);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async delete(zonesId) {
        try {
            await service.zones.delete(zonesId);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
}
__decorate([
    State()
], ZonesModule.prototype, "zones", void 0);
__decorate([
    State()
], ZonesModule.prototype, "count", void 0);
__decorate([
    State()
], ZonesModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], ZonesModule.prototype, "setItems", null);
__decorate([
    Mutation()
], ZonesModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], ZonesModule.prototype, "setError", null);
__decorate([
    Getter()
], ZonesModule.prototype, "list", null);
__decorate([
    Getter()
], ZonesModule.prototype, "total", null);
__decorate([
    Getter()
], ZonesModule.prototype, "errorList", null);
__decorate([
    Action()
], ZonesModule.prototype, "get", null);
__decorate([
    Action()
], ZonesModule.prototype, "add", null);
__decorate([
    Action()
], ZonesModule.prototype, "edit", null);
__decorate([
    Action()
], ZonesModule.prototype, "delete", null);
