import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./reports.scss";
import { Table } from "@/ui-kit";
import { AddReports } from "@/components";
import { onFileAction } from "@/helpers";
let Reports = class Reports extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "Шаблон отчёта", class: "name" }];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    async downloadPDF(val) {
        if (val?.name === "pdf") {
            try {
                this.store.statusReport.changeStatusReport();
                const currReport = this.store.tableRow.current;
                const data = await this.store.reports.getReports(currReport?.id);
                this.store.statusReport.changeStatusReport();
                onFileAction(data, currReport?.name);
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], Reports.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Reports.prototype, "downloadPDF", null);
Reports = __decorate([
    Component({
        components: { AddReports, Table },
    })
], Reports);
export default Reports;
