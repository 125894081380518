import Vue from "vue";
import VueRouter from "vue-router";
import AdministrationChildren from "./Administration";
import FilingCabinetChildren from "./FilingCabinet";
import MonitoringChildren from "./Monitoring";
import TimeAttendanceChildren from "./TimeAttendance";
import { Login, Sections } from "@/views";
import { Auth } from "@/helpers/Auth";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        async beforeEnter(to, from, next) {
            await Auth.login();
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            layout: "AuthLayout",
        },
    },
    {
        path: "/sections",
        name: "Sections",
        component: Sections,
        meta: {
            layout: "SectionLayout",
        },
    },
    {
        path: "/administration",
        name: "Administration",
        redirect: ({ path }) => {
            return `${path}/${AdministrationChildren[0].path}`;
        },
        children: AdministrationChildren,
        meta: {
            id: 1,
            isProtected: true,
            layout: "LayoutDefault",
        },
    },
    {
        path: "/filing-cabinet",
        name: "FilingCabinet",
        redirect: ({ path }) => {
            return `${path}/${FilingCabinetChildren[0].path}`;
        },
        children: FilingCabinetChildren,
        meta: {
            id: 2,
            isProtected: true,
            layout: "LayoutDefault",
        },
    },
    {
        path: "/monitoring",
        name: "Monitoring",
        redirect: ({ path }) => {
            return `${path}/${MonitoringChildren[0].path}`;
        },
        children: MonitoringChildren,
        meta: {
            id: 3,
            isProtected: true,
            layout: "LayoutDefault",
        },
    },
    {
        path: "/time-attendance",
        name: "TimeAttendance",
        redirect: ({ path }) => {
            return `${path}/${TimeAttendanceChildren[0].path}`;
        },
        children: TimeAttendanceChildren,
        meta: {
            id: 4,
            isProtected: true,
            layout: "LayoutDefault",
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "/error",
        meta: {
            layout: "ErrorLayout",
        },
    },
    {
        path: "/error",
        name: "403",
        meta: {
            layout: "ErrorLayout",
        },
    },
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    Auth.isLoggedIn().then((isLoggedIn) => {
        //проверяем что страница не является страницой редиректа и пользователь не залогинен//
        if (to.path !== "/" && !isLoggedIn) {
            next({
                path: "/",
            });
        }
        else {
            if (isLoggedIn) {
                Auth.currentUser().then((routes) => {
                    const { sections } = routes;
                    const protectedRoute = to.matched.filter((record) => record.meta.isProtected);
                    if (protectedRoute) {
                        protectedRoute.filter((item) => {
                            if (Array.isArray(sections)) {
                                if (sections.includes(String(item.meta.id))) {
                                    return next();
                                }
                                next({
                                    name: "/error",
                                    query: {
                                        status: "403",
                                    },
                                });
                            }
                        });
                    }
                });
            }
        }
    });
    next();
});
export default router;
