import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Column, Button, InputNumber, Input, Modal, Calendar, Icon, Overlay, Checkbox, Toggler, } from "@/ui-kit";
import "./AddguestForm.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { ImgValidate } from "@/helpers/decorators/img";
import { CameraCapture, ReaderPhotos } from "@/components";
import { modifyingDateToIso } from "@/helpers";
let AddGuestPasses = class AddGuestPasses extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            surname: "",
            name: "",
            patronymic: "",
            rfid: "",
            document: "",
            phone: "",
            birthDate: "",
            identityCardStartDate: null,
            identityCardStopDate: null,
            photo: "",
            maxPassesNumber: 1,
            accessSchedule: null,
            accessPoint: null,
            organization: this.store.orgList.currentOrg?.id,
        };
        this.errors = {
            surname: "",
            name: "",
            patronymic: "",
            rfid: "",
            document: "",
            phone: "",
            birthDate: "",
            identityCardStartDate: "",
            identityCardStopDate: "",
            photo: "",
            maxPassesNumber: "",
            accessSchedule: "",
            accessPoint: "",
        };
        this.accessSchedulesData = [];
        this.accessPointData = [];
        this.avatar = "/images/photo.jpg";
        this.isUploadCamera = false;
        this.isUploadReader = false;
        this.isOnceAccess = true;
    }
    onToggle() {
        if (this.isOnceAccess) {
            this.modalData.maxPassesNumber = 1;
        }
        else {
            this.modalData.maxPassesNumber = null;
            this.modalData.identityCardStartDate = null;
            this.modalData.identityCardStopDate = null;
        }
        this.modalData.accessSchedule = null;
        this.modalData.accessPoint = null;
        this.errors.identityCardStartDate = "";
        this.errors.identityCardStopDate = "";
        this.errors.maxPassesNumber = "";
        this.errors.accessSchedule = "";
        this.errors.accessPoint = "";
    }
    created() {
        this.getData();
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                if (data.accessPoint) {
                    this.isOnceAccess = true;
                    this.modalData.accessSchedule = null;
                }
                if (data.accessSchedule) {
                    this.isOnceAccess = false;
                    this.modalData.accessPoint = null;
                }
                this.avatar = data.photo || "/images/photo.jpg";
                this.modalData.id = data.id;
            }
        }
    }
    async getData() {
        try {
            await Promise.all([
                this.store.accessSchedules.get({
                    top: "",
                    skip: 0,
                    orderby: "",
                    filter: "",
                }),
                this.store.accessPoints.get({
                    top: "",
                    skip: 0,
                    orderby: "",
                    filter: "",
                }),
            ]);
            this.accessSchedulesData = this.store.accessSchedules.list;
            this.accessPointData = this.store.accessPoints.list;
        }
        catch (e) {
            console.log(e, "accessSchedulesData");
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    uploadImage(e, ...error) {
        if (error.join("") !== "") {
            this.store.notification.addNotification({
                type: "error",
                message: error.join(""),
            });
            return;
        }
        const target = e.target;
        const file = target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (e) => {
            this.store.notification.addNotification({
                type: "error",
                message: "что-то пошло не так, попробуйте позже",
            });
            return;
        };
        reader.onload = (e) => {
            if (e?.target?.result) {
                this.avatar = e.target.result;
                this.modalData.photo = e.target.result;
            }
        };
    }
    takePhotoFromCamera(picFromCamera) {
        this.avatar = picFromCamera;
        this.modalData.photo = picFromCamera;
        this.isUploadCamera = false;
    }
    takePhotoFromReader(picFromTfr) {
        this.avatar = picFromTfr;
        this.modalData.photo = picFromTfr;
        this.isUploadReader = false;
    }
    resetImage() {
        this.avatar = "/images/photo.jpg";
        this.modalData.photo = "";
    }
    async addGuestPasses() {
        const birth = this.modalData.birthDate;
        const startDate = this.modalData.identityCardStartDate;
        const endDate = this.modalData.identityCardStopDate;
        const success = await this.store.guestPasses.add({
            ...this.modalData,
            birthDate: modifyingDateToIso(birth),
            identityCardStartDate: modifyingDateToIso(startDate),
            identityCardStopDate: modifyingDateToIso(endDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editGuestPasses() {
        const birth = this.modalData.birthDate;
        const startDate = this.modalData.identityCardStartDate;
        const endDate = this.modalData.identityCardStopDate;
        const success = await this.store.guestPasses.edit({
            ...this.modalData,
            birthDate: modifyingDateToIso(birth),
            identityCardStartDate: modifyingDateToIso(startDate),
            identityCardStopDate: modifyingDateToIso(endDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editGuestPasses()
                : this.addGuestPasses());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
    accessScheduleHandler(result) {
        this.modalData["accessSchedule"] = result;
    }
    accessPointHandler(result) {
        this.modalData["accessPoint"] = result;
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddGuestPasses.prototype, "isValid", null);
__decorate([
    ImgValidate
], AddGuestPasses.prototype, "uploadImage", null);
__decorate([
    Emit("success")
], AddGuestPasses.prototype, "addGuestPasses", null);
__decorate([
    Emit("success")
], AddGuestPasses.prototype, "editGuestPasses", null);
AddGuestPasses = __decorate([
    Component({
        components: {
            Toggler,
            InputNumber,
            Overlay,
            Column,
            CameraCapture,
            ReaderPhotos,
            Button,
            Input,
            Modal,
            Calendar,
            Icon,
            Checkbox,
        },
    })
], AddGuestPasses);
export default AddGuestPasses;
