import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class AccessPointsModule {
    constructor() {
        this.accessPoints = [];
        this.count = 0;
        this.axiosError = null;
    }
    setItems(accessPoints) {
        this.accessPoints = accessPoints;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.accessPoints;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.accessPoints.get(options);
            this.setItems(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async add(accessPoint) {
        try {
            await service.accessPoints.add(accessPoint);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async edit(accessPoint) {
        try {
            await service.accessPoints.edit(accessPoint);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async delete(accessPointId) {
        try {
            await service.accessPoints.delete(accessPointId);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
}
__decorate([
    State()
], AccessPointsModule.prototype, "accessPoints", void 0);
__decorate([
    State()
], AccessPointsModule.prototype, "count", void 0);
__decorate([
    State()
], AccessPointsModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], AccessPointsModule.prototype, "setItems", null);
__decorate([
    Mutation()
], AccessPointsModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], AccessPointsModule.prototype, "setError", null);
__decorate([
    Getter()
], AccessPointsModule.prototype, "list", null);
__decorate([
    Getter()
], AccessPointsModule.prototype, "total", null);
__decorate([
    Getter()
], AccessPointsModule.prototype, "errorList", null);
__decorate([
    Action()
], AccessPointsModule.prototype, "get", null);
__decorate([
    Action()
], AccessPointsModule.prototype, "add", null);
__decorate([
    Action()
], AccessPointsModule.prototype, "edit", null);
__decorate([
    Action()
], AccessPointsModule.prototype, "delete", null);
