import { __decorate } from "tslib";
import { Component, ModelSync, Prop, Vue } from "@/types";
import "./checkbox.scss";
import { Icon } from "@/ui-kit";
let Checkbox = class Checkbox extends Vue {
};
__decorate([
    ModelSync("checked", "change", { type: Boolean, required: true })
], Checkbox.prototype, "checkedValue", void 0);
__decorate([
    Prop({ type: Number, default: 1 })
], Checkbox.prototype, "order", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Checkbox.prototype, "parent", void 0);
Checkbox = __decorate([
    Component({
        components: { Icon },
    })
], Checkbox);
export default Checkbox;
