import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Column, Input, Modal } from "@/ui-kit";
import "./addUser.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { Storage } from "@/helpers/storage";
let AddUser = class AddUser extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.forAllSections = false;
        this.forAllOrganization = false;
        this.modalData = {
            login: "",
            password: "",
            accessPoints: [],
            sections: [],
            organizations: [],
            createdAt: "",
        };
        this.errors = {
            login: "",
            password: "",
            sections: "",
            organizations: "",
        };
        this.orgData = [];
        this.sectionsClient = [
            {
                id: 1,
                name: this.$l["Administration"],
            },
            {
                id: 2,
                name: this.$l["FilingCabinet"],
            },
            {
                id: 3,
                name: this.$l["Monitoring"],
            },
            {
                id: 4,
                name: this.$l["TimeAttendance"],
            },
        ];
    }
    async getOrgData() {
        try {
            await this.store.organization.get({
                top: "",
                skip: 0,
                orderby: "",
                filter: "",
            });
            this.orgData = this.store.organization.list;
        }
        catch (e) {
            console.log(e, "orgData");
        }
    }
    mounted() {
        this.getOrgData().then(() => {
            if (this.currDialog?.data) {
                const { name, data: { id, login, sections, organizations }, } = this.currDialog;
                if (name === "save") {
                    this.modalData.id = id;
                    this.modalData.login = login;
                    this.modalData.sections = sections;
                    this.forAllSections = this.sectionsClient.length === sections.length;
                    let index = 0;
                    this.orgData.forEach((item) => {
                        if (organizations.includes(item.id)) {
                            this.$set(this.modalData.organizations, index, item.id);
                            index++;
                        }
                    });
                    const orgLength = this.modalData.organizations.length;
                    this.forAllOrganization = this.orgData.length === orgLength;
                }
            }
        });
    }
    handleFunctional(sections) {
        this.modalData.sections = sections;
        if (this.modalData.sections.length === this.sectionsClient.length) {
            this.handleAllFunctional(true);
        }
    }
    handleOrganization(organization) {
        this.modalData.organizations = organization;
        if (this.modalData.organizations.length === this.orgData.length) {
            this.handleAllOrganization(true);
        }
    }
    handleAllFunctional(all) {
        this.modalData.sections = all
            ? Array.from(this.sectionsClient, (item) => item.id)
            : [];
    }
    handleAllOrganization(all) {
        this.modalData.organizations = all
            ? Array.from(this.orgData, (item) => item.id)
            : [];
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    async addUser() {
        const success = await this.store.users.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editUser() {
        const success = await this.store.users.edit({
            ...this.modalData,
        });
        /**
         * Попытка отвязки активной организации от пользователя
         * Для дропдауна в разделах
         */
        if (Storage.fetch("organization")) {
            if (!this.modalData.organizations.includes(Storage.fetch("organization").id)) {
                Storage.remove("organization");
                this.store.orgList.resetOrg();
            }
        }
        else {
            await this.activeOrgChange();
        }
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async activeOrgChange() {
        try {
            await this.store.organization.getBaseForUser({
                skip: 0,
            });
            if (this.store.organization.listForUser.length &&
                !Storage.fetch("organization")) {
                this.store.orgList.setCurrentOrg({
                    id: this.store.organization.listForUser[0].id,
                    name: this.store.organization.listForUser[0].name,
                    accessSchedule: this.store.organization.listForUser[0].accessSchedule,
                });
            }
        }
        catch (e) {
            console.log(e, "organization");
        }
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editUser()
                : this.addUser());
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddUser.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddUser.prototype, "addUser", null);
__decorate([
    Emit("success")
], AddUser.prototype, "editUser", null);
AddUser = __decorate([
    Component({
        components: { Button, Input, Modal, Column },
    })
], AddUser);
export default AddUser;
