import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, Checkbox, Column, DropDown, Calendar, Icon, } from "@/ui-kit";
import "./addThirtyWorkSchedules.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { modifyingDateToIso } from "@/helpers";
let AddThirtyWorkSchedules = class AddThirtyWorkSchedules extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            organization: this.store.orgList.currentOrg?.id,
            name: "",
            workScheduleType: 1,
            automaticShiftClosing: false,
            startDate: null,
            withHolidays: true,
            accessZones: [],
            workingIntervals: [],
        };
        this.errors = {
            name: "",
            accessZones: "",
            workingIntervals: "",
            startDate: "",
        };
        this.forAllZones = false;
        this.zones = [];
        this.dataWorkingIntervals = [];
    }
    async getData() {
        try {
            await Promise.all([
                this.store.zones.get({
                    skip: 0,
                    filter: "accessPointType eq 1",
                }),
                this.store.dayIntervals.get({
                    skip: 0,
                    filter: `organization eq ${this.store.orgList.currentOrg?.id}`,
                }),
            ]).then(() => {
                this.zones = this.store.zones.list;
                this.dataWorkingIntervals = this.store.dayIntervals.list;
            });
        }
        catch (e) {
            console.log(e);
        }
    }
    mounted() {
        this.getData().then(() => {
            if (this.currDialog?.data) {
                const { name, data } = this.currDialog;
                if (name === "save") {
                    this.modalData = {
                        ...formObjectHelper(this.modalData, data),
                    };
                    this.forAllZones = this.zones.length === data.accessZones.length;
                    this.modalData.workingIntervals = this.modalData.workingIntervals.map((item) => ({
                        ...item,
                        id: item.day,
                        name: `${this.$l["day"]} ${item.day} ${item.workingIntervalName}`,
                    }));
                }
            }
        });
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    get typedWorkSchedule() {
        //WorkSchedulesWeek: "недельный график работы",
        //WorkSchedulesMonth: "сменный график работы",
        return this.$l["modalTitle"]("WorkSchedulesMonth", this.currDialog?.name);
    }
    handleZones(zones) {
        this.modalData.accessZones = zones;
        if (this.modalData.accessZones.length === this.zones.length) {
            this.handleAllZones(true);
        }
    }
    handleAllZones(all) {
        this.modalData.accessZones = all
            ? Array.from(this.zones, (item) => item.id)
            : [];
    }
    addWorkingIntervals(item) {
        const lastElement = this.modalData.workingIntervals.length;
        const putLastElement = lastElement + 1;
        const maxDayInMonth = 31;
        if (lastElement === maxDayInMonth) {
            return;
        }
        this.modalData.workingIntervals = [
            ...this.modalData.workingIntervals,
            {
                id: putLastElement,
                name: `${this.$l["day"]} ${putLastElement} ${item.name}`,
                workingIntervalId: item.id,
                day: putLastElement,
            },
        ];
        this.errors.workingIntervals = "";
    }
    removeDay(idRemove) {
        let newWorkingInterval = [];
        const day = this.$l["day"];
        this.modalData.workingIntervals.forEach((item) => {
            /**
             * 1) Исключаем удалленный элмент(item.id !== idRemove) из нового массива
             * 2) Уменьшаем id элемента, если id больше удаляемового
             * 3) Заменяем удаленный день, последующим
             */
            if (item.id && item.id !== idRemove) {
                const decrementDay = item.id > idRemove ? item.day - 1 : item.day;
                const replaceTextDay = item.name?.replace(`${day} ${item.day}`, "");
                newWorkingInterval.push({
                    ...item,
                    id: decrementDay,
                    day: decrementDay,
                    name: `${day} ${decrementDay}` + replaceTextDay,
                });
            }
        });
        this.modalData.workingIntervals = newWorkingInterval;
    }
    async addWorkSchedule() {
        const success = await this.store.workSchedules.add({
            ...this.modalData,
            startDate: modifyingDateToIso(this.modalData.startDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editWorkSchedule() {
        const success = await this.store.workSchedules.edit({
            ...this.modalData,
            startDate: modifyingDateToIso(this.modalData.startDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editWorkSchedule()
                : this.addWorkSchedule());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddThirtyWorkSchedules.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddThirtyWorkSchedules.prototype, "addWorkSchedule", null);
__decorate([
    Emit("success")
], AddThirtyWorkSchedules.prototype, "editWorkSchedule", null);
AddThirtyWorkSchedules = __decorate([
    Component({
        components: {
            Icon,
            Calendar,
            DropDown,
            Checkbox,
            Button,
            Input,
            Modal,
            Column,
        },
    })
], AddThirtyWorkSchedules);
export default AddThirtyWorkSchedules;
