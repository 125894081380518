var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thirty-form"},[_c('Modal',{attrs:{"footer-position":'right'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.typedWorkSchedule)+" ")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"thirty-form__wrap"},[_c('div',{staticClass:"thirty-form__left"},[_c('div',{staticClass:"thirty-form__name"},[_c('Input',{attrs:{"label-position":'top',"errors":_vm.errors.name},on:{"onFocus":function($event){_vm.errors.name = ''}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$l["name"])+" ")]},proxy:true}]),model:{value:(_vm.modalData.name),callback:function ($$v) {_vm.$set(_vm.modalData, "name", $$v)},expression:"modalData.name"}})],1),_c('div',{staticClass:"thirty-form__zones"},[_c('Column',{attrs:{"forAll":_vm.forAllZones,"dataCheckboxes":_vm.modalData.accessZones,"data":_vm.zones,"height":298,"errors":_vm.errors.accessZones},on:{"toggleCheckbox":_vm.handleZones,"toggleAllCheckbox":_vm.handleAllZones,"removeError":function($event){_vm.errors.accessZones = ''}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"seven-form__twosided"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['zonesList'])}}),_c('Icon',{attrs:{"name":'two-sided',"color":'black',"size":[22, 22],"title":'Доступны зоны, добавленные в двухсторонние точки доступа.'}})],1)]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(contentProp.name)+" ")])]}}])})],1)]),_c('div',{staticClass:"thirty-form__right"},[_c('div',{staticClass:"thirty-form__date"},[_c('div',{staticClass:"thirty-form__calendar"},[_c('div',{staticClass:"thirty-form__calendar-title"},[_vm._v(" "+_vm._s(_vm.$l["beginDate"])+" ")]),_c('Calendar',{attrs:{"errors":_vm.errors.startDate},on:{"focus":function($event){_vm.errors.startDate = ''}},model:{value:(_vm.modalData.startDate),callback:function ($$v) {_vm.$set(_vm.modalData, "startDate", $$v)},expression:"modalData.startDate"}})],1),_c('div',{staticClass:"thirty-form__checkboxes"},[_c('div',{staticClass:"thirty-form__ignore"},[_c('Checkbox',{attrs:{"order":0},model:{value:(_vm.modalData.withHolidays),callback:function ($$v) {_vm.$set(_vm.modalData, "withHolidays", $$v)},expression:"modalData.withHolidays"}},[[_vm._v(" "+_vm._s(_vm.$l["ignoreHolidays"])+" ")]],2)],1),_c('div',{staticClass:"thirty-form__closed"},[_c('Checkbox',{attrs:{"order":0},model:{value:(_vm.modalData.automaticShiftClosing),callback:function ($$v) {_vm.$set(_vm.modalData, "automaticShiftClosing", $$v)},expression:"modalData.automaticShiftClosing"}},[[_vm._v(" "+_vm._s(_vm.$l["autoClose"])+" ")]],2)],1)])]),_c('div',{staticClass:"thirty-form__intervals"},[_c('div',{staticClass:"thirty-form__list"},[_c('div',{staticClass:"thirty-form__interval-list"},[_c('Column',{attrs:{"data":_vm.dataWorkingIntervals,"height":288,"withSearch":false,"withCheckbox":false,"isActiveHandle":false},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['intervalList'])}})]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"name-intervals"},[_c('span',[_vm._v(_vm._s(contentProp.name))]),_c('Icon',{attrs:{"color":'green',"name":'add',"size":[20, 20]},on:{"click":function($event){return _vm.addWorkingIntervals(contentProp)}}})],1)]}}])})],1)]),_c('div',{staticClass:"thirty-form__list"},[_c('div',{staticClass:"thirty-form__working-list"},[_c('div',{staticStyle:{"text-align":"left"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$l['daysInGraph'])}}),_c('span',[_vm._v(": "+_vm._s(_vm.modalData.workingIntervals.length))])]),_c('Column',{attrs:{"data":_vm.modalData.workingIntervals,"height":288,"withSearch":false,"withCheckbox":false,"isActiveHandle":false,"errors":_vm.errors.workingIntervals},on:{"removeError":function($event){_vm.errors.workingIntervals = ''}},scopedSlots:_vm._u([{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"thirty-form__counter"},[_c('span',[_vm._v(_vm._s(contentProp.name))]),_c('Icon',{attrs:{"name":'cross',"color":'main-color',"size":[30, 30]},on:{"click":function($event){return _vm.removeDay(contentProp.id)}}})],1)]}}])})],1)])])])])]},proxy:true},{key:"footer",fn:function(ref){
var buttonType = ref.buttonType;
return [_c('Button',{attrs:{"bg-color":'purple'},on:{"clickHandler":_vm.successForm}},[_vm._v(" "+_vm._s(buttonType)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }