import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, Radio, Calendar, Overlay } from "@/ui-kit";
import "./addHolidays.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { Storage } from "@/helpers/storage";
import { addSeconds, modifyingDateToIso } from "@/helpers";
let AddHolidays = class AddHolidays extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            organization: this.store.orgList.currentOrg?.id,
            period: Storage.fetch("holidays").id,
            holidayDate: "",
            holidayType: 1,
            decreaseTime: "",
            transferDate: "",
        };
        this.errors = {
            holidayDate: "",
            decreaseTime: "",
            transferDate: "",
        };
        this.daysType = [
            {
                id: 1,
                text: this.$l["festiveDay"],
            },
            {
                id: 2,
                text: this.$l["shortDay"],
            },
            {
                id: 3,
                text: this.$l["workDay"],
            },
        ];
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                this.setRadio(data.holidayType);
            }
        }
    }
    setRadio(id) {
        if (id === 1) {
            this.modalData.decreaseTime = "";
            this.modalData.transferDate = "";
        }
        this.errors.holidayDate = "";
        this.errors.decreaseTime = "";
        this.errors.transferDate = "";
        this.modalData.holidayType = id;
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    async addHoliday() {
        const success = await this.store.holidays.add({
            ...this.modalData,
            decreaseTime: addSeconds(this.modalData.decreaseTime),
            holidayDate: modifyingDateToIso(this.modalData.holidayDate),
            transferDate: modifyingDateToIso(this.modalData.transferDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editHoliday() {
        const success = await this.store.holidays.edit({
            ...this.modalData,
            decreaseTime: addSeconds(this.modalData.decreaseTime),
            holidayDate: modifyingDateToIso(this.modalData.holidayDate),
            transferDate: modifyingDateToIso(this.modalData.transferDate),
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editHoliday()
                : this.addHoliday());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddHolidays.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddHolidays.prototype, "addHoliday", null);
__decorate([
    Emit("success")
], AddHolidays.prototype, "editHoliday", null);
AddHolidays = __decorate([
    Component({
        components: { Overlay, Input, Button, Modal, Radio, Calendar },
    })
], AddHolidays);
export default AddHolidays;
