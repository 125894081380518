import { __decorate } from "tslib";
import { Component, Emit, Prop, Ref, VModel, Vue } from "@/types";
import "./input.scss";
import { Icon } from "@/ui-kit";
import { mask } from "@/helpers/maskGenerate";
let Input = class Input extends Vue {
    constructor() {
        super(...arguments);
        this.isHidePass = true;
        this.watchError = false;
    }
    onFocus() {
        if (this.watchError) {
            this.watchError = false;
        }
        return;
    }
    onEnter() {
        return;
    }
    onBlur() {
        return;
    }
    iconShow() {
        this.isHidePass = !this.isHidePass;
        this.$nextTick(() => this.inputRef.focus());
    }
    showError() {
        if (!this.errors) {
            return;
        }
        this.watchError = true;
    }
    get inputStyle() {
        let main, label;
        if (this.labelPosition) {
            (main = `input--${this.labelPosition}`),
                (label = `input__label--${this.labelPosition}`);
        }
        return {
            main: main ?? "",
            label: label ?? "",
        };
    }
    /***
     * рефактор
     */
    get types() {
        if (this.type === "password" && this.isHidePass) {
            return this.type;
        }
        if (this.type === "number") {
            return this.type;
        }
        if (this.type === "hidden") {
            return this.type;
        }
        return "text";
    }
    get installMask() {
        if (this.mask && typeof this.inputVal === "string") {
            return mask(this.inputVal)[this.mask](this.inputVal);
        }
        return "";
    }
};
__decorate([
    Ref("inputRef")
], Input.prototype, "inputRef", void 0);
__decorate([
    VModel({ type: [String, Number] })
], Input.prototype, "inputVal", void 0);
__decorate([
    Prop({ type: String })
], Input.prototype, "placeholder", void 0);
__decorate([
    Prop({ type: Boolean })
], Input.prototype, "disabled", void 0);
__decorate([
    Prop({ type: [String, Boolean], default: false })
], Input.prototype, "labelPosition", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Input.prototype, "isPass", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Input.prototype, "isSearch", void 0);
__decorate([
    Prop({ type: [String, Number], default: null })
], Input.prototype, "errors", void 0);
__decorate([
    Prop({ type: String, default: "text" })
], Input.prototype, "type", void 0);
__decorate([
    Prop({ type: String, default: "" })
], Input.prototype, "mask", void 0);
__decorate([
    Prop({ type: Number, default: 32 })
], Input.prototype, "maxlength", void 0);
__decorate([
    Prop({ type: String, default: "on" })
], Input.prototype, "autocomplete", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Input.prototype, "isLeftErrors", void 0);
__decorate([
    Emit("onFocus")
], Input.prototype, "onFocus", null);
__decorate([
    Emit("onEnter")
], Input.prototype, "onEnter", null);
__decorate([
    Emit("onBlur")
], Input.prototype, "onBlur", null);
Input = __decorate([
    Component({
        components: { Icon },
        inheritAttrs: false,
    })
], Input);
export default Input;
