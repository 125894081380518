import { __decorate } from "tslib";
import { Component, Emit, Prop, useModule, Vue } from "@/types";
import "./modal.scss";
import { Button, Icon, Loader } from "@/ui-kit";
let Modal = 
/**TODO
 *
 * Убрать из компонентов template = footer
 *     <slot name="footer" :buttonType="buttonText">
       <Button :bg-color="'purple'" @clickHandler="onClick">
       {{ buttonText }}
       </Button>
       </slot>
 */
class Modal extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.loading = false;
    }
    beforeMount() {
        window.addEventListener("keyup", this.onEscapeKeyUp);
        this.$once(`hook:destroyed`, () => {
            document.removeEventListener(`keydown`, this.onEscapeKeyUp);
        });
    }
    setLoading(isLoading) {
        this.loading = isLoading;
    }
    get getTitleForm() {
        return this.$l["modalTitle"](this.$route.name, this.showDialog?.name);
    }
    get showDialog() {
        return this.store.dialog.curr;
    }
    get buttonText() {
        return this.$l[this.showDialog?.name];
    }
    get footerStyle() {
        return `modal__footer--${this.footerPosition}`;
    }
    onEscapeKeyUp(event) {
        if (event.key === "Escape") {
            this.close();
        }
    }
    close() {
        this.store.dialog.removeDialog();
        return;
    }
};
__decorate([
    Prop({ default: "center", type: String })
], Modal.prototype, "footerPosition", void 0);
__decorate([
    Emit("close")
], Modal.prototype, "close", null);
Modal = __decorate([
    Component({
        components: { Loader, Icon, Button },
    })
    /**TODO
     *
     * Убрать из компонентов template = footer
     *     <slot name="footer" :buttonType="buttonText">
           <Button :bg-color="'purple'" @clickHandler="onClick">
           {{ buttonText }}
           </Button>
           </slot>
     */
], Modal);
export default Modal;
