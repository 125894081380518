import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Checkbox, Column, DropDown, Input, Modal, Overlay, } from "@/ui-kit";
import "./addAccess.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddAccessPoints = class AddAccessPoints extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.sidedType = [
            { id: 0, name: "Односторонняя" },
            { id: 1, name: "Двухсторонняя" },
        ];
        this.type = this.sidedType[0].name;
        this.outReaderAvailable = true;
        this.readersIn = [];
        this.zonesIn = [];
        this.readersOut = [];
        this.zonesOut = [];
        this.modalData = {
            name: "",
            type: 0,
            entranceReader: null,
            entranceZone: null,
            exitZone: null,
            exitReader: null,
        };
        this.errors = {
            name: "",
            entranceReader: "",
            entranceZone: "",
            exitReader: "",
            exitZone: "",
            entranceReaderId: "",
            entranceZoneId: "",
            exitReaderId: "",
            exitZoneId: "",
        };
    }
    created() {
        this.getData();
    }
    async getData() {
        try {
            await Promise.all([
                this.store.readers.get({
                    top: "",
                    skip: 0,
                    orderby: "",
                    filter: "",
                }),
                this.store.zones.get({
                    top: "",
                    skip: 0,
                    orderby: "",
                    filter: "",
                }),
            ]);
            this.readersIn = this.store.readers.list;
            this.readersOut = this.store.readers.list;
            this.zonesIn = this.store.zones.list;
            this.zonesOut = this.store.zones.list;
            if (!this.currDialog?.data) {
                const readersModify = [];
                this.store.accessPoints.list.forEach((item) => {
                    readersModify.push(item.entranceReaderId, item.exitReaderId);
                });
                const notBusyReaders = this.readersIn.filter((item) => {
                    return !readersModify.includes(item.id);
                });
                this.readersIn = notBusyReaders;
                this.readersOut = notBusyReaders;
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data: { entranceReaderId, entranceReaderName, entranceZoneId, entranceZoneName, exitReaderId, exitReaderName, exitZoneId, exitZoneName, id, name: nameval, type: sideSelected, }, } = this.currDialog;
            if (name === "save") {
                if (sideSelected !== 0) {
                    this.modalData.exitReader = exitReaderId;
                    this.modalData.exitZone = exitZoneId;
                }
                this.modalData.id = id;
                this.modalData.entranceReader = entranceReaderId;
                this.modalData.entranceZone = entranceZoneId;
                this.modalData.name = nameval;
                this.chooseType({
                    id: sideSelected,
                    name: sideSelected === 0 ? "Односторонняя" : "Двухсторонняя",
                });
            }
        }
    }
    readersInHandler(result) {
        this.modalData["entranceReader"] = result;
    }
    readersOutHandler(result) {
        this.modalData["exitReader"] = result;
    }
    zonesInHandler(result) {
        this.modalData["entranceZone"] = result;
    }
    zonesOutHandler(result) {
        this.modalData["exitZone"] = result;
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    chooseType({ id, name }) {
        if (id === 0) {
            this.modalData["exitZone"] = null;
            this.modalData["exitReader"] = null;
        }
        this.modalData.type = id;
        this.outReaderAvailable = name === "Односторонняя";
        this.errors.exitReaderId = "";
        this.errors.exitZoneId = "";
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    async addAccess() {
        const success = await this.store.accessPoints.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editAccess() {
        const success = await this.store.accessPoints.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editAccess()
                : this.addAccess());
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddAccessPoints.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddAccessPoints.prototype, "addAccess", null);
__decorate([
    Emit("success")
], AddAccessPoints.prototype, "editAccess", null);
AddAccessPoints = __decorate([
    Component({
        components: { Overlay, Column, DropDown, Checkbox, Button, Input, Modal },
    })
], AddAccessPoints);
export default AddAccessPoints;
