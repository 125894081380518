import { __decorate } from "tslib";
const NOW = new Date();
import { Component, Vue, Emit, Watch, Prop, VModel, Ref } from "@/types";
import { Input, Icon, Button } from "@/ui-kit";
import "./calendar.scss";
let Calendar = class Calendar extends Vue {
    constructor() {
        super(...arguments);
        this.show = false;
        this.date = {
            day: NOW.getDate(),
            month: NOW.getMonth(),
            year: NOW.getFullYear(),
        };
        this.days = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];
        this.months = [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ];
        this.clickedDay = null;
        this.inputValue = "";
        this.years = [];
        this.showYears = false;
        this.showMonth = false;
        this.windowHeight = 0;
        this.calendar = new Date();
    }
    getWindowHeight() {
        this.windowHeight = document.documentElement.clientHeight;
    }
    initVal(val) {
        if (typeof val === "string" && val !== "") {
            this.inputValue = val.split("-").reverse().join(".");
            const splitDateToView = this.inputValue.split(".");
            this.date = {
                day: parseInt(splitDateToView[0]),
                month: parseInt(splitDateToView[1]) - 1,
                year: parseInt(splitDateToView[2]),
            };
            this.clickedDay = parseInt(splitDateToView[0]);
        }
    }
    resetDateToEmpty(isResetDate) {
        if (isResetDate) {
            this.inputValue = "";
        }
    }
    mounted() {
        this.$nextTick().then(() => {
            window.addEventListener("resize", this.getWindowHeight);
            this.getWindowHeight();
        });
    }
    updated() {
        if (this.show) {
            let calendar = this.calendarSize, calendarHeight = calendar.getBoundingClientRect().height, calendarInput = this.calendarInput.getBoundingClientRect(), bottomDifference = this.windowHeight - calendarInput.bottom;
            if (bottomDifference > calendarHeight ||
                calendarInput.top < calendarHeight) {
                calendar.style.top = calendarInput.height + "px";
            }
            else {
                calendar.style.top = -calendarHeight + "px";
            }
        }
    }
    get currYear() {
        return this.date.year;
    }
    set currYear(val) {
        this.date.year = val;
    }
    get currMonth() {
        return this.date.month;
    }
    set currMonth(val) {
        this.date.month = val;
    }
    get currDay() {
        return this.date.day;
    }
    set currDay(val) {
        this.date.day = val;
    }
    get currDayChosen() {
        if (this.date.month === NOW.getMonth() &&
            this.date.year === NOW.getFullYear()) {
            return NOW.getDate();
        }
        return undefined;
    }
    get daysInMonth() {
        return new Date(this.currYear, this.currMonth + 1, 0).getDate();
    }
    get lastDateOfPrevMonth() {
        return new Date(this.currYear, this.currMonth, 0).getDate();
    }
    get qtyDaysPrevMonth() {
        return new Date(this.currYear, this.currMonth, 0).getDay();
    }
    get daysOfPrevMonth() {
        return (this.qtyDaysPrevMonth &&
            Array.from({ length: this.qtyDaysPrevMonth }, (v, k) => this.lastDateOfPrevMonth - this.qtyDaysPrevMonth + (k + 1)));
    }
    get qtyDaysNextMonth() {
        return 42 - (this.daysInMonth + this.qtyDaysPrevMonth);
    }
    monthsClickHandler() {
        this.showMonth = true;
    }
    //https://github.com/mengxiong10/vue2-datepicker/blob/master/src/calendar/table-year.vue
    chunk(arr, size) {
        if (!Array.isArray(arr)) {
            return [];
        }
        const result = [];
        const len = arr.length;
        let i = 0;
        size = size || len;
        while (i < len) {
            result.push(arr.slice(i, (i += size)));
        }
        this.years = result.flat();
        return result;
    }
    yearsPikerHandler() {
        const firstYear = Math.floor(this.date.year / 18) * 18;
        const years = [];
        for (let i = 0; i < 18; i++) {
            years.push(firstYear + i);
        }
        this.showYears = true;
        this.showMonth = false;
        return this.chunk(years, 1);
    }
    nextYearHandler() {
        if (this.years.includes(this.lastAvailableItemNext)) {
            return;
        }
        this.years = this.years.map((item, i) => {
            let init = this.years[0] + 18;
            return init + i;
        });
    }
    prevYearHandler() {
        if (this.years.includes(this.lastAvailableItemPrev)) {
            return;
        }
        this.years = this.years.map((item, i) => {
            let init = this.years[0] - 18;
            return init + i;
        });
    }
    setYear(year) {
        this.showYears = false;
        this.showMonth = true;
        this.date.year = year;
    }
    setMonth(month) {
        this.showMonth = false;
        this.clickedDay = null;
        this.date.month = month;
    }
    closeCalendar() {
        this.show = false;
    }
    nextAction() {
        if (this.showMonth) {
            this.nextYearHandlerIndex();
        }
        else if (this.showYears) {
            this.nextYearHandler();
        }
        else {
            this.nextMonthHandler();
        }
    }
    prevAction() {
        if (this.showMonth) {
            this.prevYearHandlerIndex();
        }
        else if (this.showYears) {
            this.prevYearHandler();
        }
        else {
            this.previousMonthHandler();
        }
    }
    nextYearHandlerIndex() {
        if (this.date.year === this.lastAvailableItemNext)
            return;
        this.date.year += 1;
    }
    prevYearHandlerIndex() {
        if (this.date.year === this.lastAvailableItemPrev)
            return;
        this.date.year -= 1;
    }
    setToday() {
        this.date.month = NOW.getMonth();
        this.date.year = NOW.getFullYear();
        this.date.day = NOW.getDate();
        this.setDate(this.currDay);
    }
    previousMonthHandler() {
        if (this.date.year === this.lastAvailableItemPrev && this.currMonth === 0)
            return;
        this.clickedDay = null;
        this.date = {
            ...this.date,
            year: this.currMonth === 0 ? this.currYear - 1 : this.currYear,
            month: this.currMonth === 0 ? 11 : this.currMonth - 1,
        };
    }
    nextMonthHandler() {
        if (this.date.year === this.lastAvailableItemNext && this.currMonth === 11)
            return;
        this.clickedDay = null;
        this.date = {
            ...this.date,
            year: this.currMonth === 11 ? this.currYear + 1 : this.currYear,
            month: (this.currMonth + 1) % 12,
        };
    }
    openCalendarHandler() {
        if (!this.clickedDay) {
            this.date = {
                day: NOW.getDate(),
                month: NOW.getMonth(),
                year: NOW.getFullYear(),
            };
            this.inputValue = "";
        }
        this.showMonth = false;
        this.showYears = false;
        this.show = !this.show;
    }
    getKey() {
        return Date.now();
    }
    clearDate(e) {
        e.stopPropagation();
        this.inputValue = "";
        this.val = null;
    }
    focus() {
        return;
    }
    setDate(day) {
        this.clickedDay = day;
        const fixDay = day < 10 ? "0" + day : day;
        const fixMonth = this.currMonth + 1 < 10 ? "0" + (this.currMonth + 1) : this.currMonth + 1;
        this.inputValue = `${fixDay}.${fixMonth}.${this.currYear}`;
        this.show = false;
        return this.inputValue;
    }
    yearsWatcher() {
        return this.years;
    }
    beforeDestroy() {
        window.removeEventListener("resize", this.getWindowHeight);
    }
};
__decorate([
    Prop({ required: false, type: String })
], Calendar.prototype, "label", void 0);
__decorate([
    Prop({ type: [String, Boolean], default: "top" })
], Calendar.prototype, "labelPosition", void 0);
__decorate([
    Prop({ type: [String, Number], default: "" })
], Calendar.prototype, "errors", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Calendar.prototype, "resetDate", void 0);
__decorate([
    VModel({ type: String, default: null })
], Calendar.prototype, "val", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Calendar.prototype, "withResetBtn", void 0);
__decorate([
    Ref("calendarSize")
], Calendar.prototype, "calendarSize", void 0);
__decorate([
    Ref("calendarInput")
], Calendar.prototype, "calendarInput", void 0);
__decorate([
    Prop({ type: Number, default: new Date().getFullYear() })
], Calendar.prototype, "lastAvailableItemNext", void 0);
__decorate([
    Prop({ type: Number, default: 1900 })
], Calendar.prototype, "lastAvailableItemPrev", void 0);
__decorate([
    Watch("val", { immediate: true })
], Calendar.prototype, "initVal", null);
__decorate([
    Watch("resetDate")
], Calendar.prototype, "resetDateToEmpty", null);
__decorate([
    Emit("clearDate")
], Calendar.prototype, "clearDate", null);
__decorate([
    Emit("focus")
], Calendar.prototype, "focus", null);
__decorate([
    Emit("input")
], Calendar.prototype, "setDate", null);
__decorate([
    Watch("years")
], Calendar.prototype, "yearsWatcher", null);
Calendar = __decorate([
    Component({
        components: { Button, Input, Icon },
    })
], Calendar);
export default Calendar;
