import { __decorate } from "tslib";
import { Getter, Mutation, State, Action } from "@/types";
export class Dialog {
    constructor() {
        this.currentDialog = null;
        this.confirmation = null;
        this.playPause = "pause";
    }
    setDialog(payload) {
        this.currentDialog = payload;
    }
    setPlayPause(payload) {
        this.playPause = payload;
    }
    removeDialog() {
        this.currentDialog = null;
        this.removeCaller();
    }
    caller(payload) {
        const { callback = null, itemToConfirm = null } = payload;
        this.confirmation = {
            callback,
            itemToConfirm,
        };
    }
    removeCaller() {
        this.confirmation = null;
    }
    async setDetails(payload) {
        await this.caller(payload);
    }
    get curr() {
        return this.currentDialog;
    }
    get currPlayPause() {
        return this.playPause;
    }
    get type() {
        if (this.currentDialog) {
            return this.currentDialog.type;
        }
    }
    get confirm() {
        return this.confirmation;
    }
}
__decorate([
    State()
], Dialog.prototype, "currentDialog", void 0);
__decorate([
    State()
], Dialog.prototype, "confirmation", void 0);
__decorate([
    State()
], Dialog.prototype, "playPause", void 0);
__decorate([
    Mutation()
], Dialog.prototype, "setDialog", null);
__decorate([
    Mutation()
], Dialog.prototype, "setPlayPause", null);
__decorate([
    Mutation()
], Dialog.prototype, "removeDialog", null);
__decorate([
    Mutation()
], Dialog.prototype, "caller", null);
__decorate([
    Mutation()
], Dialog.prototype, "removeCaller", null);
__decorate([
    Action()
], Dialog.prototype, "setDetails", null);
__decorate([
    Getter()
], Dialog.prototype, "curr", null);
__decorate([
    Getter()
], Dialog.prototype, "currPlayPause", null);
__decorate([
    Getter()
], Dialog.prototype, "type", null);
__decorate([
    Getter()
], Dialog.prototype, "confirm", null);
