import { Adjustments, DayIntervals, Holidays, Reports, WorkSchedules, Absences, } from "@/views";
export default [
    { path: "day-intervals", name: "DayIntervals", component: DayIntervals },
    { path: "work-schedules", name: "WorkSchedules", component: WorkSchedules },
    { path: "holidays", name: "Holidays", component: Holidays },
    {
        path: "reports",
        name: "Reports",
        component: Reports,
    },
    { path: "adjustments", name: "Adjustments", component: Adjustments },
    { path: "absences", name: "Absences", component: Absences },
];
