import { removeNull } from "@/helpers/removeNull";
export class BasicService {
    constructor(axios) {
        this.axios = axios;
    }
    getConfig(url, data, config) {
        const result = {
            ...config,
            url,
        };
        if (data) {
            result.data = data;
        }
        return result;
    }
    $get(url, data, config) {
        const requestProps = this.getConfig(url, data, {
            ...config,
            method: "GET",
        });
        return this.axios.request(requestProps);
    }
    $post(url, data, config) {
        const modifyData = removeNull(data);
        const requestProps = this.getConfig(url, modifyData, {
            ...config,
            method: "POST",
        });
        return this.axios.request(requestProps);
    }
    $put(url, data, config) {
        const modifyData = removeNull(data);
        const requestProps = this.getConfig(url, modifyData, {
            ...config,
            method: "PUT",
        });
        return this.axios.request(requestProps);
    }
    $delete(url, data, config) {
        const requestProps = this.getConfig(url, data, {
            ...config,
            method: "DELETE",
        });
        return this.axios.request(requestProps);
    }
}
