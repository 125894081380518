import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue } from "@/types";
import "./confirm.scss";
import { Button, Icon, Modal } from "@/ui-kit";
let Confirm = class Confirm extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
    }
    get showDialog() {
        return this.store.dialog.curr;
    }
    get page() {
        let sliceForTwoWords = this.$route.name;
        /***
         * Если имя страницы состоит из двух слов, меняем регистр первого слово;
         * должен соответствовать названию модулю из стора
         */
        let page = (sliceForTwoWords.charAt(0).toLowerCase() +
            sliceForTwoWords.slice(1));
        return this.store[page];
    }
    get confirmTitle() {
        if (this.$route.name === "WorkSchedules") {
            let isSeven = this.showDialog?.data.workScheduleType === 1
                ? "WorkSchedulesMonth"
                : "WorkSchedulesWeek";
            return this.$l["modalTitle"](isSeven, this.showDialog?.name);
        }
        return this.$l["modalTitle"](this.$route.name, this.showDialog?.name);
    }
    get deleteItem() {
        if (this.showDialog) {
            const specialName = this.store.dialog.confirm?.itemToConfirm;
            return specialName ?? this.showDialog.data.name;
        }
        return "";
    }
    async baseConfirm() {
        if ("delete" in this.page) {
            const isSuccess = await this.page.delete(this.showDialog?.data.id);
            if (isSuccess) {
                await this.store.notification.addNotification({
                    type: "success",
                    message: this.$l["success"],
                });
            }
            return isSuccess;
        }
    }
    async confirm() {
        try {
            /***
             * Для индвидуальных случаев навешиваются в watch
             */
            if (this.store.dialog.confirm?.callback) {
                const isSuccess = await this.store.dialog.confirm.callback();
                if (isSuccess) {
                    await this.store.notification.addNotification({
                        type: "success",
                        message: this.$l["success"],
                    });
                }
                return isSuccess;
            }
            const base = await this.baseConfirm();
            return base;
        }
        catch (e) {
            console.log(e, "confirm");
            return false;
        }
    }
    close() {
        this.store.dialog.removeDialog();
    }
};
__decorate([
    Emit("confirm")
], Confirm.prototype, "confirm", null);
Confirm = __decorate([
    Component({
        components: { Button, Icon, Modal },
    })
], Confirm);
export default Confirm;
