var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"multiselect"},[_c('div',{staticClass:"multiselect__wrap"},[_c('div',{staticClass:"multiselect__name"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.$l["organization"])+" ")]})],2),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeSearchHandler),expression:"closeSearchHandler"}],staticClass:"multiselect__chips",class:{
        'multiselect__chips--searching': _vm.searching !== '',
      },on:{"click":function($event){return _vm.searchFocusHandler($event)}}},[_c('div',{staticClass:"multiselect__icon"},[_c('Icon',{attrs:{"size":[20, 20],"name":'search'}})],1),_vm._l((_vm.chipsList),function(item){return _c('div',{key:item.id ? item.id : item.name,staticClass:"multiselect__selections",class:{
          'multiselect__selections--active':
            _vm.activeItem === (item.id ? item.id : item.name),
        },on:{"click":function($event){_vm.activeItem = item.id ? item.id : item.name ? item.name : ''}}},[_vm._t("chosen",function(){return [_vm._v(" "+_vm._s(item[_vm.chipName])+" ")]},{"chosen":item}),_c('div',{staticClass:"multiselect__remove",on:{"click":function($event){return _vm.removeItem(item)}}},[_c('Icon',{attrs:{"size":[20, 20],"name":'cross',"color":'main-color'}})],1)],2)}),_c('Input',{ref:"inputRef",staticClass:"multiselect__input",class:{
          'multiselect__input--hidePlaceholder': _vm.chipsList.length,
        },attrs:{"placeholder":_vm.placeholder},model:{value:(_vm.searching),callback:function ($$v) {_vm.searching=$$v},expression:"searching"}}),(_vm.searching !== '')?[_c('div',{staticClass:"multiselect-search__options"},[(_vm.loading)?[_c('Loader',{attrs:{"iconSize":[25, 25]}})]:[(_vm.list.length)?_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"multiselect-search__option",on:{"click":function($event){return _vm.getChipsHandler(item)}}},[_vm._t("searchResult",function(){return [_vm._v(" "+_vm._s(item[_vm.chipName])+" ")]},{"searchResult":item})],2)}):[_c('div',{staticClass:"multiselect-search__empty"},[_vm._v(" "+_vm._s(_vm.$l["notFoundSearch"])+" ")])]]],2)]:_vm._e()],2),_c('Icon',{attrs:{"size":[35, 35],"name":'cross',"color":_vm.chipsList.length > 0 ? 'main-color' : 'grey',"className":'multiselect__removeAll'},on:{"click":_vm.removeAll}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }