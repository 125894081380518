const administrationTitle = {
    Readers: "считыватель",
    Zones: "зону",
    AccessPoints: "точку доступа",
    AccessSchedules: "график доступа",
    Users: "cистемного пользователя",
};
const filingCabinetTitle = {
    Organization: "организацию",
    Subdivisions: "подразделение",
    Employees: "сотрудника",
    Dismissed: "уволенного",
    GuestPasses: "гостевой пропуск",
};
const monitoringTitle = {
    Configurations: "конфигурацию",
    Monitor: "",
};
const timeAttendanceTitle = {
    DayIntervals: "рабочий интервал",
    WorkSchedulesWeek: "недельный график работы",
    WorkSchedulesMonth: "сменный график работы",
    Holidays: "день",
    Adjustments: "корректировку",
    Reports: "шаблон отчёта",
    Absences: "причину неявки",
};
const modalAction = {
    ...administrationTitle,
    ...filingCabinetTitle,
    ...monitoringTitle,
    ...timeAttendanceTitle,
};
export default modalAction;
