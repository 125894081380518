import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from "@/types";
import "./column.scss";
import { Checkbox, Input } from "@/ui-kit";
let Column = class Column extends Vue {
    constructor() {
        super(...arguments);
        this.selectedTo = [];
        this.selectedAll = false;
        this.searching = "";
        this.isSearch = true;
        this.current = null;
        this.id = null;
        this.watchError = false;
    }
    mounted() {
        this.id = this._uid;
    }
    highlightActiveId() {
        this.$nextTick(() => {
            if (!isNaN(this.activeId)) {
                this.current = this.activeId;
            }
        });
    }
    checkForList() {
        /**
         * Прокручиваем таблицу к выбранному элементу
         */
        if (this.list.length) {
            this.$nextTick(() => {
                const parent = document.getElementById(`${this.id}`);
                const child = parent.querySelector(".column__item--active");
                if (child) {
                    const childTop = child.offsetTop;
                    const parentTop = child.parentNode.offsetTop;
                    child.parentNode.scrollTop = childTop - parentTop;
                }
            });
        }
    }
    checkAllDataCheckboxes() {
        if (this.forAll) {
            this.selectedAll = true;
            this.selectedTo = this.list.map((item) => item.id);
            return;
        }
        this.selectedAll = false;
    }
    checkDataCheckboxes() {
        if (this.dataCheckboxes.length) {
            this.selectedAll = this.list.length === this.dataCheckboxes.length;
            this.selectedTo = this.dataCheckboxes;
            return;
        }
        this.selectedTo = [];
        this.selectedAll = false;
    }
    /**
     * Фильтр для колонки
     */
    get list() {
        return this.data.filter((item) => {
            if (item["name"]) {
                return item["name"]
                    .toLowerCase()
                    .includes(this.searching.toLowerCase());
            }
            return this.data;
        });
    }
    handleColumnSelected(item) {
        return (this.current = this.current === item.id ? null : item.id);
    }
    toggleCheckbox(data) {
        this.selectedTo = this.selectedTo.includes(data.id)
            ? this.selectedTo.filter((item) => item !== data.id)
            : [...this.selectedTo, data.id];
        this.selectedAll = this.selectedTo.length === this.list.length;
        return this.selectedTo;
    }
    toggleAllCheckbox() {
        this.selectedTo = this.selectedAll ? this.list.map((item) => item.id) : [];
        this.selectedAll = this.selectedTo.length === this.list.length;
        return this.selectedAll;
    }
    removeError() {
        if (this.watchError) {
            this.watchError = false;
        }
    }
    searcher() {
        this.isSearch = Boolean(this.searching.length);
    }
    setBlur() {
        this.searching = "";
    }
    showError() {
        if (!this.errors) {
            return;
        }
        this.watchError = true;
    }
};
__decorate([
    Prop({ type: Array, default: () => [], required: true })
], Column.prototype, "data", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], Column.prototype, "dataCheckboxes", void 0);
__decorate([
    Prop({ type: Number, default: 700 })
], Column.prototype, "height", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Column.prototype, "withCheckbox", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Column.prototype, "withSearch", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Column.prototype, "forAll", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], Column.prototype, "isLeftErrors", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], Column.prototype, "isActiveHandle", void 0);
__decorate([
    Prop({ type: String, default: "" })
], Column.prototype, "errors", void 0);
__decorate([
    Prop({ type: String, default: "notFoundSearch" })
], Column.prototype, "noFoundData", void 0);
__decorate([
    Prop({ type: Number, required: false })
], Column.prototype, "activeId", void 0);
__decorate([
    Prop({
        type: String,
        default: "Выбрать все",
    })
], Column.prototype, "parentCheckboxForArray", void 0);
__decorate([
    Watch("activeId", { immediate: true })
], Column.prototype, "highlightActiveId", null);
__decorate([
    Watch("list", { immediate: true })
], Column.prototype, "checkForList", null);
__decorate([
    Watch("forAll", { immediate: true })
], Column.prototype, "checkAllDataCheckboxes", null);
__decorate([
    Watch("dataCheckboxes", { immediate: true })
], Column.prototype, "checkDataCheckboxes", null);
__decorate([
    Emit("handleColumnSelected")
], Column.prototype, "handleColumnSelected", null);
__decorate([
    Emit("toggleCheckbox")
], Column.prototype, "toggleCheckbox", null);
__decorate([
    Emit("toggleAllCheckbox")
], Column.prototype, "toggleAllCheckbox", null);
__decorate([
    Emit("removeError")
], Column.prototype, "removeError", null);
Column = __decorate([
    Component({
        components: { Checkbox, Input },
    })
], Column);
export default Column;
