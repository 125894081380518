import { __decorate } from "tslib";
import { Component, Emit, Ref, useModule, Vue, Watch, } from "@/types";
import { Button, Input, Modal, MultiSelect, Icon, Radio, Checkbox, Toggler, Calendar, } from "@/ui-kit";
import "./addSysLogFilter.scss";
import { addSeconds, modifyingDateToIso } from "@/helpers";
let AddSysLogFilter = class AddSysLogFilter extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            levelIds: null,
            userIds: null,
            sourceNames: null,
            timeIntervalType: 1,
            timeIntervalDuration: 1,
            timeIntervalBeginDate: null,
            timeIntervalBeginTime: null,
            timeIntervalEndDate: null,
            timeIntervalEndTime: null,
        };
        this.errors = {
            timeIntervalBeginTime: "",
            timeIntervalEndTime: "",
            timeIntervalBeginDate: "",
            timeIntervalEndDate: "",
        };
        this.isPeriod = true;
        this.timeInterval = [
            {
                id: 1,
                name: this.$l["day"],
            },
            {
                id: 2,
                name: this.$l["week"],
            },
            {
                id: 3,
                name: this.$l["month"],
            },
        ];
        this.intervalType = 1; // 1 = Current , 2 = Previous , 3 = Custom;
        this.datePeriod = 1; // 1 = Day , 2 = Weak, 3 = Month;
        this.filtersInit = [
            {
                id: 1,
                title: this.$l["sysSource"],
                moduleApi: "systemEventsSources",
                func: (arr) => {
                    this.modalData.sourceNames = arr.length
                        ? arr.map((item) => item.name)
                        : null;
                },
                listToEditValue: this.modalData.sourceNames,
                filterKey: "name",
                typeFilter: "string",
                placeholder: this.$l["inputSource"],
            },
            {
                id: 2,
                title: this.$l["sysUser"],
                moduleApi: "usersLogin",
                func: (arr) => {
                    this.modalData.userIds = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                listToEditValue: this.modalData.userIds,
                filterKey: "id",
                filterSearchValue: "login",
                chipName: "login",
                typeFilter: "string",
                placeholder: this.$l["inputUser"],
            },
            {
                id: 3,
                title: this.$l["sysTypeEvent"],
                moduleApi: "systemEventsLevels",
                func: (arr) => {
                    this.modalData.levelIds = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                listToEditValue: this.modalData.levelIds,
                placeholder: this.$l["inputType"],
            },
        ];
    }
    created() {
        this.store.systemEventsFilter
            .get({
            skip: 0,
        })
            .then(() => {
            const result = this.store.systemEventsFilter
                .list;
            this.modalData.sourceNames = result.sourceNames;
            this.modalData.userIds = result.userIds;
            this.modalData.levelIds = result.levelIds;
            this.filtersInit[0].listToEditValue = this.modalData.sourceNames;
            this.filtersInit[1].listToEditValue = this.modalData.userIds;
            this.filtersInit[2].listToEditValue = this.modalData.levelIds;
            this.modalData.timeIntervalType = result.timeIntervalType;
            this.intervalType = result.timeIntervalType;
            this.modalData.timeIntervalDuration = result.timeIntervalDuration;
            this.datePeriod = result.timeIntervalDuration;
            if (this.modalData.timeIntervalType === 3) {
                this.isPeriod = false;
                this.intervalType = 3;
                this.modalData.timeIntervalBeginDate = result.timeIntervalBeginDate;
                this.modalData.timeIntervalEndDate = result.timeIntervalEndDate;
                this.modalData.timeIntervalBeginTime = result.timeIntervalBeginTime;
                this.modalData.timeIntervalEndTime = result.timeIntervalEndTime;
            }
        });
    }
    isValid() {
        this.errors.timeIntervalBeginTime = this.store.validationField.valid["timeIntervalBeginTime"];
        this.errors.timeIntervalEndTime = this.store.validationField.valid["timeIntervalEndTime"];
        this.errors.timeIntervalBeginDate = this.store.validationField.valid["timeIntervalBeginDate"];
        this.errors.timeIntervalEndDate = this.store.validationField.valid["timeIntervalEndDate"];
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    prepareToSendDate(val) {
        if (val.intervalType === 3) {
            this.modalData.timeIntervalDuration = null;
            this.isPeriod = false;
        }
        this.modalData.timeIntervalType = val.intervalType;
        this.modalData.timeIntervalDuration = val.datePeriod;
    }
    resetAll() {
        this.isPeriod = true;
        this.datePeriod = 1;
        this.intervalType = 1;
        for (let i = 0; i < this.multiselect.length; i++) {
            this.multiselect[i].removeAll();
        }
        /**
         * Удаляем чиплист
         */
        this.filtersInit = this.filtersInit.map((item) => ({
            ...item,
            listToEditValue: null,
        }));
        /**
         * Выставляем значение в соотвествеии с задачей #36262
         */
        this.modalData = Object.entries(this.modalData).reduce((acc, [key, val]) => {
            if (key === "timeIntervalType" || key === "timeIntervalDuration") {
                acc[key] = 1;
            }
            else {
                acc[key] = null;
            }
            return acc;
        }, {});
        this.errors.timeIntervalBeginTime = null;
        this.errors.timeIntervalEndTime = null;
        this.errors.timeIntervalBeginDate = null;
        this.errors.timeIntervalEndDate = null;
    }
    dataTypeHandler() {
        if (this.isPeriod) {
            this.datePeriod = 1;
            this.intervalType = 1;
            this.modalData.timeIntervalDuration = 1;
            this.modalData.timeIntervalBeginDate = null;
            this.modalData.timeIntervalEndDate = null;
            this.modalData.timeIntervalBeginTime = null;
            this.modalData.timeIntervalEndTime = null;
        }
        else {
            this.datePeriod = null;
            this.intervalType = 3;
            this.modalData.timeIntervalBeginDate = this.defaultTime.intervalBeginDate;
            this.modalData.timeIntervalEndDate = this.defaultTime.intervalEndDate;
            this.modalData.timeIntervalBeginTime = this.defaultTime.intervalBeginTime;
            this.modalData.timeIntervalEndTime = this.defaultTime.intervalEndTime;
        }
        this.errors.timeIntervalBeginTime = null;
        this.errors.timeIntervalEndTime = null;
        this.errors.timeIntervalBeginDate = null;
        this.errors.timeIntervalEndDate = null;
    }
    async saveFilter() {
        const begin = this.modalData.timeIntervalBeginDate;
        const end = this.modalData.timeIntervalEndDate;
        const [success, error = null] = await this.store.systemEventsFilter.add({
            ...this.modalData,
            timeIntervalBeginDate: modifyingDateToIso(begin),
            timeIntervalEndDate: modifyingDateToIso(end),
            timeIntervalBeginTime: addSeconds(this.modalData.timeIntervalBeginTime),
            timeIntervalEndTime: addSeconds(this.modalData.timeIntervalEndTime),
        });
        if (error) {
            await this.store.sysLog.setError(error);
            return false;
        }
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    get defaultTime() {
        const date = new Date();
        return {
            intervalBeginDate: new Date().toISOString().split("T")[0],
            intervalEndDate: new Date(date.setDate(date.getDate() + 1))
                .toISOString()
                .split("T")[0],
            intervalBeginTime: "00:00",
            intervalEndTime: "00:00",
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    get changeDateTimeValues() {
        return {
            intervalType: this.intervalType,
            datePeriod: this.datePeriod,
        };
    }
    get isDateSwitchAvailable() {
        return this.currDialog?.details !== "play";
    }
};
__decorate([
    Ref("multiselect")
], AddSysLogFilter.prototype, "multiselect", void 0);
__decorate([
    Watch("store.validationField.valid")
], AddSysLogFilter.prototype, "isValid", null);
__decorate([
    Watch("changeDateTimeValues")
], AddSysLogFilter.prototype, "prepareToSendDate", null);
__decorate([
    Emit("success")
], AddSysLogFilter.prototype, "saveFilter", null);
AddSysLogFilter = __decorate([
    Component({
        components: {
            Calendar,
            Toggler,
            Radio,
            Checkbox,
            MultiSelect,
            Button,
            Input,
            Modal,
            Icon,
        },
    })
], AddSysLogFilter);
export default AddSysLogFilter;
