import { __decorate } from "tslib";
import { Mutation, State } from "@/types";
import { Getter } from "vuex-simple";
export class ValidationField {
    constructor() {
        this.fieldErrors = {};
    }
    setError({ ...err }) {
        this.fieldErrors = err;
    }
    get valid() {
        return this.fieldErrors;
    }
}
__decorate([
    State()
], ValidationField.prototype, "fieldErrors", void 0);
__decorate([
    Mutation()
], ValidationField.prototype, "setError", null);
__decorate([
    Getter()
], ValidationField.prototype, "valid", null);
