import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import lang from "@/language/index";
import { Auth } from "@/helpers/Auth";
import VueMask from "v-mask";
import vClickOutside from "v-click-outside";
Vue.use(VueMask, {});
Vue.use(vClickOutside);
Vue.config.productionTip = false;
Vue.prototype.$l = lang;
(async () => {
    await Auth.initialize();
    new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount("#app");
})().catch((e) => console.log(e));
