import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, Checkbox, Column, DropDown, Icon, } from "@/ui-kit";
import "./addSevenWorkSchedules.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddSevenWorkSchedules = class AddSevenWorkSchedules extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            organization: this.store.orgList.currentOrg?.id,
            name: "",
            workScheduleType: 2,
            automaticShiftClosing: false,
            withHolidays: true,
            accessZones: [],
            workingIntervals: [
                {
                    day: 1,
                    workingIntervalId: 0,
                },
                {
                    day: 2,
                    workingIntervalId: 0,
                },
                {
                    day: 3,
                    workingIntervalId: 0,
                },
                {
                    day: 4,
                    workingIntervalId: 0,
                },
                {
                    day: 5,
                    workingIntervalId: 0,
                },
                {
                    day: 6,
                    workingIntervalId: 0,
                },
                {
                    day: 7,
                    workingIntervalId: 0,
                },
            ],
        };
        this.errors = {
            name: "",
            accessZones: "",
        };
        this.forAllZones = false;
        this.zones = [];
        this.dataWorkingIntervals = [];
        this.days = {};
    }
    selectedAutoCreated(organizationId = 1) {
        const date = [
            "Понедельник",
            "Вторник",
            "Cреда",
            "Четверг",
            "Пятница",
            "Cуббота",
            "Воскресенье",
        ];
        for (let i = 1; i <= 7; i++) {
            let indexDay = i - 1;
            this.$set(this.days, i, {
                id: i,
                title: date[indexDay],
                selected: organizationId,
                setDay: ({ id, name }) => {
                    this.days[i].selected = id;
                    this.modalData.workingIntervals[indexDay] = {
                        day: i,
                        workingIntervalId: id,
                    };
                },
            });
        }
    }
    async getData() {
        try {
            await Promise.all([
                this.store.zones.get({
                    skip: 0,
                    filter: "accessPointType eq 1",
                }),
                this.store.dayIntervals.get({
                    skip: 0,
                    filter: `organization eq ${this.store.orgList.currentOrg?.id}`,
                }),
            ]).then(() => {
                this.zones = this.store.zones.list;
                this.dataWorkingIntervals = this.store.dayIntervals.list;
                this.modalData.workingIntervals = this.modalData.workingIntervals.map((item) => ({
                    ...item,
                    workingIntervalId: this.store.dayIntervals.list[0].id,
                }));
                this.selectedAutoCreated(this.store.dayIntervals.list[0].id);
            });
        }
        catch (e) {
            console.log(e);
        }
    }
    mounted() {
        this.getData().then(() => {
            if (this.currDialog?.data) {
                const { name, data } = this.currDialog;
                if (name === "save") {
                    this.modalData = {
                        ...formObjectHelper(this.modalData, data),
                    };
                    this.forAllZones = this.zones.length === data.accessZones.length;
                    this.days = data.workingIntervals.reduce((acc, curr, index) => {
                        const currentDay = this.days[curr.day];
                        acc[curr.day] = {
                            ...acc,
                            ...currentDay,
                            selected: curr.workingIntervalId,
                            name: curr.workingIntervalName,
                            setDay: ({ id, name }) => {
                                const currentIndex = curr.day;
                                this.days[currentIndex].selected = id;
                                this.modalData.workingIntervals[currentIndex - 1] = {
                                    day: curr.day,
                                    workingIntervalId: id,
                                    workingIntervalName: name,
                                };
                            },
                        };
                        return acc;
                    }, {});
                }
            }
        });
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    get typedWorkSchedule() {
        //WorkSchedulesWeek: "недельный график работы",
        //WorkSchedulesMonth: "сменный график работы",
        return this.$l["modalTitle"]("WorkSchedulesWeek", this.currDialog?.name);
    }
    handleZones(zones) {
        this.modalData.accessZones = zones;
        if (this.modalData.accessZones.length === this.zones.length) {
            this.handleAllZones(true);
        }
    }
    handleAllZones(all) {
        this.modalData.accessZones = all
            ? Array.from(this.zones, (item) => item.id)
            : [];
    }
    async addWorkSchedule() {
        const success = await this.store.workSchedules.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editWorkSchedule() {
        const success = await this.store.workSchedules.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editWorkSchedule()
                : this.addWorkSchedule());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddSevenWorkSchedules.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddSevenWorkSchedules.prototype, "addWorkSchedule", null);
__decorate([
    Emit("success")
], AddSevenWorkSchedules.prototype, "editWorkSchedule", null);
AddSevenWorkSchedules = __decorate([
    Component({
        components: { DropDown, Checkbox, Button, Input, Modal, Column, Icon },
    })
], AddSevenWorkSchedules);
export default AddSevenWorkSchedules;
