import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./organization.scss";
import { Table } from "@/ui-kit";
import { AddOrganization } from "@/components";
import { Storage } from "@/helpers/storage";
let Organization = class Organization extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Организация", class: "name" },
            { name: "График", class: "accessScheduleName" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeOrganization(val) {
        if (val?.name === "remove") {
            let confirm = false;
            this.store.dialog.setDetails({
                callback: () => {
                    return this.store.organization
                        .delete({ id: val.data.id, confirm: confirm })
                        .then((isSuccess) => {
                        if (isSuccess) {
                            this.reload(true);
                            this.removeOrgFromStorage();
                        }
                        if (this.store.organization.errorList?.response?.status === 449) {
                            confirm = true;
                        }
                        return isSuccess;
                    });
                },
            });
        }
    }
    /**
     * Попытка удаления активной орги
     */
    removeOrgFromStorage() {
        if (this.store.dialog.curr?.data.id === this.store.orgList.currentOrg?.id) {
            Storage.remove("organization");
            this.store.orgList.resetOrg();
        }
    }
};
__decorate([
    Ref("table")
], Organization.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Organization.prototype, "removeOrganization", null);
Organization = __decorate([
    Component({
        components: { AddOrganization, Table },
    })
], Organization);
export default Organization;
