import { __decorate } from "tslib";
import { Component, Prop, Vue } from "@/types";
import "./loader.scss";
import { Icon } from "@/ui-kit";
let Loader = class Loader extends Vue {
};
__decorate([
    Prop({ default: () => [40, 40], type: Array })
], Loader.prototype, "iconSize", void 0);
__decorate([
    Prop({ default: "main-color", type: String })
], Loader.prototype, "color", void 0);
__decorate([
    Prop()
], Loader.prototype, "className", void 0);
__decorate([
    Prop()
], Loader.prototype, "title", void 0);
Loader = __decorate([
    Component({
        components: { Icon },
    })
], Loader);
export default Loader;
