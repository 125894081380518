import { __decorate } from "tslib";
import { Component, Emit, Ref, useModule, Vue, Watch, } from "@/types";
import { Button, Input, Modal, MultiSelect, Icon, Radio, Checkbox, Toggler, Calendar, } from "@/ui-kit";
import "./addPassLogFilter.scss";
import { addSeconds, modifyingDateToIso } from "@/helpers";
let AddPassLogFilter = class AddPassLogFilter extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            organizationIds: null,
            readerIds: null,
            personIds: null,
            passMethodIds: null,
            passAllowed: true,
            temperature: null,
            maskDetected: true,
            timeIntervalType: 1,
            timeIntervalDuration: 1,
            timeIntervalBeginDate: null,
            timeIntervalBeginTime: null,
            timeIntervalEndDate: null,
            timeIntervalEndTime: null,
        };
        this.errors = {
            timeIntervalBeginTime: "",
            timeIntervalEndTime: "",
            timeIntervalBeginDate: "",
            timeIntervalEndDate: "",
        };
        this.isPeriod = true;
        this.maskAccess = [
            {
                id: 1,
                name: this.$l["have"],
            },
            {
                id: 2,
                name: this.$l["no"],
            },
            {
                id: 3,
                name: this.$l["all"],
            },
        ];
        this.maskDetectedRadio = 1;
        this.passAllowed = [
            {
                id: 1,
                name: this.$l["accessTrue"],
            },
            {
                id: 2,
                name: this.$l["accessFalse"],
            },
            {
                id: 3,
                name: this.$l["all"],
            },
        ];
        this.passAllowedRadio = 1;
        this.timeInterval = [
            {
                id: 1,
                name: this.$l["day"],
            },
            {
                id: 2,
                name: this.$l["week"],
            },
            {
                id: 3,
                name: this.$l["month"],
            },
        ];
        this.intervalType = 1; // 1 = Current , 2 = Previous , 3 = Custom;
        this.datePeriod = 1; // 1 = Day , 2 = Weak, 3 = Month;
        this.filtersInit = [
            {
                id: 1,
                title: this.$l["organization"],
                moduleApi: "organization",
                func: (arr) => {
                    this.modalData.organizationIds = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                listToEditValue: this.modalData.organizationIds,
            },
            {
                id: 2,
                title: this.$l["columnFilterReader"],
                moduleApi: "readers",
                func: (arr) => {
                    this.modalData.readerIds = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                listToEditValue: this.modalData.readerIds,
            },
            {
                id: 3,
                title: this.$l["columnFilterFio"],
                moduleApi: "employees",
                func: (arr) => {
                    this.modalData.personIds = arr.length
                        ? arr.map((item) => item.id)
                        : null;
                },
                filterSearchValue: "surname",
                listToEditValue: this.modalData.personIds,
            },
            {
                id: 4,
                title: this.$l["method"],
                moduleApi: "passMethods",
                func: (arr) => {
                    this.modalData.passMethodIds = arr.length
                        ? arr.map((item) => {
                            return item.id;
                        })
                        : null;
                },
                filterSearchValue: "name",
                listToEditValue: this.modalData.passMethodIds,
                placeholder: this.$l["inputMethods"],
            },
        ];
    }
    created() {
        this.store.passEventsFilter
            .get({
            skip: 0,
        })
            .then(() => {
            const result = this.store.passEventsFilter.list;
            this.modalData.organizationIds = result.organizationIds;
            this.modalData.readerIds = result.readerIds;
            this.modalData.personIds = result.personIds;
            this.modalData.passMethodIds = result.passMethodIds;
            this.filtersInit[0].listToEditValue = this.modalData.organizationIds;
            this.filtersInit[1].listToEditValue = this.modalData.readerIds;
            this.filtersInit[2].listToEditValue = this.modalData.personIds;
            this.filtersInit[3].listToEditValue = this.modalData.passMethodIds;
            if (result.maskDetected === null) {
                this.maskDetectedRadio = 3;
            }
            else if (result.maskDetected) {
                this.maskDetectedRadio = 1;
            }
            else if (!result.maskDetected) {
                this.maskDetectedRadio = 2;
            }
            if (result.passAllowed === null) {
                this.passAllowedRadio = 3;
            }
            else if (result.passAllowed) {
                this.passAllowedRadio = 1;
            }
            else if (!result.passAllowed) {
                this.passAllowedRadio = 2;
            }
            this.modalData.temperature = result.temperature;
            this.modalData.timeIntervalType = result.timeIntervalType;
            this.intervalType = result.timeIntervalType;
            this.modalData.timeIntervalDuration = result.timeIntervalDuration;
            this.datePeriod = result.timeIntervalDuration;
            if (this.modalData.timeIntervalType === 3) {
                this.isPeriod = false;
                this.intervalType = 3;
                this.modalData.timeIntervalBeginDate = result.timeIntervalBeginDate;
                this.modalData.timeIntervalEndDate = result.timeIntervalEndDate;
                this.modalData.timeIntervalBeginTime = result.timeIntervalBeginTime;
                this.modalData.timeIntervalEndTime = result.timeIntervalEndTime;
            }
        });
    }
    isValid() {
        this.errors.timeIntervalBeginTime = this.store.validationField.valid["timeIntervalBeginTime"];
        this.errors.timeIntervalEndTime = this.store.validationField.valid["timeIntervalEndTime"];
        this.errors.timeIntervalBeginDate = this.store.validationField.valid["timeIntervalBeginDate"];
        this.errors.timeIntervalEndDate = this.store.validationField.valid["timeIntervalEndDate"];
    }
    getMaskDetected(val) {
        if (val === 1) {
            this.modalData.maskDetected = true;
        }
        else if (val === 2) {
            this.modalData.maskDetected = false;
        }
        else if (val === 3) {
            this.modalData.maskDetected = null;
        }
    }
    getPassAllowed(val) {
        if (val === 1) {
            this.modalData.passAllowed = true;
        }
        else if (val === 2) {
            this.modalData.passAllowed = false;
        }
        else if (val === 3) {
            this.modalData.passAllowed = null;
        }
    }
    get defaultTime() {
        const date = new Date();
        return {
            intervalBeginDate: new Date().toISOString().split("T")[0],
            intervalEndDate: new Date(date.setDate(date.getDate() + 1))
                .toISOString()
                .split("T")[0],
            intervalBeginTime: "00:00",
            intervalEndTime: "00:00",
        };
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    prepareToSendDate(val) {
        if (val.intervalType === 3) {
            this.modalData.timeIntervalDuration = null;
            this.isPeriod = false;
        }
        this.modalData.timeIntervalType = val.intervalType;
        this.modalData.timeIntervalDuration = val.datePeriod;
    }
    resetAll() {
        this.isPeriod = true;
        this.datePeriod = 1;
        this.intervalType = 1;
        this.maskDetectedRadio = 3;
        this.passAllowedRadio = 3;
        for (let i = 0; i < this.multiselect.length; i++) {
            this.multiselect[i].removeAll();
        }
        /**
         * Удаляем чиплист
         */
        this.filtersInit = this.filtersInit.map((item) => ({
            ...item,
            listToEditValue: null,
        }));
        /**
         * Выставляем значение в соотвествеии с задачей #36262
         */
        this.modalData = Object.entries(this.modalData).reduce((acc, [key, val]) => {
            if (key === "timeIntervalType" || key === "timeIntervalDuration") {
                acc[key] = 1;
            }
            else {
                acc[key] = null;
            }
            return acc;
        }, {});
        this.errors.timeIntervalBeginTime = null;
        this.errors.timeIntervalEndTime = null;
        this.errors.timeIntervalBeginDate = null;
        this.errors.timeIntervalEndDate = null;
    }
    dataTypeHandler() {
        if (this.isPeriod) {
            this.datePeriod = 1;
            this.intervalType = 1;
            this.modalData.timeIntervalBeginDate = null;
            this.modalData.timeIntervalEndDate = null;
            this.modalData.timeIntervalBeginTime = null;
            this.modalData.timeIntervalEndTime = null;
            this.modalData.timeIntervalDuration = 1;
        }
        else {
            this.datePeriod = null;
            this.intervalType = 3;
            this.modalData.timeIntervalBeginDate = this.defaultTime.intervalBeginDate;
            this.modalData.timeIntervalEndDate = this.defaultTime.intervalEndDate;
            this.modalData.timeIntervalBeginTime = this.defaultTime.intervalBeginTime;
            this.modalData.timeIntervalEndTime = this.defaultTime.intervalEndTime;
        }
        this.errors.timeIntervalBeginTime = null;
        this.errors.timeIntervalEndTime = null;
        this.errors.timeIntervalBeginDate = null;
        this.errors.timeIntervalEndDate = null;
    }
    async saveFilter() {
        const begin = this.modalData.timeIntervalBeginDate;
        const end = this.modalData.timeIntervalEndDate;
        const [success, error = null] = await this.store.passEventsFilter.add({
            ...this.modalData,
            temperature: this.temperatureModify,
            timeIntervalBeginDate: modifyingDateToIso(begin),
            timeIntervalEndDate: modifyingDateToIso(end),
            timeIntervalBeginTime: addSeconds(this.modalData.timeIntervalBeginTime),
            timeIntervalEndTime: addSeconds(this.modalData.timeIntervalEndTime),
        });
        if (error) {
            await this.store.passLog.setError(error);
            return false;
        }
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    get temperatureModify() {
        const temp = this.modalData.temperature;
        return temp ? temp : null;
        // if(temp){
        //
        // }
        //
        //
        // return temp ? temp : null
        // // if (typeof temp === "string" && temp !== "") {
        // //   return parseFloat(temp.replace(",", ".").replace(" ", ""));
        // // }
        // return null;
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    get changeDateTimeValues() {
        return {
            intervalType: this.intervalType,
            datePeriod: this.datePeriod,
        };
    }
    get isDateSwitchAvailable() {
        return this.currDialog?.details !== "play";
    }
};
__decorate([
    Ref("multiselect")
], AddPassLogFilter.prototype, "multiselect", void 0);
__decorate([
    Watch("store.validationField.valid")
], AddPassLogFilter.prototype, "isValid", null);
__decorate([
    Watch("maskDetectedRadio")
], AddPassLogFilter.prototype, "getMaskDetected", null);
__decorate([
    Watch("passAllowedRadio")
], AddPassLogFilter.prototype, "getPassAllowed", null);
__decorate([
    Watch("changeDateTimeValues")
], AddPassLogFilter.prototype, "prepareToSendDate", null);
__decorate([
    Emit("success")
], AddPassLogFilter.prototype, "saveFilter", null);
AddPassLogFilter = __decorate([
    Component({
        components: {
            Calendar,
            Toggler,
            Radio,
            Checkbox,
            MultiSelect,
            Button,
            Input,
            Modal,
            Icon,
        },
    })
], AddPassLogFilter);
export default AddPassLogFilter;
