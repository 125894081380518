import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class AccessPoints extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "access-points";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(accessPoint) {
        try {
            await this.$post(this.baseURL, accessPoint);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(accessPoint) {
        try {
            await this.$put(`${this.baseURL}/${accessPoint.id}`, accessPoint);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(accessPointId) {
        try {
            await this.$delete(`${this.baseURL}/${accessPointId}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
