import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue } from "@/types";
import "./dayIntervals.scss";
import { Table } from "@/ui-kit";
import { AddDayInterval } from "@/components";
let DayIntervals = class DayIntervals extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "Рабочий интервал", class: "name" }];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], DayIntervals.prototype, "table", void 0);
DayIntervals = __decorate([
    Component({
        components: { AddDayInterval, Table },
    })
], DayIntervals);
export default DayIntervals;
