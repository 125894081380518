import { __decorate } from "tslib";
import { Mutation, State } from "@/types";
export class StatusReport {
    constructor() {
        this.reportGenerated = false;
    }
    changeStatusReport() {
        this.reportGenerated = !this.reportGenerated;
    }
}
__decorate([
    State()
], StatusReport.prototype, "reportGenerated", void 0);
__decorate([
    Mutation()
], StatusReport.prototype, "changeStatusReport", null);
