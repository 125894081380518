import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Input, Modal, Checkbox, Overlay } from "@/ui-kit";
import "./addDayInterval.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { addSeconds } from "@/helpers";
let AddDayInterval = class AddDayInterval extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            name: "",
            arrivalTime: "",
            departureTime: "",
            breakDuration: null,
            staggered: false,
            staggeredWorkShift: null,
            organization: this.store.orgList.currentOrg?.id,
        };
        this.errors = {
            name: "",
            arrivalTime: "",
            departureTime: "",
            breakDuration: null,
            staggeredWorkShift: null,
        };
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
            }
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get modifiedTime() {
        const time = {
            arrivalTime: this.modalData.arrivalTime,
            departureTime: this.modalData.departureTime,
            breakDuration: this.modalData.breakDuration,
            staggeredWorkShift: this.modalData.staggeredWorkShift,
        };
        return Object.entries(time).reduce((acc, [key, val]) => {
            if (typeof val === "string") {
                acc[key] = addSeconds(val);
            }
            return acc;
        }, {});
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    resetStaggered() {
        this.modalData.staggeredWorkShift = null;
        this.errors.staggeredWorkShift = null;
    }
    async addDayInterval() {
        this.modalData = {
            ...this.modalData,
            ...this.modifiedTime,
        };
        const success = await this.store.dayIntervals.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editDayInterval() {
        this.modalData = {
            ...this.modalData,
            ...this.modifiedTime,
        };
        const success = await this.store.dayIntervals.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editDayInterval()
                : this.addDayInterval());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.validationField.valid")
], AddDayInterval.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddDayInterval.prototype, "addDayInterval", null);
__decorate([
    Emit("success")
], AddDayInterval.prototype, "editDayInterval", null);
AddDayInterval = __decorate([
    Component({
        components: { Overlay, Checkbox, Button, Input, Modal },
    })
], AddDayInterval);
export default AddDayInterval;
