import { __decorate } from "tslib";
import { Component, Prop, VModel, Vue, Emit } from "@/types";
import "./search.scss";
import { Input } from "@/ui-kit";
let Search = class Search extends Vue {
    constructor() {
        super(...arguments);
        this.isSearch = false;
    }
    get list() {
        return this.listSearch.filter((item) => item[this.keySearching]
            .toLowerCase()
            .includes(this.searching.toLowerCase()));
    }
    get searcher() {
        return this.searching;
    }
    set searcher(val) {
        this.isSearch = Boolean(val.length);
        this.searching = val;
    }
    closeSearch() {
        if (!this.listSearch.length) {
            this.searching = "";
        }
        this.isSearch = false;
    }
    focus() {
        return;
    }
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    getSearch(item) {
        this.isSearch = false;
        this.searching = item[this.viewResult];
    }
    input(val) {
        return val;
    }
};
__decorate([
    VModel({ type: [String, Number], default: "" })
], Search.prototype, "searching", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], Search.prototype, "listSearch", void 0);
__decorate([
    Prop({ type: String, default: "name" })
], Search.prototype, "keySearching", void 0);
__decorate([
    Prop({ type: String, default: "name" })
], Search.prototype, "viewResult", void 0);
__decorate([
    Prop({ type: String, default: "" })
], Search.prototype, "errors", void 0);
__decorate([
    Emit("focus")
], Search.prototype, "focus", null);
__decorate([
    Emit("getSearch")
], Search.prototype, "getSearch", null);
__decorate([
    Emit("input")
], Search.prototype, "input", null);
Search = __decorate([
    Component({
        components: { Input },
    })
], Search);
export default Search;
