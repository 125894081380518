import { __decorate } from "tslib";
import { Component, Prop, VModel, Vue, Emit } from "@/types";
import "./radio.scss";
import { Icon } from "@/ui-kit";
let Radio = class Radio extends Vue {
    getRadioId() {
        return this.id;
    }
};
__decorate([
    VModel({ required: true, type: Number })
], Radio.prototype, "inputVal", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 1 })
], Radio.prototype, "order", void 0);
__decorate([
    Prop({ type: Number, required: true, default: 1 })
], Radio.prototype, "id", void 0);
__decorate([
    Prop({ type: Array, default: () => [22, 22] })
], Radio.prototype, "size", void 0);
__decorate([
    Emit("getRadioId")
], Radio.prototype, "getRadioId", null);
Radio = __decorate([
    Component({
        components: { Icon },
    })
], Radio);
export default Radio;
