var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"access-form"},[_c('Modal',{attrs:{"footer-position":'right'},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"access-form__up"},[_c('div',{staticClass:"access-form__name"},[_c('Input',{attrs:{"label-position":'left',"errors":_vm.errors.name},on:{"onFocus":function($event){_vm.errors.name = ''}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$l["name"])+" ")]},proxy:true}]),model:{value:(_vm.modalData.name),callback:function ($$v) {_vm.$set(_vm.modalData, "name", $$v)},expression:"modalData.name"}})],1),_c('div',{staticClass:"access-form__type"},[_c('DropDown',{attrs:{"options":_vm.sidedType,"label":_vm.$l['typeSchedules']},on:{"handleClickVariant":_vm.chooseType},model:{value:(_vm.modalData.type),callback:function ($$v) {_vm.$set(_vm.modalData, "type", $$v)},expression:"modalData.type"}})],1)]),_c('div',{staticClass:"access-form__wrap"},[_c('div',{staticClass:"access-form__item"},[_c('Column',{attrs:{"activeId":_vm.modalData.entranceReader,"data":_vm.readersIn,"errors":_vm.errors.entranceReader || _vm.errors.entranceReaderId,"height":241,"withCheckbox":false},on:{"handleColumnSelected":_vm.readersInHandler,"removeError":function($event){(_vm.errors.entranceReader = ''), (_vm.errors.entranceReaderId = '')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['readersIn'])}})]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"model"},[_vm._v(" "+_vm._s(contentProp.name)+" ")])]}}])})],1),_c('div',{staticClass:"access-form__item"},[_c('Column',{attrs:{"activeId":_vm.modalData.entranceZone,"data":_vm.zonesIn,"errors":_vm.errors.entranceZone || _vm.errors.entranceZoneId,"height":241,"withCheckbox":false},on:{"handleColumnSelected":_vm.zonesInHandler,"removeError":function($event){(_vm.errors.entranceZone = ''), (_vm.errors.entranceZoneId = '')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['moveInZones'])}})]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"model"},[_vm._v(" "+_vm._s(contentProp.name)+" ")])]}}])})],1),_c('div',{staticClass:"access-form__item"},[_c('Overlay',{attrs:{"isOverlay":_vm.outReaderAvailable}},[_c('Column',{attrs:{"data":_vm.readersOut,"activeId":_vm.modalData.exitReader,"errors":_vm.errors.exitReader || _vm.errors.exitReaderId,"height":241,"withCheckbox":false},on:{"handleColumnSelected":_vm.readersOutHandler,"removeError":function($event){(_vm.errors.exitReader = ''), (_vm.errors.exitReaderId = '')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['readersOut'])}})]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"model"},[_vm._v(" "+_vm._s(contentProp.name)+" ")])]}}])})],1)],1),_c('div',{staticClass:"access-form__item"},[_c('Overlay',{attrs:{"isOverlay":_vm.outReaderAvailable}},[_c('Column',{attrs:{"data":_vm.zonesOut,"activeId":_vm.modalData.exitZone,"errors":_vm.errors.exitZone || _vm.errors.exitZoneId,"height":241,"withCheckbox":false},on:{"handleColumnSelected":_vm.zonesOutHandler,"removeError":function($event){(_vm.errors.exitZone = ''), (_vm.errors.exitZoneId = '')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$l['moveInZones'])}})]},proxy:true},{key:"content",fn:function(ref){
var contentProp = ref.contentProp;
return [_c('div',{staticClass:"model"},[_vm._v(" "+_vm._s(contentProp.name)+" ")])]}}])})],1)],1)])]},proxy:true},{key:"footer",fn:function(ref){
var buttonType = ref.buttonType;
return [_c('Button',{attrs:{"bg-color":'purple'},on:{"clickHandler":_vm.successForm}},[_vm._v(" "+_vm._s(buttonType)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }