import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "@/types";
import "./button.scss";
let Button = class Button extends Vue {
    clickHandler() {
        return;
    }
    get btnClass() {
        return `button--${this.bgColor}`;
    }
};
__decorate([
    Prop({ default: false })
], Button.prototype, "disabled", void 0);
__decorate([
    Prop({ default: "white" })
], Button.prototype, "bgColor", void 0);
__decorate([
    Emit("clickHandler")
], Button.prototype, "clickHandler", null);
Button = __decorate([
    Component({
        components: {},
    })
], Button);
export default Button;
