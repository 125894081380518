export class Storage {
    static fetch(key) {
        const data = localStorage.getItem(key);
        if (!data) {
            return;
        }
        return JSON.parse(data);
    }
    static install(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    static remove(key) {
        localStorage.removeItem(key);
    }
}
