import { __decorate } from "tslib";
import { Component, Vue } from "@/types";
import "@/assets/scss/app.scss";
import { Loader, Notification } from "@/ui-kit";
import AppLayout from "@/layouts/AppLayout.vue";
import { Auth } from "@/helpers/Auth";
import { useModule } from "vuex-simple";
import { StatusIndicationSocket } from "@/store/modules/sockets/statusIndicationSocket";
import { Socket } from "@/helpers/Socket";
import { Watch } from "vue-property-decorator";
import { Storage } from "@/helpers/storage";
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.isReadyPage = false;
    }
    async beforeCreate() {
        try {
            this.isReadyPage = await Auth.isLoggedIn();
            this.store.statusIndication = new StatusIndicationSocket(new Socket());
            this.store.statusIndication.connect();
        }
        catch (e) {
            console.log(e);
        }
    }
    mounted() {
        this.getOrganizationDefault();
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    indicationUpdate(val) {
        if (val) {
            if (val.socket.closedSocket) {
                setTimeout(() => {
                    this.store.statusIndication = new StatusIndicationSocket(new Socket());
                    this.store.statusIndication.connect();
                }, 5000);
            }
        }
    }
    async getOrganizationDefault() {
        try {
            await this.store.organization.getBaseForUser({
                skip: 0,
            });
            if (!this.store.organization.listForUser.length) {
                return;
            }
            if (!Storage.fetch("organization")) {
                this.store.orgList.setCurrentOrg({
                    id: this.store.organization.listForUser[0].id,
                    name: this.store.organization.listForUser[0].name,
                    accessSchedule: this.store.organization.listForUser[0].accessSchedule,
                });
            }
        }
        catch (e) {
            console.log(e, "organization");
        }
    }
};
__decorate([
    Watch("store.statusIndication", { immediate: true, deep: true })
], App.prototype, "indicationUpdate", null);
App = __decorate([
    Component({
        components: {
            Loader,
            Notification,
            AppLayout,
        },
    })
], App);
export default App;
