import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
export class NotifyModule {
    constructor() {
        this.notificate = [];
    }
    update_notification(notifications) {
        this.notificate = notifications;
    }
    set_notification(notifications) {
        notifications.reduce((acc, curr) => {
            const obj = {
                ...curr,
                ...acc,
                id: Math.random(),
            };
            this.notificate.push(obj);
            setTimeout(() => {
                this.removeNotification(obj.id);
            }, 6000);
            return obj;
        }, []);
    }
    get total() {
        return this.notificate;
    }
    async addNotification(notification) {
        await this.set_notification([notification]);
    }
    removeNotification(removeId) {
        this.update_notification(this.notificate.filter((item) => item.id !== removeId));
    }
}
__decorate([
    State()
], NotifyModule.prototype, "notificate", void 0);
__decorate([
    Mutation()
], NotifyModule.prototype, "update_notification", null);
__decorate([
    Mutation()
], NotifyModule.prototype, "set_notification", null);
__decorate([
    Getter()
], NotifyModule.prototype, "total", null);
__decorate([
    Action()
], NotifyModule.prototype, "addNotification", null);
__decorate([
    Action()
], NotifyModule.prototype, "removeNotification", null);
