import { __decorate } from "tslib";
import { Component, Prop, Vue } from "@/types";
import "./icons.scss";
import { icon } from "@/helpers/iconHelper";
let Icon = class Icon extends Vue {
    get getIcon() {
        return icon(this.name, this.size, this.color);
    }
};
__decorate([
    Prop({ type: String, default: "eyeOpen" })
], Icon.prototype, "name", void 0);
__decorate([
    Prop({ type: Array, default: () => [20, 20] })
], Icon.prototype, "size", void 0);
__decorate([
    Prop({ type: String, default: "main-color" })
], Icon.prototype, "color", void 0);
__decorate([
    Prop()
], Icon.prototype, "className", void 0);
Icon = __decorate([
    Component
], Icon);
export default Icon;
