import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Checkbox, Column, DropDown, Input, Modal, Table, Overlay, Radio, } from "@/ui-kit";
import "./addSchedules.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import Icon from "@/ui-kit/Icon/Icon.vue";
import { addSeconds } from "@/helpers";
let AddSchedules = class AddSchedules extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.inIntervals = [
            {
                id: 1,
                text: "Круглосуточный",
            },
            {
                id: 2,
                text: "Свободный выход",
            },
            {
                id: 3,
                text: "Проход по часам",
            },
        ];
        this.inIntervalsChose = 1;
        this.forAllCheck = false;
        this.modalData = {
            name: "",
            arrivalTime: "",
            departureTime: "",
            freeExit: false,
            accessPoints: [],
            always: true,
        };
        this.arrivalTimeView = "00:00";
        this.departureTimeView = "00:00";
        this.errors = {
            name: "",
            arrivalTime: "",
            departureTime: "",
            accessPoints: "",
        };
        this.accessPointsData = [];
    }
    created() {
        this.getAccessPoints();
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                this.arrivalTimeView = this.modalData.arrivalTime;
                this.departureTimeView = this.modalData.departureTime;
                if (this.modalData.arrivalTime === "23:59:59") {
                    this.arrivalTimeView = "00:00:00";
                }
                if (this.modalData.departureTime === "23:59:59") {
                    this.departureTimeView = "00:00:00";
                }
                const installRadioButton = [
                    {
                        id: 1,
                        isActive: this.modalData.always,
                    },
                    {
                        id: 2,
                        isActive: this.modalData.freeExit,
                    },
                    {
                        id: 3,
                        isActive: !this.modalData.always && !this.modalData.freeExit,
                    },
                ];
                this.inIntervalsChose = installRadioButton.filter((item) => item.isActive)[0].id;
            }
        }
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    get prepareDataToSend() {
        let arrivalTime = "00:00:00";
        let departureTime = "00:00:00";
        if (this.inIntervalsChose === 1) {
            this.modalData.always = true;
            this.modalData.freeExit = false;
            this.arrivalTimeView = "00:00";
            this.departureTimeView = "00:00";
            departureTime = "23:59:59";
        }
        else if (this.inIntervalsChose === 2) {
            this.modalData.always = false;
            this.modalData.freeExit = true;
            this.departureTimeView = "00:00";
            arrivalTime = addSeconds(this.arrivalTimeView);
            departureTime = "23:59:59";
        }
        else {
            this.modalData.always = false;
            this.modalData.freeExit = false;
            arrivalTime = addSeconds(this.arrivalTimeView);
            if (this.departureTimeView === "00:00") {
                departureTime = "23:59:59";
            }
            else {
                departureTime = addSeconds(this.departureTimeView);
            }
        }
        return {
            arrivalTime,
            departureTime,
        };
    }
    get availableSided() {
        return {
            inIntervalsChose: this.inIntervalsChose,
            accessPointsData: this.store.accessPoints.list,
        };
    }
    initDeterminedSided(val) {
        if (val.accessPointsData.length) {
            /**
             * В проходе по часам доступны только двусторонние точки (item.type === 1)
             */
            this.accessPointsData = val.accessPointsData.filter((item) => {
                if (val.inIntervalsChose === 3) {
                    return item.type === 1;
                }
                return item;
            });
            const ids = [];
            this.accessPointsData.forEach((item) => {
                if (this.modalData.accessPoints.includes(item.id)) {
                    ids.push(item.id);
                }
            });
            this.modalData.accessPoints = ids;
            this.forAllCheck = ids.length === this.accessPointsData.length;
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    async addAccessSchedules() {
        this.modalData.arrivalTime = this.prepareDataToSend.arrivalTime;
        this.modalData.departureTime = this.prepareDataToSend.departureTime;
        this.departureTimeView = addSeconds(this.departureTimeView);
        this.arrivalTimeView = addSeconds(this.arrivalTimeView);
        const success = await this.store.accessSchedules.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editAccessSchedules() {
        this.modalData.arrivalTime = this.prepareDataToSend.arrivalTime;
        this.modalData.departureTime = this.prepareDataToSend.departureTime;
        this.departureTimeView = addSeconds(this.departureTimeView);
        this.arrivalTimeView = addSeconds(this.arrivalTimeView);
        const success = await this.store.accessSchedules.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editAccessSchedules()
                : this.addAccessSchedules());
        }
        catch (e) {
            console.log(e);
        }
    }
    async getAccessPoints() {
        try {
            await this.store.accessPoints.get({
                skip: 0,
            });
        }
        catch (e) {
            console.log(e, "accessPointsData");
        }
    }
    setRadio(id) {
        this.inIntervalsChose = id;
        if (this.inIntervalsChose === 1) {
            this.arrivalTimeView = "00:00";
            this.departureTimeView = "00:00";
            this.modalData.always = true;
            this.modalData.freeExit = false;
        }
        if (this.inIntervalsChose === 2) {
            this.arrivalTimeView = "";
            this.departureTimeView = "00:00";
            this.modalData.always = false;
            this.modalData.freeExit = true;
        }
        if (this.inIntervalsChose === 3) {
            this.arrivalTimeView = "";
            this.departureTimeView = "";
            this.modalData.accessPoints = [];
            this.modalData.always = false;
            this.modalData.freeExit = false;
        }
        this.modalData.arrivalTime = "";
        this.modalData.departureTime = "";
        this.errors.arrivalTime = "";
        this.errors.departureTime = "";
    }
    handleAccessPoints(arr) {
        this.modalData.accessPoints = arr;
    }
    handleAllAccessPoints(all) {
        this.modalData.accessPoints = all
            ? Array.from(this.accessPointsData, (item) => item.id)
            : [];
    }
};
__decorate([
    Watch("availableSided", { immediate: true })
], AddSchedules.prototype, "initDeterminedSided", null);
__decorate([
    Watch("store.validationField.valid")
], AddSchedules.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddSchedules.prototype, "addAccessSchedules", null);
__decorate([
    Emit("success")
], AddSchedules.prototype, "editAccessSchedules", null);
AddSchedules = __decorate([
    Component({
        components: {
            Icon,
            Overlay,
            Column,
            DropDown,
            Checkbox,
            Button,
            Input,
            Modal,
            Table,
            Radio,
        },
    })
], AddSchedules);
export default AddSchedules;
