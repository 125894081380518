import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class PassLogModule {
    constructor() {
        this.passLogs = [];
        this.count = 0;
        this.axiosError = null;
    }
    setData(passLogs) {
        this.passLogs = passLogs;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.passLogs;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.passLog.get(options);
            this.setData(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
        }
    }
}
__decorate([
    State()
], PassLogModule.prototype, "passLogs", void 0);
__decorate([
    State()
], PassLogModule.prototype, "count", void 0);
__decorate([
    State()
], PassLogModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], PassLogModule.prototype, "setData", null);
__decorate([
    Mutation()
], PassLogModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], PassLogModule.prototype, "setError", null);
__decorate([
    Getter()
], PassLogModule.prototype, "list", null);
__decorate([
    Getter()
], PassLogModule.prototype, "total", null);
__decorate([
    Getter()
], PassLogModule.prototype, "errorList", null);
__decorate([
    Action()
], PassLogModule.prototype, "get", null);
