import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Table } from "@/ui-kit";
import { AddEmployees } from "@/components";
import "./dismissed.scss";
import { generateFIO } from "@/helpers";
let Dismissed = class Dismissed extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.filter = "year(dismissDate) Gt 1970 and ";
        this.headerData = [
            { name: "Фото", class: "photo", noSort: true },
            { name: "Табельный номер", class: "staffNumber" },
            { name: "ФИО", class: "surname" },
            { name: "Подразделение", class: "departmentName" },
            { name: "Номер ключа", class: "rfid" },
            { name: "График работы", class: "workScheduleName" },
            { name: "График доступа", class: "accessScheduleName" },
        ];
    }
    get showDialog() {
        return this.current?.type === "form";
    }
    get current() {
        return this.store.dialog.curr;
    }
    actionHandler(val) {
        if (val) {
            if (val.name === "recoveryEmployees") {
                this.recoveryEmployees(val);
            }
            if (val.name === "remove") {
                this.removeEmployees(val);
            }
        }
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    recoveryEmployees(val) {
        this.store.dialog.setDetails({
            itemToConfirm: `${val.data.surname} ${val.data.name}`,
            callback: () => {
                const dateFired = {
                    ...this.store.dialog.curr?.data,
                    dismissDate: null,
                };
                return this.store.employees.edit(dateFired).then((isSuccess) => {
                    if (isSuccess) {
                        this.reload(true);
                    }
                    return isSuccess;
                });
            },
        });
    }
    removeEmployees(val) {
        this.store.dialog.setDetails({
            itemToConfirm: `${val.data.surname} ${val.data.name}`,
            callback: () => {
                return this.store.employees
                    .delete(this.current?.data.id)
                    .then((isSuccess) => {
                    if (isSuccess) {
                        this.reload(true);
                    }
                    return isSuccess;
                });
            },
        });
    }
    fioHelper(val) {
        return generateFIO(val);
    }
};
__decorate([
    Ref("table")
], Dismissed.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Dismissed.prototype, "actionHandler", null);
Dismissed = __decorate([
    Component({
        components: { AddEmployees, Table },
    })
], Dismissed);
export default Dismissed;
