import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Table } from "@/ui-kit";
import { AddGuestPasses } from "@/components";
import "./guestPasses.scss";
import { generateFIO } from "@/helpers";
let GuestPasses = class GuestPasses extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Фото", class: "photo", noSort: true },
            { name: "ФИО", class: "surname" },
            { name: "Номер ключа", class: "rfid" },
            { name: "График доступа", class: "accessScheduleName" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeGuest(val) {
        if (val?.name === "remove") {
            this.store.dialog.setDetails({
                itemToConfirm: `${val.data.surname} ${val.data.name}`,
            });
        }
    }
    fioHelper(val) {
        return generateFIO(val);
    }
};
__decorate([
    Ref("table")
], GuestPasses.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], GuestPasses.prototype, "removeGuest", null);
GuestPasses = __decorate([
    Component({
        components: { AddGuestPasses, Table },
    })
], GuestPasses);
export default GuestPasses;
