import { __decorate } from "tslib";
import { Component, Prop, Vue } from "@/types";
import "./overlay.scss";
let Overlay = class Overlay extends Vue {
};
__decorate([
    Prop({ default: false })
], Overlay.prototype, "isOverlay", void 0);
Overlay = __decorate([
    Component
], Overlay);
export default Overlay;
