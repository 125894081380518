var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("input " + (_vm.inputStyle.main))},[(_vm.labelPosition)?_c('div',{class:[
      'input__label',
      _vm.inputStyle.label,
      _vm.disabled && 'input__label--disable' ]},[_vm._t("label")],2):_vm._e(),_c('div',{staticClass:"input__wrap"},[((_vm.types)==='checkbox')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"},{name:"mask",rawName:"v-mask",value:(_vm.installMask),expression:"installMask"}],ref:"inputRef",staticClass:"area",class:[
        _vm.disabled && 'area--disable',
        _vm.isPass && 'area--pass',
        _vm.isSearch && 'area--search',
        _vm.errors && 'area--error' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxlength,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputVal)?_vm._i(_vm.inputVal,null)>-1:(_vm.inputVal)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"keyup":_vm.onEnter,"change":function($event){var $$a=_vm.inputVal,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputVal=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputVal=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputVal=$$c}}}},'input',_vm.$attrs,false)):((_vm.types)==='radio')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"},{name:"mask",rawName:"v-mask",value:(_vm.installMask),expression:"installMask"}],ref:"inputRef",staticClass:"area",class:[
        _vm.disabled && 'area--disable',
        _vm.isPass && 'area--pass',
        _vm.isSearch && 'area--search',
        _vm.errors && 'area--error' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxlength,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputVal,null)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"keyup":_vm.onEnter,"change":function($event){_vm.inputVal=null}}},'input',_vm.$attrs,false)):_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputVal),expression:"inputVal"},{name:"mask",rawName:"v-mask",value:(_vm.installMask),expression:"installMask"}],ref:"inputRef",staticClass:"area",class:[
        _vm.disabled && 'area--disable',
        _vm.isPass && 'area--pass',
        _vm.isSearch && 'area--search',
        _vm.errors && 'area--error' ],attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxlength,"type":_vm.types},domProps:{"value":(_vm.inputVal)},on:{"blur":_vm.onBlur,"focus":_vm.onFocus,"keyup":_vm.onEnter,"input":function($event){if($event.target.composing){ return; }_vm.inputVal=$event.target.value}}},'input',_vm.$attrs,false)),_vm._t("wrap"),_c('transition',{attrs:{"name":"fade"}},[(_vm.errors && _vm.watchError)?_c('div',{class:_vm.isLeftErrors ? 'input__error input__error--left' : 'input__error'},[_vm._v(" "+_vm._s(_vm.errors)+" ")]):_vm._e()]),(_vm.errors)?_c('div',{class:_vm.isLeftErrors
          ? 'input__explanation input__explanation--left'
          : 'input__explanation',on:{"mouseenter":_vm.showError,"mouseleave":function($event){_vm.watchError = false}}},[_c('Icon',{staticClass:"input__explanation-icon",attrs:{"color":'white',"size":[4, 20],"name":'errorExclamation'}})],1):_vm._e(),(_vm.isPass)?_c('Icon',{staticClass:"input__pass",attrs:{"name":'eye' + (_vm.isHidePass ? 'Open' : 'Close')},on:{"click":_vm.iconShow}}):_vm._e(),(_vm.isSearch)?_c('Icon',{staticClass:"input__search",attrs:{"size":[18, 18],"name":'search'}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }