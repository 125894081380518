import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class DayIntervals extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "working-intervals";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(dayInterval) {
        try {
            await this.$post(this.baseURL, dayInterval);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(dayInterval) {
        try {
            await this.$put(`${this.baseURL}/${dayInterval.id}`, dayInterval);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(dayIntervalId) {
        try {
            await this.$delete(`${this.baseURL}/${dayIntervalId}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
