import { __decorate } from "tslib";
import { Component, Emit, Ref, useModule, Vue, Watch, } from "@/types";
import { Button, InputNumber, Input, Modal, Loader } from "@/ui-kit";
import "./addReader.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddReader = class AddReader extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.errors = {
            name: "",
            ipAddress: "",
            login: "",
            httpPort: 0,
            password: "",
        };
        this.modalData = {
            name: "",
            ipAddress: "",
            httpPort: 80,
            login: "",
            password: "",
        };
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
            }
        }
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    async addReaders() {
        const success = await this.store.readers.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editReaders() {
        const success = await this.store.readers.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            this.modal.setLoading(true);
            await (this.currDialog?.name === "save"
                ? this.editReaders()
                : this.addReaders());
        }
        catch (e) {
            console.log(e);
        }
        finally {
            this.modal.setLoading(false);
        }
    }
    openBrowser() {
        window.open(`http://${this.modalData.ipAddress}:${this.modalData.httpPort}`, "_blank");
    }
};
__decorate([
    Ref("modal")
], AddReader.prototype, "modal", void 0);
__decorate([
    Watch("store.validationField.valid")
], AddReader.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddReader.prototype, "addReaders", null);
__decorate([
    Emit("success")
], AddReader.prototype, "editReaders", null);
AddReader = __decorate([
    Component({
        components: { Button, InputNumber, Input, Modal, Loader },
    })
], AddReader);
export default AddReader;
