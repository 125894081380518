import { Routes } from "@/contstant";
export function childrenRouteRender(parentRoute = "administration") {
    const parentKeyRoute = parentRoute.toLowerCase();
    return Routes[parentKeyRoute].map((item) => {
        return {
            name: item,
            icon: item,
            route: item[0].toUpperCase() + item.slice(1),
        };
    });
}
