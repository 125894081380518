import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Table } from "@/ui-kit";
import { AddReader, SearchReader } from "@/components";
import "./readers.scss";
let Readers = class Readers extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Cтатус", class: "status", noSort: true },
            { name: "Имя", class: "name" },
            { name: "IP", class: "ipAddress" },
            { name: "Модель", class: "model" },
            { name: "Порт", class: "httpPort" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form" && !this.searchDialog;
    }
    get searchDialog() {
        return this.store.dialog.curr?.name === "search";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    openBrowser(ipAddress, httpPort) {
        window.open(`http://${ipAddress}:${httpPort}`, "_blank");
    }
    removeReaders(val) {
        if (val?.name === "remove") {
            let confirm = false;
            this.store.dialog.setDetails({
                callback: () => {
                    return this.store.readers
                        .delete({ id: val.data.id, confirm: confirm })
                        .then((isSuccess) => {
                        if (isSuccess) {
                            this.reload(true);
                        }
                        if (this.store.readers.errorList?.response?.status === 449) {
                            confirm = true;
                        }
                        return isSuccess;
                    });
                },
            });
        }
    }
};
__decorate([
    Ref("table")
], Readers.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Readers.prototype, "removeReaders", null);
Readers = __decorate([
    Component({
        components: { SearchReader, AddReader, Table },
    })
], Readers);
export default Readers;
