import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "@/types";
import "./select.scss";
import { Button, Icon } from "@/ui-kit";
let Select = class Select extends Vue {
    constructor() {
        super(...arguments);
        this.chosenVariant = localStorage.getItem("top") || 10;
        this.isVisible = false;
    }
    onCloseClick() {
        this.isVisible = false;
    }
    onChooseOption(item) {
        this.isVisible = false;
        this.chosenVariant = item;
    }
};
__decorate([
    Prop({ default: () => [500, 200, 100, 50, 20, 10], type: Array })
], Select.prototype, "options", void 0);
__decorate([
    Emit("onChooseOption")
], Select.prototype, "onChooseOption", null);
Select = __decorate([
    Component({
        components: { Button, Icon },
    })
], Select);
export default Select;
