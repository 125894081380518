import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Icon, Loader, Table, TableWS } from "@/ui-kit";
import { AddSysLogFilter, PhotoVerification } from "@/components";
import "./sysLog.scss";
import { Socket } from "@/helpers/Socket";
import { SystemEventsSocket } from "@/store/modules/sockets/systemEventsSocket";
import { dateIntervalCreated } from "@/helpers/oDataDateIntervalFilter";
import { onFileAction } from "@/helpers";
let Syslog = class Syslog extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            {
                name: "Дата и время",
                class: "timeStamp",
                noSort: true,
            },
            {
                name: "Источник",
                class: "sourceName",
                noSort: true,
            },
            { name: "Событие", class: "message", noSort: true },
            {
                name: "Пользователь",
                class: "userName",
                noSort: true,
            },
        ];
        this.wsData = null;
        this.isSwitchToWs = true;
        this.filterSettings = "";
        this.loading = true;
        this.orderByForReport = "";
        this.currentTable = TableWS;
    }
    async isPlaying() {
        this.loading = true;
        if (this.store.dialog.currPlayPause === "play") {
            this.isSwitchToWs = true;
            await this.installSysEventsFilter(false);
            this.currentTable = TableWS;
            this.createSysLogSocket();
            this.addSortKey(false);
            this.loading = false;
        }
        if (this.store.dialog.currPlayPause === "pause") {
            this.isSwitchToWs = false;
            if (this.tableSocket) {
                this.tableSocket.destroy();
                this.wsData = null;
            }
            await this.installSysEventsFilter(true);
            this.currentTable = Table;
            this.addSortKey(true);
            this.loading = false;
        }
    }
    onSortClick(val) {
        this.orderByForReport = val;
    }
    async downloadPDF(val) {
        if (val?.name === "pdf") {
            try {
                this.store.statusReport.changeStatusReport();
                const data = await this.store.reports.getReportsSysLog({
                    skip: 0,
                    orderby: this.orderByForReport || "timeStamp desc",
                    filter: this.filterSettings,
                });
                this.store.statusReport.changeStatusReport();
                onFileAction(data, "Системный журнал");
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    async installSysEventsFilter(isDateSwitchAvailable = false) {
        try {
            await this.store.systemEventsFilter.get({
                skip: 0,
            });
            const result = this.store.systemEventsFilter.list;
            let filter = "";
            let intervalsCreated = {};
            const existKeyFilter = Object.entries(result).reduce((acc, [key, val]) => {
                switch (key) {
                    case "levelIds":
                    case "sourceNames":
                    case "userIds":
                        //рЕжим 's' для фильтра//
                        key = key.slice(0, -1);
                        break;
                    case "timeIntervalType":
                    case "timeIntervalDuration":
                    case "timeIntervalBeginDate":
                    case "timeIntervalBeginTime":
                    case "timeIntervalEndDate":
                    case "timeIntervalEndTime":
                        if (!isDateSwitchAvailable) {
                            val = null;
                            break;
                        }
                        intervalsCreated[key] = val;
                        //В фильтре ключ для даты //
                        key = "timeStamp";
                        val = dateIntervalCreated(intervalsCreated);
                        break;
                    default:
                        break;
                }
                acc[key] = val;
                return acc;
            }, {});
            Object.entries(existKeyFilter).forEach(([key, val]) => {
                if (Array.isArray(val)) {
                    /**Фильтрация по текстовым значениям**/
                    if (["sourceName", "userId"].includes(key)) {
                        val = val.map((item) => `'${item}'`);
                    }
                    filter += ` and ${key} in (${val})`;
                }
                else {
                    if (val !== null) {
                        if (key === "timeStamp") {
                            filter += ` and ${val}`;
                        }
                        else {
                            filter += ` and ${key} eq ${val}`;
                        }
                    }
                }
            });
            this.filterSettings = filter.replace(" and", "");
        }
        catch (e) {
            console.log("installSysEventsFilter");
        }
    }
    createSysLogSocket() {
        this.tableSocket = new SystemEventsSocket(new Socket(), this.filterSettings);
        this.tableSocket.connect();
        this.tableSocket.setUpdateDataHandler(() => {
            this.wsData = this.tableSocket.data;
            this.loading = false;
        });
    }
    addSortKey(isSortKeyNeed) {
        let headerDataWithSort = [];
        this.headerData.forEach((item) => {
            if (item.noSort && isSortKeyNeed) {
                delete item.noSort;
            }
            else {
                item.noSort = true;
            }
            headerDataWithSort.push(item);
        });
        this.headerData = headerDataWithSort;
    }
    async reloadAfterSaveFilter(success) {
        if (!success)
            return;
        try {
            if (this.store.dialog.currPlayPause === "play") {
                if (this.tableSocket) {
                    this.tableSocket.destroy();
                    this.wsData = null;
                    await this.installSysEventsFilter(false);
                    this.createSysLogSocket();
                    this.store.dialog.removeDialog();
                }
            }
            if (this.store.dialog.currPlayPause === "pause") {
                await this.installSysEventsFilter(true);
                await this.table.updatePage();
            }
        }
        catch (e) {
            console.log("reloadAfterSaveFilter");
        }
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    beforeDestroy() {
        if (this.tableSocket) {
            this.tableSocket.destroy();
        }
    }
};
__decorate([
    Ref("table")
], Syslog.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.currPlayPause", { immediate: true })
], Syslog.prototype, "isPlaying", null);
__decorate([
    Watch("store.dialog.curr")
], Syslog.prototype, "downloadPDF", null);
Syslog = __decorate([
    Component({
        components: {
            Loader,
            PhotoVerification,
            AddSysLogFilter,
            Icon,
            Table,
            TableWS,
        },
    })
], Syslog);
export default Syslog;
