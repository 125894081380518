export class BaseSocket {
    constructor(Socket) {
        this.apiUrl = "";
        this.socket = Socket;
    }
    connect() {
        this.socket.init(this.apiUrl);
    }
    setUpdateDataHandler(handler) {
        this.socket.onMessageHandler = handler;
    }
    get data() {
        return this.socket.getData();
    }
    destroy() {
        this.socket.destroy();
    }
}
