import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Reports extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "reports-templates";
        this.baseURL_PDF = "reports";
        this.baseURL_PDF_SYS_LOG = "reports/system-events";
        this.baseURL_PDF_PASS_LOG = "reports/pass-events";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getReports(id) {
        try {
            const url = `${this.baseURL_PDF}/${id}`;
            const { data } = await this.$get(url, {}, { responseType: "arraybuffer" });
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getReportsSysLog(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL_PDF_SYS_LOG, options);
            const { data } = await this.$get(url, {}, { responseType: "arraybuffer" });
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async getReportsPassLog(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL_PDF_PASS_LOG, options);
            const { data } = await this.$get(url, {}, { responseType: "arraybuffer" });
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(report) {
        try {
            await this.$post(this.baseURL, report);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(report) {
        try {
            await this.$put(`${this.baseURL}/${report.id}`, report);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(report) {
        try {
            await this.$delete(`${this.baseURL}/${report}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
