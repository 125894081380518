import { createDecorator } from "vue-class-component";
export const debounce = createDecorator((options, key) => {
    if (options?.methods) {
        const originalMethod = options.methods[key];
        let timeoutID = null;
        options.methods[key] = function wrapperMethod(...args) {
            const time = this.$props?.debounce ?? 1000;
            clearTimeout(timeoutID);
            timeoutID = setTimeout(() => originalMethod.apply(this, args), time);
        };
    }
});
