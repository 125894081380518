import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue } from "@/types";
import { Icon, Table } from "@/ui-kit";
import "./accessPoints.scss";
import { AddAccessPoints } from "@/components";
let AccessPoints = class AccessPoints extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Имя", class: "name" },
            { name: "Тип", class: "type" },
            { name: "Считыватель на вход", class: "entranceReaderName" },
            { name: "Ведет в зону", class: "entranceZoneName" },
            { name: "Считыватель на выход", class: "exitReaderName" },
            { name: "Ведет в зону", class: "exitZoneName" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], AccessPoints.prototype, "table", void 0);
AccessPoints = __decorate([
    Component({
        components: { AddAccessPoints, Icon, Table },
    })
], AccessPoints);
export default AccessPoints;
