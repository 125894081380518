import { __decorate } from "tslib";
import { Component, Vue } from "@/types";
const defaultLayout = "LayoutDefault";
let AppLayout = class AppLayout extends Vue {
    get layout() {
        const layout = this.$route.meta?.layout || defaultLayout;
        return () => import(`/${layout}.vue`);
    }
};
AppLayout = __decorate([
    Component
], AppLayout);
export default AppLayout;
