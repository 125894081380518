import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue } from "@/types";
import { Icon, Table } from "@/ui-kit";
import "@/views/Administration/AccessPoints/accessPoints.scss";
import { AddSchedules } from "@/components";
import "./accessSchedules.scss";
let AccessSchedules = class AccessSchedules extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "График доступа", class: "name" }];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], AccessSchedules.prototype, "table", void 0);
AccessSchedules = __decorate([
    Component({
        components: { AddSchedules, Icon, Table },
    })
], AccessSchedules);
export default AccessSchedules;
