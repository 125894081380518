/**
 * Подробности в задаче http://redmine.beward.ru/issues/34904
 * @param error
 */
export function validationParse(error) {
    return Object.entries(error).reduce((acc, [key, val]) => {
        acc[key] = val.length > 1 ? val[0] : val.join("");
        return acc;
    }, {});
}
