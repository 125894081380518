import { __decorate } from "tslib";
import { Component, Prop, Vue, Emit, Watch, useModule, Ref, VModel, } from "@/types";
import "./searchApi.scss";
import { Column, Icon, Checkbox, Loader, InputNumber } from "@/ui-kit";
import { debounce } from "@/helpers/decorators/debounce";
import { concatFilters } from "@/helpers";
let SearchApi = class SearchApi extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.list = [];
        this.loading = true;
        this.isOpen = false;
    }
    async getData() {
        if (this.searching !== "") {
            try {
                await this.store[this.moduleApi].get({
                    skip: 0,
                    top: this.top,
                    filter: `${this.contains} ${this.isFilteredByOrg}`,
                });
                this.list = this.store[this.moduleApi].list;
            }
            catch (e) {
                console.log(e, "searching");
            }
            finally {
                this.loading = false;
            }
            return;
        }
        this.loading = true;
    }
    get currentInput() {
        return () => {
            return import(`@/ui-kit/${this.isTextSearch ? "Input/Input" : "InputNumber/InputNumber"}.vue`);
        };
    }
    get contains() {
        let contain = `contains(${this.filterSearchValue}, '${this.searching}')`;
        if (this.filterSearchValue === "surname") {
            contain = concatFilters(this.searching);
        }
        return encodeURIComponent(contain);
    }
    get isFilteredByOrg() {
        return this.byOrganization
            ? `and organization eq ${this.store.orgList.currentOrg?.id}`
            : "";
    }
    searchFocusHandler(e) {
        if (e.target.closest(".search-api__selections")) {
            return;
        }
        this.inputRef.$refs.inputRef.focus();
    }
    closeSearchHandler() {
        this.isOpen = false;
    }
    onHandleVariant(item) {
        this.searching = item[this.searchRowName];
        this.closeSearchHandler();
    }
    focus() {
        return;
    }
    onInput(val) {
        this.loading = true;
        if (val.length === 0) {
            this.closeSearchHandler();
            return;
        }
        this.isOpen = true;
    }
};
__decorate([
    Ref("inputRef")
], SearchApi.prototype, "inputRef", void 0);
__decorate([
    VModel({ type: String, default: "" })
], SearchApi.prototype, "searching", void 0);
__decorate([
    Prop({ required: true, type: String })
], SearchApi.prototype, "moduleApi", void 0);
__decorate([
    Prop({ type: String, default: "" })
], SearchApi.prototype, "placeholder", void 0);
__decorate([
    Prop({ default: "name", type: String })
], SearchApi.prototype, "filterSearchValue", void 0);
__decorate([
    Prop({ default: "name", type: String })
], SearchApi.prototype, "searchRowName", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], SearchApi.prototype, "byOrganization", void 0);
__decorate([
    Prop({ default: 10, type: Number })
], SearchApi.prototype, "top", void 0);
__decorate([
    Prop({ type: String, default: "" })
], SearchApi.prototype, "errors", void 0);
__decorate([
    Prop({ type: Number, default: 1000 })
], SearchApi.prototype, "debounce", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], SearchApi.prototype, "isTextSearch", void 0);
__decorate([
    debounce,
    Watch("searching")
], SearchApi.prototype, "getData", null);
__decorate([
    Emit("onHandleVariant")
], SearchApi.prototype, "onHandleVariant", null);
__decorate([
    Emit("focus")
], SearchApi.prototype, "focus", null);
SearchApi = __decorate([
    Component({
        components: { Loader, InputNumber, Icon, Column, Checkbox },
    })
], SearchApi);
export default SearchApi;
