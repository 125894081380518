export const dateIntervalCreated = (objDefault = {
    timeIntervalType: 1,
    timeIntervalDuration: 1,
    timeIntervalBeginDate: null,
    timeIntervalBeginTime: null,
    timeIntervalEndDate: null,
    timeIntervalEndTime: null,
}, key = "timeStamp") => {
    const { timeIntervalType, timeIntervalDuration, timeIntervalBeginDate, timeIntervalBeginTime, timeIntervalEndDate, timeIntervalEndTime, } = objDefault;
    const date = new Date();
    const fromDate = new Date();
    const toDate = new Date();
    let beginDate = "";
    let endDate = "";
    // Не равен custom //
    if (timeIntervalType !== 3) {
        //Текущая день//
        if (timeIntervalType === 1) {
            if (timeIntervalDuration === 1) {
                fromDate.setUTCHours(0, 0, 0, 0);
                toDate.setUTCHours(23, 59, 59, 999);
            }
            //Текущая Неделя//
            if (timeIntervalDuration === 2) {
                fromDate.setDate(date.getDate() + 1 - date.getDay());
                toDate.setDate(date.getDate());
            }
            //Текущий Месяц//
            if (timeIntervalDuration === 3) {
                fromDate.setDate(1);
            }
        }
        if (timeIntervalType === 2) {
            //Предыдущий день//
            if (timeIntervalDuration === 1) {
                fromDate.setDate(date.getDate() - 1);
                toDate.setDate(date.getDate() - 1);
            }
            //Предыдущая Неделя//
            if (timeIntervalDuration === 2) {
                fromDate.setDate(date.getDate() - 6 - date.getDay());
                toDate.setDate(date.getDate() - 1);
            }
            //Предыдущий Месяц//
            if (timeIntervalDuration === 3) {
                fromDate.setMonth(date.getMonth() - 1);
                fromDate.setDate(1);
                toDate.setMonth(date.getMonth());
                toDate.setDate(0);
            }
        }
        fromDate.setUTCHours(0, 0, 0, 0);
        toDate.setUTCHours(23, 59, 59, 999);
        beginDate = fromDate.toISOString();
        endDate = toDate.toISOString();
    }
    else {
        /**
         * Приводим к виду ISO
         */
        beginDate = `${timeIntervalBeginDate}T${timeIntervalBeginTime}Z`;
        endDate = `${timeIntervalEndDate}T${timeIntervalEndTime}Z`;
        //TODO проверить на 23:59 ? "59.999" : "00.000",
    }
    return `${key} gt ${beginDate} and ${key} lt ${endDate} `;
};
