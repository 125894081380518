import { __decorate } from "tslib";
import { Component, Vue } from "@/types";
import { Button, DropDown, Icon, Tooltip } from "@/ui-kit";
import { SectionRoute } from "@/components";
import { HeaderDropdownsStorage } from "@/components/Header/HeaderChild";
let HeaderNavigation = class HeaderNavigation extends Vue {
};
HeaderNavigation = __decorate([
    Component({
        components: {
            HeaderDropdownsStorage,
            DropDown,
            SectionRoute,
            Button,
            Icon,
            Tooltip,
        },
    })
], HeaderNavigation);
export default HeaderNavigation;
