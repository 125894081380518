import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Checkbox, Overlay, Column, Input, Loader, Modal, Table, } from "@/ui-kit";
import "./searchReader.scss";
import Icon from "@/ui-kit/Icon/Icon.vue";
let SearchReader = class SearchReader extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.loader = true;
        this.data = [];
        this.selectedTo = [];
        this.selectedAll = false;
        this.errors = {
            name: "",
            ipAddress: "",
            login: "",
            password: "",
        };
        this.modalData = {
            name: "",
            ipAddress: "",
            login: "",
            password: "",
            httpPort: 80,
        };
        this.headerData = [
            { name: "Модель", class: "model" },
            { name: "IP", class: "ipAddress" },
            { name: "MAC", class: "macAddress" },
            { name: "", class: "checkbox" },
            { name: "Статус", class: "status" },
        ];
    }
    get checkForZeroId() {
        return this.data.filter((item) => item.id === 0).length;
    }
    toggleCheckbox(data) {
        this.selectedTo = this.selectedTo.includes(data)
            ? this.selectedTo.filter((item) => item !== data)
            : [...this.selectedTo, data];
        this.selectedAll = this.selectedTo.length === this.checkForZeroId;
    }
    toggleAllCheckbox() {
        this.selectedTo = this.selectedAll
            ? this.data.filter((item) => item.id === 0)
            : [];
    }
    async created() {
        try {
            await this.store.readers.search();
            this.data = this.store.readers.listSearchReader;
            this.loader = false;
        }
        catch (e) {
            this.loader = false;
        }
    }
    isValid() {
        this.errors.login = this.store.validationField.valid["login"];
        this.errors.password = this.store.validationField.valid["password"];
    }
    async addReaders() {
        const success = await Promise.all(this.selectedTo.map(async (reader) => {
            let sendReaders = {
                ...reader,
                login: this.modalData.login,
                password: this.modalData.password,
                name: reader.name,
            };
            const result = await this.store.readers.add({
                ...sendReaders,
            });
            await this.store.notification.addNotification({
                type: result ? "success" : "error",
                message: result
                    ? `Считыватель ${reader.ipAddress}:${reader.httpPort} успешно добавлен`
                    : `Считыватель ${reader.ipAddress}:${reader.httpPort} не был добавлен`,
            });
            return result;
        }));
        return success.some((item) => Boolean(item));
    }
    async successForm() {
        try {
            await this.addReaders();
        }
        catch (e) {
            console.log(e);
        }
    }
    openBrowser(ipAddress, httpPort) {
        window.open(`http://${ipAddress}:${httpPort}`, "_blank");
    }
};
__decorate([
    Watch("store.validationField.valid")
], SearchReader.prototype, "isValid", null);
__decorate([
    Emit("success")
], SearchReader.prototype, "addReaders", null);
SearchReader = __decorate([
    Component({
        components: {
            Icon,
            Overlay,
            Column,
            Loader,
            Checkbox,
            Button,
            Input,
            Modal,
            Table,
        },
    })
], SearchReader);
export default SearchReader;
