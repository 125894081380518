import { __decorate } from "tslib";
import { Component, Emit, Prop } from "@/types";
import "./inputNumber.scss";
import { Icon, Input } from "@/ui-kit";
import { debounce } from "@/helpers/decorators/debounce";
let InputNumber = class InputNumber extends Input {
    handleKeyup(event) {
        if (this.inputVal === "")
            return;
        if (typeof this.inputVal === "string") {
            this.inputVal = this.inputVal.replace(/\D/g, "");
            if (this.min >= 1) {
                if (this.inputVal.charAt(0) === "0") {
                    this.inputVal = this.inputVal.replace("0", "");
                }
            }
        }
        if (this.max && this.inputVal > this.max) {
            this.inputVal = this.max;
        }
        else if (this.min && this.inputVal < this.min) {
            this.inputVal = this.min;
        }
        return +this.inputVal;
    }
};
__decorate([
    Prop({ type: Number })
], InputNumber.prototype, "min", void 0);
__decorate([
    Prop({ type: Number })
], InputNumber.prototype, "max", void 0);
__decorate([
    Prop({ type: Number, default: 0 })
], InputNumber.prototype, "debounce", void 0);
__decorate([
    debounce,
    Emit("handleKeyup")
], InputNumber.prototype, "handleKeyup", null);
InputNumber = __decorate([
    Component({
        components: { Icon },
        inheritAttrs: false,
    })
], InputNumber);
export default InputNumber;
