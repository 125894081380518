import { __decorate } from "tslib";
import { Vue, Component, Emit, Prop } from "@/types";
import "./pagination.scss";
import { Button, Icon, Input, Select } from "@/ui-kit";
import { ONLY_DIGIT } from "@/contstant";
let Pagination = class Pagination extends Vue {
    constructor() {
        super(...arguments);
        this.isBlur = false;
        this.isPressedEnter = false;
        this.skip = 0;
        this.currentPage = 1;
        this.setPage = 1;
    }
    get currentTop() {
        return this.pagination.top;
    }
    get pagCalc() {
        return {
            total: this.total,
            per_page: this.currentTop,
            current_page: this.currentPage,
            last_page: Math.ceil(this.total / this.currentTop),
            from: (this.currentPage - 1) * this.currentTop + 1,
            to: this.currentPage * this.currentTop,
        };
    }
    enterKey(event) {
        if (event.code === "Enter") {
            this.goPageTo();
        }
    }
    handleBlur() {
        this.isBlur = true;
        this.goPageTo();
    }
    setCurrentPage(skip = 0, top) {
        return {
            skip: skip,
            top: top ?? this.currentTop,
        };
    }
    goPageTo() {
        const setPageNumber = +this.setPage;
        if (this.currentPage === setPageNumber) {
            this.setPage = this.currentPage;
            return;
        }
        if (this.isBlur && this.isPressedEnter) {
            this.isPressedEnter = false;
            this.isBlur = false;
            return;
        }
        this.currentPage = setPageNumber;
        this.skip = this.pagCalc.to - this.pagCalc.per_page;
        if (!ONLY_DIGIT.test(String(setPageNumber)) ||
            setPageNumber <= 1 ||
            setPageNumber > this.pagCalc.last_page) {
            this.currentPage = 1;
            this.setPage = 1;
            this.skip = 0;
        }
        this.setCurrentPage(this.skip);
    }
    goToFirstPage() {
        this.currentPage = 1;
        this.setPage = 1;
        this.skip = 0;
    }
    nextPage() {
        this.skip += this.currentTop;
        this.currentPage += 1;
        this.setPage = this.currentPage;
        this.setCurrentPage(this.skip);
    }
    prevPage() {
        this.skip -= this.currentTop;
        this.currentPage -= 1;
        if (this.skip <= 0) {
            this.skip = 0;
            this.currentPage = 1;
        }
        this.setPage = this.currentPage;
        this.setCurrentPage(this.skip);
    }
    getTop(top) {
        this.currentPage = 1;
        this.setPage = 1;
        this.skip = 0;
        this.setCurrentPage(this.skip, top);
        localStorage.setItem("top", top.toString());
    }
};
__decorate([
    Prop({ type: Number, required: true })
], Pagination.prototype, "total", void 0);
__decorate([
    Prop({ type: Object, required: true })
], Pagination.prototype, "pagination", void 0);
__decorate([
    Emit("setCurrentPage")
], Pagination.prototype, "setCurrentPage", null);
__decorate([
    Emit("goToFirstPage")
], Pagination.prototype, "goToFirstPage", null);
Pagination = __decorate([
    Component({
        components: {
            Select,
            Input,
            Button,
            Icon,
        },
    })
], Pagination);
export default Pagination;
