import { __decorate } from "tslib";
import { Component, Emit, Prop, useModule, Vue, Watch, } from "@/types";
import { Button, Calendar, Column, Icon, Input, InputNumber, Modal, } from "@/ui-kit";
import "./addEmployees.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { ImgValidate } from "@/helpers/decorators/img";
import { CameraCapture, ReaderPhotos } from "@/components";
import { Storage } from "@/helpers/storage";
import { modifyingDateToIso } from "@/helpers";
let AddEmployees = class AddEmployees extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            surname: "",
            name: "",
            patronymic: "",
            position: "",
            staffNumber: "",
            document: "",
            photo: "",
            phone: "",
            birthDate: "",
            dismissDate: "",
            rfid: "",
            identityCardStartDate: "",
            identityCardStopDate: "",
            organization: this.store.orgList.currentOrg?.id,
            department: null,
            workSchedule: null,
            accessSchedule: null,
        };
        this.errors = {
            surname: "",
            name: "",
            patronymic: "",
            position: "",
            staffNumber: "",
            document: "",
            photo: "",
            phone: "",
            birthDate: "",
            dismissDate: "",
            rfid: "",
            identityCardStartDate: "",
            identityCardStopDate: "",
            organization: "",
            department: "",
            accessSchedule: "",
        };
        this.accessSchedulesData = [];
        this.subdivisionsData = [];
        this.workSchedulesData = [];
        this.avatar = "/images/photo.jpg";
        this.isUploadCamera = false;
        this.isUploadReader = false;
    }
    created() {
        this.getData();
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                this.avatar = data.photo || "/images/photo.jpg";
                this.modalData.id = data.id;
            }
        }
    }
    async getData() {
        try {
            await Promise.all([
                this.store.accessSchedules.get({
                    skip: 0,
                }),
                this.store.workSchedules.get({
                    skip: 0,
                    filter: `organization eq ${this.store.orgList.currentOrg?.id}`,
                }),
                this.store.subdivisions.get({
                    skip: 0,
                    filter: `organization eq ${this.store.orgList.currentOrg?.id}`,
                }),
            ]);
            this.accessSchedulesData = this.store.accessSchedules.list;
            this.subdivisionsData = this.store.subdivisions.list;
            this.workSchedulesData = this.store.workSchedules.list;
            if (this.isEmployeesDismissed) {
                if (!this.modalData.accessSchedule) {
                    this.accessSchedulesData = [];
                }
                if (!this.modalData.department) {
                    this.subdivisionsData = [];
                }
                if (!this.modalData.workSchedule) {
                    this.workSchedulesData = [];
                }
            }
        }
        catch (e) {
            console.log(e, "accessSchedulesData");
        }
    }
    currentOrgChange() {
        if (!this.currDialog?.data) {
            const getIdOrg = Storage.fetch("organization");
            if (getIdOrg) {
                this.modalData.accessSchedule = getIdOrg["accessSchedule"];
            }
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    uploadImage(e, ...error) {
        if (error.join("") !== "") {
            this.store.notification.addNotification({
                type: "error",
                message: error.join(""),
            });
            return;
        }
        const target = e.target;
        const file = target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = (e) => {
            this.store.notification.addNotification({
                type: "error",
                message: "что-то пошло не так, попробуйте позже",
            });
            return;
        };
        reader.onload = (e) => {
            if (e?.target?.result) {
                this.avatar = e.target.result;
                this.modalData.photo = e.target.result;
            }
        };
    }
    takePhotoFromCamera(picFromCamera) {
        this.avatar = picFromCamera;
        this.modalData.photo = picFromCamera;
        this.isUploadCamera = false;
    }
    takePhotoFromReader(picFromTfr) {
        this.avatar = picFromTfr;
        this.modalData.photo = picFromTfr;
        this.isUploadReader = false;
    }
    resetImage() {
        this.avatar = "/images/photo.jpg";
        this.modalData.photo = "";
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    async addEmployees() {
        const birth = this.modalData.birthDate;
        const startDate = this.modalData.identityCardStartDate;
        const endDate = this.modalData.identityCardStopDate;
        const success = await this.store.employees.add({
            ...this.modalData,
            birthDate: modifyingDateToIso(birth),
            identityCardStartDate: modifyingDateToIso(startDate),
            identityCardStopDate: modifyingDateToIso(endDate),
        });
        if (this.store.employees.errorList?.response?.status === 449) {
            this.modalData.confirm = true;
        }
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editEmployees() {
        const birth = this.modalData.birthDate;
        const startDate = this.modalData.identityCardStartDate;
        const endDate = this.modalData.identityCardStopDate;
        const success = await this.store.employees.edit({
            ...this.modalData,
            birthDate: modifyingDateToIso(birth),
            identityCardStartDate: modifyingDateToIso(startDate),
            identityCardStopDate: modifyingDateToIso(endDate),
        });
        if (this.store.employees.errorList?.response?.status === 449) {
            this.modalData.confirm = true;
        }
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editEmployees()
                : this.addEmployees());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
    workScheduleHandler(result) {
        this.modalData["workSchedule"] = result;
    }
    departmentHandler(result) {
        if (result) {
            this.modalData.accessSchedule = this.subdivisionsData.filter((item) => result === item.id)[0].accessSchedule;
        }
        else {
            this.currentOrgChange();
        }
        this.getData();
        this.modalData["department"] = result;
    }
    accessScheduleHandler(result) {
        this.modalData["accessSchedule"] = result;
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], AddEmployees.prototype, "isEmployeesDismissed", void 0);
__decorate([
    Watch("store.organizationDropdown.currentOrg", { immediate: true })
], AddEmployees.prototype, "currentOrgChange", null);
__decorate([
    Watch("store.validationField.valid")
], AddEmployees.prototype, "isValid", null);
__decorate([
    ImgValidate
], AddEmployees.prototype, "uploadImage", null);
__decorate([
    Emit("success")
], AddEmployees.prototype, "addEmployees", null);
__decorate([
    Emit("success")
], AddEmployees.prototype, "editEmployees", null);
AddEmployees = __decorate([
    Component({
        components: {
            ReaderPhotos,
            InputNumber,
            Column,
            CameraCapture,
            Button,
            Input,
            Modal,
            Calendar,
            Icon,
        },
    })
], AddEmployees);
export default AddEmployees;
