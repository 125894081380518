import { __decorate } from "tslib";
import "./sectionRoute.scss";
import { Component, Prop, Vue } from "@/types";
import { MENU_SECTION_DEFAULT } from "@/contstant";
import { Icon } from "@/ui-kit";
import { Auth } from "@/helpers/Auth";
let SectionRoute = class SectionRoute extends Vue {
    constructor() {
        super(...arguments);
        this.sectionRoutes = [];
    }
    get initRoute() {
        if (this.routes.length > 0) {
            return this.routes;
        }
        return this.sectionRoutes;
    }
    async mounted() {
        try {
            const { sections } = await Auth.currentUser();
            this.sectionRoutes = MENU_SECTION_DEFAULT.filter((route) => {
                const { id } = route;
                if (Array.isArray(sections)) {
                    return sections.includes(id);
                }
                return id === sections;
            });
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    Prop({ default: "white", type: String })
], SectionRoute.prototype, "color", void 0);
__decorate([
    Prop({ default: () => [52, 52], type: Array })
], SectionRoute.prototype, "iconSize", void 0);
__decorate([
    Prop({ default: () => [], type: Array })
], SectionRoute.prototype, "routes", void 0);
SectionRoute = __decorate([
    Component({
        components: { Icon },
    })
], SectionRoute);
export default SectionRoute;
