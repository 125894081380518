import { __decorate } from "tslib";
import { Component, useModule, Vue } from "@/types";
import { Button, DropDown, Icon, Tooltip, Modal } from "@/ui-kit";
import { SectionRoute } from "@/components";
import { Watch } from "vue-property-decorator";
let HeaderHelp = class HeaderHelp extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.productVersion = "";
        this.copyrightYears = "";
        this.open = false;
    }
    onOpenHandler() {
        if (this.open) {
            this.store.readers.getSysInfo().then((res) => {
                if (res) {
                    this.productVersion = res.productVersion;
                    this.copyrightYears = res.copyrightYears;
                }
            });
        }
    }
};
__decorate([
    Watch("open", { immediate: true })
], HeaderHelp.prototype, "onOpenHandler", null);
HeaderHelp = __decorate([
    Component({
        components: { SectionRoute, Button, Icon, DropDown, Tooltip, Modal },
    })
], HeaderHelp);
export default HeaderHelp;
