import { AccessPoints, AccessSchedules, Organization, Readers, Users, Zones, } from "@/views";
export default [
    { path: "readers", name: "Readers", component: Readers },
    { path: "zones", name: "Zones", component: Zones },
    { path: "access-points", name: "AccessPoints", component: AccessPoints },
    {
        path: "access-schedules",
        name: "AccessSchedules",
        component: AccessSchedules,
    },
    { path: "users", name: "Users", component: Users },
    { path: "organization", name: "Organization", component: Organization },
];
