import axios from "axios";
import { Auth } from "@/helpers/Auth";
import { checkEndUrlConfig } from "@/helpers/checkEndUrlConfig";
const AsyncRouter = import("@/router");
/**#37783**/
const timerBeforeLogout = null;
const errorResponseHandler = async (error) => {
    const status = error.response?.status;
    if (status === 403 || status === 505) {
        await AsyncRouter.then((route) => {
            route.default.push({ path: `error`, query: { status: `${status}` } });
        });
    }
    return Promise.reject(error);
};
const client = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
});
client.interceptors.response.use((response) => response, errorResponseHandler);
client.interceptors.request.use(function (request = {}) {
    request.baseURL = `${checkEndUrlConfig(Auth.getWebGatewayUri)}api`;
    request.headers.Authorization = `Bearer ${Auth.getToken}`;
    return request;
});
export { client };
