import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class WorkSchedulesModule {
    constructor() {
        this.workSchedules = [];
        this.count = 0;
        this.axiosError = null;
    }
    setItems(workSchedule) {
        this.workSchedules = workSchedule;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.workSchedules;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.workSchedules.get(options);
            this.setItems(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async add(workSchedule) {
        const removerUnnecessaryKeys = Object.entries(workSchedule).reduce((acc, [key, val]) => {
            acc[key] = val;
            if (key === "workingIntervals") {
                acc[key] = val.map(({ name, id, ...item }) => ({
                    ...item,
                }));
            }
            return acc;
        }, {});
        try {
            await service.workSchedules.add(removerUnnecessaryKeys);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async edit(workSchedule) {
        const removerUnnecessaryKeys = Object.entries(workSchedule).reduce((acc, [key, val]) => {
            acc[key] = val;
            if (key === "workingIntervals") {
                acc[key] = val.map(({ name, id, ...item }) => ({
                    ...item,
                }));
            }
            return acc;
        }, {});
        try {
            await service.workSchedules.edit(removerUnnecessaryKeys);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async delete(workScheduleId) {
        try {
            await service.workSchedules.delete(workScheduleId);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
}
__decorate([
    State()
], WorkSchedulesModule.prototype, "workSchedules", void 0);
__decorate([
    State()
], WorkSchedulesModule.prototype, "count", void 0);
__decorate([
    State()
], WorkSchedulesModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], WorkSchedulesModule.prototype, "setItems", null);
__decorate([
    Mutation()
], WorkSchedulesModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], WorkSchedulesModule.prototype, "setError", null);
__decorate([
    Getter()
], WorkSchedulesModule.prototype, "list", null);
__decorate([
    Getter()
], WorkSchedulesModule.prototype, "total", null);
__decorate([
    Getter()
], WorkSchedulesModule.prototype, "errorList", null);
__decorate([
    Action()
], WorkSchedulesModule.prototype, "get", null);
__decorate([
    Action()
], WorkSchedulesModule.prototype, "add", null);
__decorate([
    Action()
], WorkSchedulesModule.prototype, "edit", null);
__decorate([
    Action()
], WorkSchedulesModule.prototype, "delete", null);
