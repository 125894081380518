import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue } from "@/types";
import "./header.scss";
import { Icon } from "@/ui-kit";
import { Auth } from "@/helpers/Auth";
import { HeaderHelp, HeaderLogout, HeaderIndication, HeaderNavigation, ActionButtons, } from "@/components/Header/HeaderChild";
let Header = class Header extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.currentUser = null;
    }
    get getParentRoute() {
        return this.$l[this.$route.matched[0].name];
    }
    async created() {
        try {
            const { name } = await Auth.currentUser();
            this.currentUser = name;
        }
        catch (e) {
            console.log(e);
        }
    }
    clickMenu() {
        return;
    }
};
__decorate([
    Emit("clickMenu")
], Header.prototype, "clickMenu", null);
Header = __decorate([
    Component({
        components: {
            HeaderIndication,
            HeaderNavigation,
            HeaderHelp,
            HeaderLogout,
            ActionButtons,
            Icon,
        },
    })
], Header);
export default Header;
