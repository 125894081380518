import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Subdivision extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "departments";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(subdivision) {
        try {
            await this.$post(this.baseURL, subdivision);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(subdivision) {
        try {
            await this.$put(`${this.baseURL}/${subdivision.id}`, subdivision);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(subdivisionId, confirmed = false) {
        try {
            await this.$delete(`${this.baseURL}/${subdivisionId}?confirm=${confirmed}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
