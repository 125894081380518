import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./adjustments.scss";
import { Table } from "@/ui-kit";
import { AddAdjustments } from "@/components";
let Holidays = class Holidays extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: "Дата", class: "date" },
            { name: "Табельный номер", class: "staffNumber" },
            { name: "Сотрудник", class: "surname" },
        ];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeUser(val) {
        if (val?.name === "remove") {
            this.store.dialog.setDetails({
                itemToConfirm: "empty",
            });
        }
    }
};
__decorate([
    Ref("table")
], Holidays.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Holidays.prototype, "removeUser", null);
Holidays = __decorate([
    Component({
        components: { AddAdjustments, Table },
    })
], Holidays);
export default Holidays;
