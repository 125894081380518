import { __decorate } from "tslib";
import { Getter, Mutation, State } from "@/types";
export class TableRowModule {
    constructor() {
        this.currentRow = null;
    }
    setCurrentRow(payload) {
        this.currentRow = payload;
    }
    get current() {
        return this.currentRow;
    }
}
__decorate([
    State()
], TableRowModule.prototype, "currentRow", void 0);
__decorate([
    Mutation()
], TableRowModule.prototype, "setCurrentRow", null);
__decorate([
    Getter()
], TableRowModule.prototype, "current", null);
