import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Icon, Table, TableWS, Loader } from "@/ui-kit";
import { PhotoVerification, AddPassLogFilter } from "@/components";
import "./passlog.scss";
import { Socket } from "@/helpers/Socket";
import { PhotoVerificationSocket } from "@/store/modules/sockets/photoVerificationSocket";
import { onFileAction, generateFIO } from "@/helpers";
import { dateIntervalCreated } from "@/helpers/oDataDateIntervalFilter";
let Passlog = class Passlog extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.choosen = 0;
        this.activeConfiguration = null;
        this.headerData = [
            {
                id: 1,
                name: this.$l["columnFilterTime"],
                class: "timeStamp",
                noSort: true,
            },
            {
                id: 2,
                name: this.$l["columnFilterReader"],
                class: "readerName",
                noSort: true,
            },
            {
                id: 3,
                name: this.$l["columnFilterFio"],
                class: "surname",
                noSort: true,
            },
            {
                id: 4,
                name: this.$l["columnFilterMethod"],
                class: "passMethod",
                noSort: true,
            },
            {
                id: 5,
                name: this.$l["columnFilterAccess"],
                class: "passAllowed",
                noSort: true,
            },
        ];
        this.isVisibleColumn = [
            {
                id: 8,
                name: this.$l["columnFilterTemp"],
                class: "temperature",
                noSort: true,
            },
            {
                id: 9,
                name: this.$l["columnFilterMask"],
                class: "maskDetected",
                noSort: true,
            },
        ];
        this.wsData = null;
        this.isSwitchToWs = true;
        this.firstReader = {};
        this.secondReader = {};
        this.thirdReader = {};
        this.fourthReader = {};
        this.readyPage = false;
        this.filterTable = "";
        this.loading = true;
        this.orderByForReport = "";
        this.currentTable = TableWS;
    }
    created() {
        this.createWS();
    }
    async isPlaying() {
        this.loading = true;
        if (this.store.dialog.currPlayPause === "play") {
            this.isSwitchToWs = true;
            await this.installPassEventFilter(false);
            this.currentTable = TableWS;
            this.createPhotoVerificationSocket();
            this.addSortKey(false);
            this.loading = false;
        }
        if (this.store.dialog.currPlayPause === "pause") {
            this.isSwitchToWs = false;
            if (this.photoVerificationSocket) {
                this.photoVerificationSocket.destroy();
            }
            this.tableSocket.destroy();
            this.wsData = null;
            await this.installPassEventFilter(true);
            this.currentTable = Table;
            this.addSortKey(true);
            this.loading = false;
        }
    }
    onSortClick(val) {
        this.orderByForReport = val;
    }
    async downloadPDF(val) {
        if (val?.name === "pdf") {
            try {
                this.store.statusReport.changeStatusReport();
                const data = await this.store.reports.getReportsPassLog({
                    skip: 0,
                    orderby: this.orderByForReport || "timeStamp desc",
                    filter: this.filterTable,
                });
                this.store.statusReport.changeStatusReport();
                onFileAction(data, "Журнал проходов");
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    async createWS() {
        try {
            await this.store.configurations.get({
                top: "",
                skip: 0,
                orderby: "",
                filter: "",
            });
            this.activeConfiguration = this.store.configurations.list.filter((i) => i.isActive)[0];
            if (this.activeConfiguration) {
                this.choosen = this.activeConfiguration.verificationType;
                await this.installPassEventFilter();
                this.setTableColumns();
                this.createPhotoVerificationSocket();
            }
        }
        catch (e) {
            console.log(e, "createWS");
        }
        finally {
            this.readyPage = true;
        }
    }
    async installPassEventFilter(isDateSwitchAvailable = false) {
        try {
            await this.store.passEventsFilter.get({
                skip: 0,
            });
            const result = this.store.passEventsFilter.list;
            let filter = "";
            let intervalsCreated = {};
            const existKeyFilter = Object.entries(result).reduce((acc, [key, val]) => {
                switch (key) {
                    case "organizationIds":
                    case "passMethodIds":
                    case "readerIds":
                    case "personIds":
                        //рЕжим 's' для фильтра//
                        key = key.slice(0, -1);
                        break;
                    case "timeIntervalType":
                    case "timeIntervalDuration":
                    case "timeIntervalBeginDate":
                    case "timeIntervalBeginTime":
                    case "timeIntervalEndDate":
                    case "timeIntervalEndTime":
                        if (!isDateSwitchAvailable) {
                            val = null;
                            break;
                        }
                        intervalsCreated[key] = val;
                        //В фильтре ключ для даты //
                        key = "timeStamp";
                        val = dateIntervalCreated(intervalsCreated);
                        break;
                    default:
                        break;
                }
                acc[key] = val;
                return acc;
            }, {});
            Object.entries(existKeyFilter).forEach(([key, val]) => {
                if (Array.isArray(val)) {
                    filter += ` and ${key} in (${val})`;
                }
                else {
                    if (val !== null) {
                        if (key === "timeStamp") {
                            filter += ` and ${val}`;
                        }
                        else if (key === "temperature") {
                            filter += ` and ${key} gt ${val}`;
                        }
                        else {
                            filter += ` and ${key} eq ${val}`;
                        }
                    }
                }
            });
            this.filterTable = filter.replace(" and", "");
        }
        catch (e) {
            console.log("installPassEventFilter");
        }
    }
    async onOpenDoor(id) {
        if (!id) {
            return;
        }
        try {
            const [success, error = null] = await this.store.readers.getOpenDoor(id);
            if (error?.response?.status === 500) {
                await this.store.notification.addNotification({
                    type: "error",
                    message: this.$l["serverUnavailable"],
                });
                return;
            }
            if (error?.response?.data.detail) {
                await this.store.notification.addNotification({
                    type: "error",
                    message: error?.response?.data.detail,
                });
                return;
            }
            if (success) {
                await this.store.notification.addNotification({
                    type: "success",
                    message: this.$l["success"],
                });
            }
        }
        catch (e) {
            console.log(e);
        }
    }
    setTableColumns() {
        if (this.activeConfiguration?.columns) {
            const configColumns = this.activeConfiguration.columns;
            this.isVisibleColumn.forEach((item) => {
                if (configColumns.includes(item.id)) {
                    this.headerData = [
                        ...this.headerData,
                        {
                            name: item.name,
                            class: item.class,
                            noSort: true,
                        },
                    ].sort((a, b) => {
                        if ("id" in a && "id" in b) {
                            return a.id - b.id;
                        }
                        return 0;
                    });
                }
            });
        }
    }
    // 508 добавил в .sort() предикат;
    createPhotoVerificationSocket() {
        if (!this.activeConfiguration) {
            return;
        }
        this.photoVerificationSocket = new PhotoVerificationSocket(new Socket(), this.filterPhotoVerify);
        this.photoVerificationSocket.connect();
        this.photoVerificationSocket.setUpdateDataHandler(() => {
            const data = this.photoVerificationSocket.data;
            if (this.activeConfiguration) {
                const { firstReaderId = null, secondReaderId = null, thirdReaderId = null, fourthReaderId = null, } = this.activeConfiguration;
                if (firstReaderId === data.readerId) {
                    this.firstReader = this.preparePhotoVerifyData(data);
                }
                if (secondReaderId === data.readerId) {
                    this.secondReader = this.preparePhotoVerifyData(data);
                }
                if (thirdReaderId === data.readerId) {
                    this.thirdReader = this.preparePhotoVerifyData(data);
                }
                if (fourthReaderId === data.readerId) {
                    this.fourthReader = this.preparePhotoVerifyData(data);
                }
            }
            this.loading = false;
        });
        /**
         * Сокет для таблицы
         */
        this.tableSocket = new PhotoVerificationSocket(new Socket(), this.filterTable);
        this.tableSocket.connect();
        this.tableSocket.setUpdateDataHandler(() => {
            if (this.tableSocket.data) {
                const data = this.tableSocket.data;
                const surname = data.surname ?? "";
                const name = generateFIO(data.name ?? "");
                const patronymic = generateFIO(data.patronymic ?? "");
                const fullName = `${surname} ${name} ${patronymic}`;
                this.wsData = {
                    ...data,
                    surname: fullName,
                };
                this.loading = false;
            }
        });
    }
    preparePhotoVerifyData(data) {
        const fullName = `${data.surname ?? ""} ${data.name ?? ""} ${data.patronymic ?? ""}`;
        return Object.entries(data).reduce((acc, [key, val]) => {
            acc[key] = val;
            acc["fullName"] = fullName ?? "";
            return acc;
        }, {});
    }
    addSortKey(isSortKeyNeed) {
        let headerDataWithSort = [];
        this.headerData.forEach((item) => {
            if (item.noSort && isSortKeyNeed) {
                delete item.noSort;
            }
            else {
                item.noSort = true;
            }
            headerDataWithSort.push(item);
        });
        this.headerData = headerDataWithSort;
    }
    async reloadAfterSaveFilter(success) {
        if (!success)
            return;
        try {
            if (this.store.dialog.currPlayPause === "play") {
                if (this.photoVerificationSocket) {
                    this.photoVerificationSocket.destroy();
                }
                this.tableSocket.destroy();
                this.wsData = null;
                await this.installPassEventFilter(false);
                this.createPhotoVerificationSocket();
                this.store.dialog.removeDialog();
            }
            if (this.store.dialog.currPlayPause === "pause") {
                await this.installPassEventFilter(true);
                await this.table.updatePage();
            }
        }
        catch (e) {
            console.log("reloadAfterSaveFilter");
        }
    }
    get filterPhotoVerify() {
        if (!this.activeConfiguration) {
            return "";
        }
        const readers = [
            this.activeConfiguration.firstReaderId,
            this.activeConfiguration.secondReaderId,
            this.activeConfiguration.thirdReaderId,
            this.activeConfiguration.fourthReaderId,
        ].filter((i) => i);
        if (!readers.length) {
            return "";
        }
        return `ReaderId in (${readers.join(",")})`;
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    fioHelper(val) {
        if (!val) {
            return "";
        }
        return generateFIO(val);
    }
    beforeDestroy() {
        if (this.photoVerificationSocket) {
            this.photoVerificationSocket.destroy();
            this.tableSocket.destroy();
        }
    }
};
__decorate([
    Ref("table")
], Passlog.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.currPlayPause", { immediate: true })
], Passlog.prototype, "isPlaying", null);
__decorate([
    Watch("store.dialog.curr")
], Passlog.prototype, "downloadPDF", null);
Passlog = __decorate([
    Component({
        components: {
            TableWS,
            Loader,
            PhotoVerification,
            AddPassLogFilter,
            Icon,
            Table,
        },
    })
], Passlog);
export default Passlog;
