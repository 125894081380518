import modalAction from "@/language/modal";
/* ошибки запроса */
const errors = {
    serviceUnavailable: "Сервис недоступен",
    accessDenied: "У вас нет доступа к этой странице",
    notFound: "Страницы с таким адресом не существует",
    undefinedError: "Что-то пошло не так, попробуйте позже",
    closedSocket: "Нет связи с сервером. Обратитесь к системному администратору.",
    serverUnavailable: "Сервер недоступен. Обратитесь к системному администратору.",
};
/* успешные фразы */
const success = {
    success: "Успешно",
};
const placeholders = {
    inputMethods: "Введите наименование метода прохода",
    inputSource: "Введите наименование считывателя или «Сервер»",
    inputUser: "Введите логин пользователя системы СКУД",
    inputType: "Введите уровень события: «Информирование», «Предупреждение» или «Ошибка»",
};
/* наименование основных разделов */
const sectionsMenu = {
    Administration: "Администрирование",
    FilingCabinet: "Картотека",
    Monitoring: "Мониторинг",
    TimeAttendance: "Учёт рабочего времени",
};
/* наименование дочерних разделов Администрирование */
const administrationMenu = {
    readers: "Считыватели",
    zones: "Зоны",
    accessPoints: "Точки доступа",
    accessSchedules: "Графики доступа",
    users: "Пользователи",
};
/* наименование дочерних разделов Картотека */
const filingCabinetMenu = {
    dismissed: "Уволенные",
    employees: "Сотрудники",
    guestPasses: "Гостевые пропуска",
    organization: "Организации",
    subdivisions: "Подразделения",
};
/*  наименование дочерних разделов Мониторинг */
const monitoringMenu = {
    passlog: "Журнал проходов",
    configurations: "Конфигурации",
    syslog: "Системный журнал",
};
/* наименование дочерних разделов Учет рабочего времени */
const timeAttendanceMenu = {
    dayIntervals: "Рабочие интервалы",
    workSchedules: "Графики работы",
    holidays: "Календарь праздников",
    reports: "Отчёты",
    adjustments: "Корректировки",
    absences: "Причины неявок",
};
const administrationPages = {
    //считыватели//
    name: "Имя",
    status: "Статус",
    ip: "IP",
    model: "Модель",
    port: "Порт",
    host: "Хост",
    //автопоиск считывателей//
    openInBrowser: "Открыть в браузере",
    autoSearch: "Автопоиск считывателей",
    mac: "MAC",
    //зоны
    //точки доступа
    readersIn: "Считыватель на вход",
    moveInZones: "Ведет в зону",
    readersOut: "Считыватель на выход",
    editAccessPoint: "Редактировать точку доступа",
    addAccessPoint: "Добавить точку доступа",
    typeSchedules: "Тип",
    //Графики доступа
    freeExit: "Свободный выход",
    timeCome: "Время прихода",
    timeGone: "Время ухода",
    listGraphAccess: "Список точек доступа",
    onlyTwoSided: "данный вид графика доступен только для двусторонних точек доступа",
    //пользователи
    func: "Функционал",
    //организация//
    organizationHeader: "Организация",
    defaultGraphAccess: "График доступа по умолчанию",
};
const fillingCabinetPage = {
    //добавить сотрудника//
    secondName: "Фамилия",
    patronymic: "Отчество",
    position: "Должность",
    keyNumber: "Номер ключа",
    keyDoc: "Номер документа",
    phoneNum: "Номер телефона",
    birthDay: "Дата рождения",
    personalNumber: "Табельный номер",
    passStart: "Дата начала действия пропуска",
    passEnd: "Дата окончания действия пропуска",
    departments: "Подразделение",
    oneAccessSchedules: "График доступа",
    oneWorkSchedules: "График работы",
    infoAboutDismissedEmployees: "Просмотр данных о сотруднике",
    // добавить гостевой пропуск
    readersAccess: "Контрольный считыватель",
    oncePasses: "Разовый пропуск",
    activePasses: "Пропуск активен",
    activePassesCount: "Раз",
    numberOfPasses: "(количество проходов)",
    //Добавить гостя//
    permanentGuest: "Постоянный пропуск",
    onceGuest: "Разовый пропуск",
    mainAccessPoint: "Контрольная точка доступа",
    //Добавить с TFR
    addPhoto: "Добавить фото",
    notFoundPhotoForLastHour: "За последний час не найдено ни одного нераспознанного лица",
};
const MonitoringPage = {
    //конфигурация//
    configurationName: "Имя конфигурации",
    photoVerificationType: "Тип фотоверификации",
    readerNumber: "Считыватель",
    visibleColumn: "Выводить колонки",
    columnFilterTime: "Дата и время",
    columnFilterReader: "Считыватель",
    columnFilterFio: "ФИО",
    columnFilterEvent: "Событие",
    columnFilterAccess: "Доступ",
    columnFilterMethod: "Метод",
    columnFilterTemp: "T ℃",
    columnFilterMask: "Маска",
    notReaders: "Не использовать",
    twoReaders: "Два считывателя",
    fourReaders: "Четыре считывателя",
    isActive: "Активна",
    configuration: "Конфигурация",
    errorColumnIsEmpty: "Должна быть выбрана хотя бы одна колонка",
    //журнал проходов//
    method: "Метод",
    timeEnter: "Время прохода",
    passAllowed: "Проход разрешён",
    passDenied: "Проход запрещён",
    notActiveConfiguration: "Сформировать конфигурацию Журнала проходов",
    accessYes: "Разрешен",
    accessNo: "Запрещен",
    tempMoreThen: "T, °C (более, чем)",
    //системный журнал//
    sysSource: "Источник",
    sysUser: "Пользователь",
    sysTypeEvent: "Тип события",
    userEmployees: "Сотрудник",
};
const TimeAttendancePage = {
    //рабочие интервалы//
    workIntervalContinuous: "Продолжительность смены в скользящем графике",
    useSlipSchedule: "Использовать скользящий график",
    halfContinuous: "Продолжительность перерыва",
    //График работы
    zonesList: "Список зон",
    ignoreHolidays: "Учитывать календарь праздников",
    autoClose: "Автоматически закрывать смену, если не было выхода",
    intervalList: "Список интервалов",
    daysInGraph: "Дней в графике",
    //Календрарь праздников
    dayType: "Тип дня",
    festiveDay: "Праздничный день",
    shortDay: "Сокращенный день",
    workDay: "Рабочий выходной",
    vectorShort: "Величина сокращения",
    dateChange: "Дата переноса",
    //Корректировки
    setDayBegin: "Задать новое время прихода",
    setDayEnd: "Задать новое время ухода",
    remark: "Примечание",
    //Отчеты
    reportType: "Тип отчета",
    firstInLastOut: "Первый вход - Последний выход",
    notWorkHour: "Наработанные часы за период",
    //Причина неявок
    setDateAdjustments: "Задать даты отсутствия :",
    beginPeriod: "Начало периода",
    endPeriod: "Конец периода",
    reason: "Причина:",
    vacation: "Отпуск",
    healthList: "Больничный лист",
    businessTrip: "Командировка",
    offWork: "Отгул",
};
//фильтрация по дате в модалках//
const filtersDate = {
    specifyPeriod: "Указать период",
    specifyTimeAndDate: "Указать дату и время",
    currentTime: "Текущий(ая)",
    pastTime: "Предыдущий(ая)",
};
//о программе//
const aboutProgramInfo = {
    aboutProgram: "О программе",
    programName: "Beward Access Control",
    authorProgram: "ООО «НПП Бевард», ",
    policyProgram: "Данная программа защищается законами об авторских правах и международными конвенциями.",
    policyProgram2: "Отчеты об ошибках и предложения по улучшению программного продукта просьба отправлять на e-mail:",
    authorEmail: "support@beward.ru",
    programVersion: "Версия",
};
const lang = {
    yes: "Да",
    no: "Нет",
    save: "Сохранить",
    add: "Добавить",
    send: "Отправить",
    filter: "Применить",
    login: "Логин",
    password: "Пароль",
    number: "Номер",
    entry: "Войти",
    welcome: "Здравствуйте, ",
    openManual: "Открыть руководство пользователя",
    aboutScud: "О программе",
    notFoundSearch: "Не найдено",
    typicalRequestError: "Нет связи с сервером. Попробуйте повторить запрос позже",
    userChange: "Смена пользователя",
    onMainRoute: "На главную",
    noDate: "Нет данных",
    day: "День",
    week: "Неделя",
    month: "Месяц",
    accessTrue: "Разрешен",
    accessFalse: "Запрещен",
    all: "Все",
    have: "Есть",
    date: "Дата",
    noOrganizationLength: "Нет доступа ни к одной организации",
    beginDate: "Дата начала",
    endDate: "Дата окончания",
    beginTime: "Время начала",
    endTime: "Время окончания",
    reset: "Cбросить все",
    modalTitle: (route = "Zones", name = "add") => {
        const action = {
            add: "Добавить",
            save: "Редактировать",
            remove: "Удалить",
            removeEmployees: "Уволить",
            recoveryEmployees: "Восстановить",
            filter: "Настройка фильтра",
        };
        return `${action[name]} ${name === "filter" ? "" : modalAction[route]}`;
    },
    confirmRequest: (name = "Zones", item = "зону", confirmAction = "") => {
        return `
            <div class="confirm__wrap">
              <span>Вы действительно хотите ${confirmAction.toLowerCase()}</span>
              ${item === "empty"
            ? "?"
            : `<span class="confirm__item">${item}?</span>`}
            </div>
            `;
    },
    ...sectionsMenu,
    ...administrationMenu,
    ...monitoringMenu,
    ...timeAttendanceMenu,
    ...administrationPages,
    ...fillingCabinetPage,
    ...filingCabinetMenu,
    ...MonitoringPage,
    ...TimeAttendancePage,
    ...errors,
    ...success,
    ...filtersDate,
    ...aboutProgramInfo,
    ...placeholders,
};
export default lang;
