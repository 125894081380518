import { __decorate } from "tslib";
import "@/assets/scss/views/sections.scss";
import { Component, Vue } from "@/types";
import { SectionRoute } from "@/components";
let Sections = class Sections extends Vue {
};
Sections = __decorate([
    Component({
        components: { SectionRoute },
    })
], Sections);
export default Sections;
