import { __decorate } from "tslib";
import { Component, Vue, useModule } from "@/types";
import "./notification.scss";
import { Icon } from "@/ui-kit";
let Notification = class Notification extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
    }
};
Notification = __decorate([
    Component({
        components: { Icon },
    })
], Notification);
export default Notification;
