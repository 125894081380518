import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class SystemEventsFilterModule {
    constructor() {
        this.systemEventsFilter = {};
        this.axiosError = null;
    }
    setData(systemEventsFilter) {
        this.systemEventsFilter = systemEventsFilter;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.systemEventsFilter;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.systemEventsFilter.get(options);
            this.setData(data);
        }
        catch (error) {
            this.setError(error);
        }
    }
    async add(systemEventsFilter) {
        try {
            await service.systemEventsFilter.add(systemEventsFilter);
            return [true];
        }
        catch (error) {
            this.setError(error);
            return [false, error];
        }
    }
}
__decorate([
    State()
], SystemEventsFilterModule.prototype, "systemEventsFilter", void 0);
__decorate([
    State()
], SystemEventsFilterModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], SystemEventsFilterModule.prototype, "setData", null);
__decorate([
    Mutation()
], SystemEventsFilterModule.prototype, "setError", null);
__decorate([
    Getter()
], SystemEventsFilterModule.prototype, "list", null);
__decorate([
    Getter()
], SystemEventsFilterModule.prototype, "errorList", null);
__decorate([
    Action()
], SystemEventsFilterModule.prototype, "get", null);
__decorate([
    Action()
], SystemEventsFilterModule.prototype, "add", null);
