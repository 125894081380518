import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class DayIntervalsModule {
    constructor() {
        this.dayIntervals = [];
        this.count = 0;
        this.axiosError = null;
    }
    setItems(dayInterval) {
        this.dayIntervals = dayInterval;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.dayIntervals;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.dayIntervals.get(options);
            this.setItems(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async add(dayInterval) {
        try {
            await service.dayIntervals.add(dayInterval);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async edit(dayInterval) {
        try {
            await service.dayIntervals.edit(dayInterval);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
    async delete(dayIntervalId) {
        try {
            await service.dayIntervals.delete(dayIntervalId);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
            // push into Notice
            // server text error - "error.response.data.message"
            // or default error - "error.response.statusText"
            // or custom error message
            // or return Promise.reject(error)
        }
    }
}
__decorate([
    State()
], DayIntervalsModule.prototype, "dayIntervals", void 0);
__decorate([
    State()
], DayIntervalsModule.prototype, "count", void 0);
__decorate([
    State()
], DayIntervalsModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], DayIntervalsModule.prototype, "setItems", null);
__decorate([
    Mutation()
], DayIntervalsModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], DayIntervalsModule.prototype, "setError", null);
__decorate([
    Getter()
], DayIntervalsModule.prototype, "list", null);
__decorate([
    Getter()
], DayIntervalsModule.prototype, "total", null);
__decorate([
    Getter()
], DayIntervalsModule.prototype, "errorList", null);
__decorate([
    Action()
], DayIntervalsModule.prototype, "get", null);
__decorate([
    Action()
], DayIntervalsModule.prototype, "add", null);
__decorate([
    Action()
], DayIntervalsModule.prototype, "edit", null);
__decorate([
    Action()
], DayIntervalsModule.prototype, "delete", null);
