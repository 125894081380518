import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue } from "@/types";
import "./zones.scss";
import { Table } from "@/ui-kit";
import { AddZone } from "@/components";
let Zones = class Zones extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "Зона", class: "name" }];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], Zones.prototype, "table", void 0);
Zones = __decorate([
    Component({
        components: { AddZone, Table },
    })
], Zones);
export default Zones;
