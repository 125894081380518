import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Table } from "@/ui-kit";
import { AddUser } from "@/components";
import "./users.scss";
let Users = class Users extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "Пользователь", class: "login" }];
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    removeUser(val) {
        if (val?.name === "remove") {
            this.store.dialog.setDetails({
                itemToConfirm: `${val.data.login}`,
            });
        }
    }
};
__decorate([
    Ref("table")
], Users.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Users.prototype, "removeUser", null);
Users = __decorate([
    Component({
        components: { AddUser, Table },
    })
], Users);
export default Users;
