import { createDecorator } from "vue-class-component";
// Declare Log decorator.
export const ImgValidate = createDecorator((options, key) => {
    if (options?.methods) {
        const originalMethod = options.methods[key];
        options.methods[key] = function wrapperMethod(...args) {
            let error = "";
            const target = args[0].target.files[0];
            const oneMb = 1000141;
            if (target && target.name.match(/\.(jpeg|jpg|png|bmp)$/)) {
                if (target.size > oneMb) {
                    error = "Размер файла не должен превышать 1 Мб.";
                }
            }
            else {
                error = "Файл имеет неподдерживаемый формат.";
            }
            originalMethod.apply(this, [...args, error]);
        };
    }
});
