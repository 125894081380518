import { __decorate } from "tslib";
import { Component, VModel, Vue, Emit } from "@/types";
import "./toggle.scss";
import { Icon } from "@/ui-kit";
let Toggler = class Toggler extends Vue {
    toggleHandler() {
        this.toggle = !this.toggle;
        return this.toggle;
    }
};
__decorate([
    VModel({ type: Boolean, required: true })
], Toggler.prototype, "toggle", void 0);
__decorate([
    Emit("toggle")
], Toggler.prototype, "toggleHandler", null);
Toggler = __decorate([
    Component({
        components: { Icon },
    })
], Toggler);
export default Toggler;
