import { __decorate } from "tslib";
import { Component, Emit, Prop, VModel, Vue } from "@/types";
import "./dropdown.scss";
import { Button, Icon, Input } from "@/ui-kit";
let DropDown = class DropDown extends Vue {
    constructor() {
        super(...arguments);
        this.isVisible = false;
    }
    get selected() {
        return this.options.filter((item) => item.id === this.select)[0];
    }
    onCloseClick() {
        this.isVisible = false;
    }
    handleClickVariant({ id, name, ...args }) {
        return;
    }
};
__decorate([
    VModel({ type: [String, Number], required: true })
], DropDown.prototype, "select", void 0);
__decorate([
    Prop({
        default: () => [],
        type: Array,
    })
], DropDown.prototype, "options", void 0);
__decorate([
    Prop({ type: String, default: "" })
], DropDown.prototype, "label", void 0);
__decorate([
    Emit("handleClickVariant")
], DropDown.prototype, "handleClickVariant", null);
DropDown = __decorate([
    Component({
        components: { Input, Button, Icon },
    })
], DropDown);
export default DropDown;
