import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class PassEventsFilterModule {
    constructor() {
        this.passEventsFilter = {};
        this.axiosError = null;
    }
    setData(passEventsFilter) {
        this.passEventsFilter = passEventsFilter;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.passEventsFilter;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.passEventsFilter.get(options);
            this.setData(data);
        }
        catch (error) {
            this.setError(error);
        }
    }
    async add(passEventsFilter) {
        try {
            await service.passEventsFilter.add(passEventsFilter);
            return [true];
        }
        catch (error) {
            this.setError(error);
            return [false, error];
        }
    }
}
__decorate([
    State()
], PassEventsFilterModule.prototype, "passEventsFilter", void 0);
__decorate([
    State()
], PassEventsFilterModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], PassEventsFilterModule.prototype, "setData", null);
__decorate([
    Mutation()
], PassEventsFilterModule.prototype, "setError", null);
__decorate([
    Getter()
], PassEventsFilterModule.prototype, "list", null);
__decorate([
    Getter()
], PassEventsFilterModule.prototype, "errorList", null);
__decorate([
    Action()
], PassEventsFilterModule.prototype, "get", null);
__decorate([
    Action()
], PassEventsFilterModule.prototype, "add", null);
