import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
export class Holidays extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "holidays";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(this.baseURL, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async add(holiday) {
        try {
            await this.$post(this.baseURL, holiday);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async edit(holiday) {
        try {
            await this.$put(`${this.baseURL}/${holiday.id}`, holiday);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    async delete(holidayId) {
        try {
            await this.$delete(`${this.baseURL}/${holidayId}`);
            return Promise.resolve(true);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
