import { Auth } from "@/helpers/Auth";
import { BaseSocket } from "@/store/modules/sockets/baseSocket";
import { checkEndUrlConfig } from "@/helpers/checkEndUrlConfig";
export class PhotoVerificationSocket extends BaseSocket {
    constructor(Socket, filter) {
        super(Socket);
        const filterReaders = filter ? `&filter=${filter}` : "";
        this.apiUrl = `${checkEndUrlConfig(Auth.getWebGatewayUriForSocket)}PassEventsService?accessToken=${Auth.getToken}${filterReaders}`;
    }
}
