const addSeconds = (val) => {
    if (typeof val === "string") {
        const length = val.length;
        if (length === 2) {
            return `${val}:00:00`;
        }
        if (length === 3) {
            return `${val}00:00`;
        }
        if (length === 4) {
            return `${val}0:00`;
        }
        if (length === 5) {
            return `${val}:00`;
        }
    }
    return val;
};
export default addSeconds;
