import { __decorate } from "tslib";
import { Action, Getter, Mutation, State } from "@/types";
import { service } from "@/services";
export class ConfigurationsModule {
    constructor() {
        this.configurations = [];
        this.count = 0;
        this.axiosError = null;
    }
    setData(configurations) {
        this.configurations = configurations;
    }
    setTotal(count) {
        this.count = count;
    }
    setError(error) {
        this.axiosError = error;
    }
    get list() {
        return this.configurations;
    }
    get total() {
        return this.count;
    }
    get errorList() {
        return this.axiosError;
    }
    async get(options) {
        try {
            const data = await service.configurations.get(options);
            this.setData(data.items);
            this.setTotal(data.total);
        }
        catch (error) {
            this.setError(error);
        }
    }
    async add(configurations) {
        try {
            await service.configurations.add(configurations);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async edit(configurations) {
        try {
            await service.configurations.edit(configurations);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async delete(configurationId) {
        try {
            await service.configurations.delete(configurationId);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
    async setActiveConfiguration(configurationId, isActive) {
        try {
            await service.configurations.setActive(configurationId, isActive);
            return true;
        }
        catch (error) {
            this.setError(error);
            return false;
        }
    }
}
__decorate([
    State()
], ConfigurationsModule.prototype, "configurations", void 0);
__decorate([
    State()
], ConfigurationsModule.prototype, "count", void 0);
__decorate([
    State()
], ConfigurationsModule.prototype, "axiosError", void 0);
__decorate([
    Mutation()
], ConfigurationsModule.prototype, "setData", null);
__decorate([
    Mutation()
], ConfigurationsModule.prototype, "setTotal", null);
__decorate([
    Mutation()
], ConfigurationsModule.prototype, "setError", null);
__decorate([
    Getter()
], ConfigurationsModule.prototype, "list", null);
__decorate([
    Getter()
], ConfigurationsModule.prototype, "total", null);
__decorate([
    Getter()
], ConfigurationsModule.prototype, "errorList", null);
__decorate([
    Action()
], ConfigurationsModule.prototype, "get", null);
__decorate([
    Action()
], ConfigurationsModule.prototype, "add", null);
__decorate([
    Action()
], ConfigurationsModule.prototype, "edit", null);
__decorate([
    Action()
], ConfigurationsModule.prototype, "delete", null);
