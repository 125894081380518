import { BasicService } from "@/services/basic";
import { createGetUrlWithOptions } from "@/helpers/services";
import { Auth } from "@/helpers/Auth";
import { checkEndUrlConfig } from "@/helpers/checkEndUrlConfig";
export class SysLog extends BasicService {
    constructor(axios) {
        super(axios);
        this.baseURL = "SystemEvents";
    }
    async get(options) {
        try {
            const url = createGetUrlWithOptions(`${checkEndUrlConfig(Auth.getWebGatewayUri)}api/${this.baseURL}`, options);
            const { data } = await this.$get(url);
            return Promise.resolve(data);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
}
