import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import "./configurations.scss";
import { Checkbox, Table, Radio } from "@/ui-kit";
import { AddConfiguration } from "@/components";
let Configurations = class Configurations extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [
            { name: this.$l["isActive"], class: "isActive", noSort: true },
            { name: this.$l["configuration"], class: "name", noSort: true },
        ];
        this.activeConfigId = null;
    }
    getInitActiveConfigId() {
        if (this.store.configurations.list.length) {
            const result = this.store.configurations.list;
            this.activeConfigId = result.filter((item) => item.isActive)[0].id;
        }
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    async setRadio(id, event) {
        /**
         * предотвратить появление иконки edit
         */
        event.stopPropagation();
        try {
            this.activeConfigId = id;
            await this.store.configurations.setActiveConfiguration(id, {
                isActive: true,
            });
        }
        catch (e) {
            console.log(e);
        }
    }
};
__decorate([
    Ref("table")
], Configurations.prototype, "table", void 0);
__decorate([
    Watch("store.configurations.list")
], Configurations.prototype, "getInitActiveConfigId", null);
Configurations = __decorate([
    Component({
        components: { Checkbox, AddConfiguration, Table, Radio },
    })
], Configurations);
export default Configurations;
