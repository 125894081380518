import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Button, Checkbox, Column, DropDown, Input, Modal, Table, Overlay, Radio, SearchApi, } from "@/ui-kit";
import "./addConfiguration.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
let AddConfiguration = class AddConfiguration extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.photoVerify = [
            {
                id: 0,
                text: this.$l["notReaders"],
            },
            {
                id: 1,
                text: this.$l["twoReaders"],
            },
            {
                id: 2,
                text: this.$l["fourReaders"],
            },
        ];
        this.filterSelect = [
            { id: 8, name: "Температура" },
            { id: 9, name: this.$l["columnFilterMask"] },
        ];
        this.modalData = {
            name: "",
            verificationType: 0,
            firstReaderId: null,
            secondReaderId: null,
            thirdReaderId: null,
            fourthReaderId: null,
            columns: [],
        };
        this.firstReaderName = "";
        this.secondReaderName = "";
        this.thirdReaderName = "";
        this.fourthReaderName = "";
        this.errors = {
            name: "",
            firstReaderId: "",
            secondReaderId: "",
            thirdReaderId: "",
            fourthReaderId: "",
            columns: "",
        };
        this.readersData = [];
        this.isAllFilterSelect = false;
    }
    async getReaderData() {
        try {
            await this.store.readers.get({
                top: "",
                skip: 0,
                orderby: "",
                filter: "",
            });
            this.readersData = this.store.readers.list;
        }
        catch (e) {
            console.log(e, "readersData");
        }
    }
    created() {
        this.getReaderData();
    }
    getReaderName(id) {
        if (id) {
            return this.readersData.filter((i) => i.id === id)[0].name;
        }
        return "";
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.getReaderData().then(() => {
                    this.firstReaderName = this.getReaderName(this.modalData.firstReaderId);
                    this.secondReaderName = this.getReaderName(this.modalData.secondReaderId);
                    this.thirdReaderName = this.getReaderName(this.modalData.thirdReaderId);
                    this.fourthReaderName = this.getReaderName(this.modalData.fourthReaderId);
                });
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                this.setRadio(data.verificationType);
                const isAll = this.modalData.columns.length === this.filterSelect.length;
                this.isAllFilterSelect = isAll;
            }
        }
    }
    handleFilterSelect(columns) {
        this.isAllFilterSelect = false;
        this.modalData.columns = columns;
        if (this.modalData.columns.length === this.filterSelect.length) {
            this.handleAllFilterSelect(true);
        }
    }
    handleAllFilterSelect(all) {
        this.modalData.columns = all
            ? Array.from(this.filterSelect, (item) => item.id)
            : [];
    }
    async successForm() {
        try {
            if (this.firstReaderName === "") {
                this.modalData.firstReaderId = null;
            }
            if (this.secondReaderName === "") {
                this.modalData.secondReaderId = null;
            }
            if (this.thirdReaderName === "") {
                this.modalData.thirdReaderId = null;
            }
            if (this.fourthReaderName === "") {
                this.modalData.fourthReaderId = null;
            }
            await (this.currDialog?.name === "save"
                ? this.editConfigurations()
                : this.addConfigurations());
        }
        catch (e) {
            console.log(e);
        }
    }
    getFirstReader(reader) {
        this.modalData.firstReaderId = reader.id;
    }
    getSecondReader(reader) {
        this.modalData.secondReaderId = reader.id;
    }
    getThirdReader(reader) {
        this.modalData.thirdReaderId = reader.id;
    }
    getFourReader(reader) {
        this.modalData.fourthReaderId = reader.id;
    }
    setRadio(id) {
        if (id === 0) {
            this.modalData.firstReaderId = null;
            this.modalData.secondReaderId = null;
            this.modalData.thirdReaderId = null;
            this.modalData.fourthReaderId = null;
            this.firstReaderName = "";
            this.secondReaderName = "";
            this.thirdReaderName = "";
            this.fourthReaderName = "";
            this.errors.firstReaderId = "";
            this.errors.secondReaderId = "";
            this.errors.thirdReaderId = "";
            this.errors.fourthReaderId = "";
        }
        if (id === 1) {
            this.errors.thirdReaderId = "";
            this.errors.fourthReaderId = "";
            this.modalData.thirdReaderId = null;
            this.modalData.fourthReaderId = null;
            this.thirdReaderName = "";
            this.fourthReaderName = "";
        }
        this.modalData.verificationType = id;
    }
    async addConfigurations() {
        const success = await this.store.configurations.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editConfigurations() {
        const success = await this.store.configurations.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
};
__decorate([
    Emit("success")
], AddConfiguration.prototype, "addConfigurations", null);
__decorate([
    Emit("success")
], AddConfiguration.prototype, "editConfigurations", null);
__decorate([
    Watch("store.validationField.valid")
], AddConfiguration.prototype, "isValid", null);
AddConfiguration = __decorate([
    Component({
        components: {
            Overlay,
            Column,
            DropDown,
            Checkbox,
            Button,
            Input,
            Modal,
            Table,
            Radio,
            SearchApi,
        },
    })
], AddConfiguration);
export default AddConfiguration;
