import { __decorate } from "tslib";
import { Component, Prop, Vue, Emit, Watch, useModule, Ref, } from "@/types";
import "./multiselect.scss";
import { Column, Icon, Checkbox, Input, Loader } from "@/ui-kit";
import { debounce } from "@/helpers/decorators/debounce";
import { concatFilters } from "@/helpers";
let MultiSelect = class MultiSelect extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.chipsList = [];
        this.searching = "";
        this.list = [];
        this.loading = true;
        this.activeItem = null;
    }
    async initData() {
        if (!this.listToEditValue) {
            this.chipsList = [];
            return;
        }
        if (this.includeFiltration !== "") {
            try {
                await this.store[this.moduleApi].get({
                    skip: 0,
                    filter: `${this.includeFiltration} ${this.isFilteredByOrg}`,
                });
                this.store[this.moduleApi].list.forEach((item) => {
                    const { id, ...args } = item;
                    this.chipsList = [
                        ...this.chipsList,
                        { id, [this.chipName]: args[this.chipName], ...args },
                    ];
                });
            }
            catch (e) {
                console.log(e);
            }
        }
    }
    async getData() {
        if (this.searching !== "") {
            try {
                await this.store[this.moduleApi].get({
                    skip: 0,
                    top: this.top,
                    filter: `${this.contained} ${this.excludeFiltration} ${this.isFilteredByOrg}`,
                });
                this.list = this.store[this.moduleApi].list;
            }
            catch (e) {
                console.log(e, "searching");
            }
            finally {
                this.loading = false;
            }
            return;
        }
        this.loading = true;
    }
    async reloadFilter() {
        try {
            await this.getData();
            this.getChipsHandler();
        }
        catch (e) {
            console.log("reload");
        }
    }
    get excludeFiltration() {
        let list = "";
        this.chipsList.forEach((item) => {
            const itemExclude = item[this.filterKey];
            const currentLastItem = this.chipsList[this.chipsList.length - 1];
            const checkForLastSpaces = item === currentLastItem ? " " : "";
            const isTypeString = this.typeFilter === "string";
            const filterItem = isTypeString ? `'${itemExclude}'` : itemExclude;
            list += `${checkForLastSpaces} and ${this.filterKey} ne ${filterItem}`;
        });
        return list;
    }
    get includeFiltration() {
        let list = "";
        if (this.listToEditValue?.length) {
            this.listToEditValue.forEach((item) => {
                const itemInclude = item;
                const checkForFirstItem = item === this.listToEditValue?.[0] ? "" : " or";
                const isTypeString = this.typeFilter === "string";
                const filterItem = isTypeString ? `'${itemInclude}'` : itemInclude;
                list += `${checkForFirstItem} ${this.filterKey} eq ${filterItem}`;
            });
        }
        return list;
    }
    get contained() {
        let contain = `contains(${this.filterSearchValue}, '${this.searching}')`;
        if (this.filterSearchValue === "surname") {
            contain = concatFilters(this.searching);
        }
        return encodeURIComponent(contain);
    }
    get isFilteredByOrg() {
        return this.byOrganization
            ? `and organization eq ${this.store.orgList.currentOrg?.id}`
            : "";
    }
    searchFocusHandler(e) {
        if (e.target.closest(".multiselect__selections")) {
            return;
        }
        this.inputRef.$refs.inputRef.focus();
    }
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    removeItem(itemToRemove) {
        this.chipsList = this.chipsList.filter((item) => {
            if (itemToRemove?.id) {
                return item.id !== itemToRemove.id;
            }
            if ("name" in itemToRemove) {
                return item.name !== itemToRemove.name;
            }
        });
    }
    removeAll() {
        this.searching = "";
        this.chipsList = [];
    }
    closeSearchHandler() {
        this.searching = "";
    }
    getChipsHandler(item) {
        this.loading = true;
        if (item) {
            this.chipsList = [...this.chipsList, item];
            this.searching = "";
        }
        return this.chipsList;
    }
};
__decorate([
    Ref("inputRef")
], MultiSelect.prototype, "inputRef", void 0);
__decorate([
    Prop({ required: true, type: String })
], MultiSelect.prototype, "moduleApi", void 0);
__decorate([
    Prop({ required: false, type: Array, default: null })
], MultiSelect.prototype, "listToEditValue", void 0);
__decorate([
    Prop({ type: String, default: "" })
], MultiSelect.prototype, "placeholder", void 0);
__decorate([
    Prop({ default: "name", type: String })
], MultiSelect.prototype, "filterSearchValue", void 0);
__decorate([
    Prop({ default: "id", type: String })
], MultiSelect.prototype, "filterKey", void 0);
__decorate([
    Prop({ default: "number", type: String })
], MultiSelect.prototype, "typeFilter", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], MultiSelect.prototype, "byOrganization", void 0);
__decorate([
    Prop({ default: "name", type: String })
], MultiSelect.prototype, "chipName", void 0);
__decorate([
    Prop({ default: 10, type: Number })
], MultiSelect.prototype, "top", void 0);
__decorate([
    Watch("listToEditValue", { immediate: true })
], MultiSelect.prototype, "initData", null);
__decorate([
    debounce,
    Watch("searching")
], MultiSelect.prototype, "getData", null);
__decorate([
    Watch("chipsList")
], MultiSelect.prototype, "reloadFilter", null);
__decorate([
    Emit("getChipsHandler")
], MultiSelect.prototype, "getChipsHandler", null);
MultiSelect = __decorate([
    Component({
        components: { Loader, Input, Icon, Column, Checkbox },
    })
], MultiSelect);
export default MultiSelect;
