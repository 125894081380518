import { __decorate } from "tslib";
import { Getter, Mutation, State } from "@/types";
import { Storage } from "@/helpers/storage";
import { Vue } from "vue-property-decorator";
export class OrganizationDropdownModule {
    constructor() {
        this.organization = Storage.fetch("organization") ?? {};
    }
    setCurrentOrg({ id, name, accessSchedule }) {
        Vue.set(this.organization, "id", id);
        Vue.set(this.organization, "name", name);
        Vue.set(this.organization, "accessSchedule", accessSchedule);
        Storage.install("organization", this.organization);
    }
    resetOrg() {
        this.organization = {};
    }
    get currentOrg() {
        return this.organization;
    }
}
__decorate([
    State()
], OrganizationDropdownModule.prototype, "organization", void 0);
__decorate([
    Mutation()
], OrganizationDropdownModule.prototype, "setCurrentOrg", null);
__decorate([
    Mutation()
], OrganizationDropdownModule.prototype, "resetOrg", null);
__decorate([
    Getter()
], OrganizationDropdownModule.prototype, "currentOrg", null);
