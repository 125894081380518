export function mask(value) {
    return {
        time: () => {
            const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
            const minutes = [/[0-5]/, /[0-9]/];
            return [...hours, ":", ...minutes];
        },
        date: () => {
            const dd = [/[0-3]/, value.charAt(0) === "3" ? /[0-1]/ : /[0-9]/];
            return [...dd, ".", "##", ".####"];
        },
        phone: () => "# (###) ###-##-##",
        ipAddress: () => "##.###.###.##",
        temperature: () => {
            const hours = [/[1-4]/, value.charAt(0) === "5" ? /[0-3]/ : /[0-9]/];
            const minutes = [/[0-9]/];
            return [...hours, ".", ...minutes];
        },
    };
}
