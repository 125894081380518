import { __decorate } from "tslib";
import { Component, Emit, useModule, Vue, Watch } from "@/types";
import { Column, Button, Input, Modal } from "@/ui-kit";
import "./addSubdivision.scss";
import { formObjectHelper } from "@/helpers/formDataHelper";
import { Storage } from "@/helpers/storage";
import SearchApi from "@/ui-kit/SearchApi/SearchApi.vue";
let AddSubdivision = class AddSubdivision extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.modalData = {
            name: "",
            accessSchedule: -1,
            organization: this.store.orgList.currentOrg?.id,
        };
        this.errors = {
            name: "",
            accessSchedule: "",
        };
        this.accessSchedulesData = [];
    }
    async created() {
        try {
            await this.store.accessSchedules.get({
                top: "",
                skip: 0,
                orderby: "",
                filter: "",
            });
            this.accessSchedulesData = this.store.accessSchedules.list;
        }
        catch (e) {
            console.log(e, "accessPointsData");
        }
    }
    mounted() {
        if (this.currDialog?.data) {
            const { name, data } = this.currDialog;
            if (name === "save") {
                this.modalData = {
                    ...formObjectHelper(this.modalData, data),
                };
                this.modalData.id = data.id;
            }
        }
    }
    currentOrgChange() {
        if (!this.currDialog?.data) {
            const getIdOrg = Storage.fetch("organization");
            if (getIdOrg) {
                this.modalData.accessSchedule = getIdOrg["accessSchedule"];
            }
        }
    }
    isValid() {
        this.errors = {
            ...formObjectHelper(this.errors, this.store.validationField.valid),
        };
    }
    get currDialog() {
        return this.store.dialog.curr;
    }
    setOrganization(result) {
        this.modalData["accessSchedule"] = result;
    }
    async addSubdivision() {
        const success = await this.store.subdivisions.add({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async editSubdivision() {
        const success = await this.store.subdivisions.edit({
            ...this.modalData,
        });
        if (success) {
            await this.store.notification.addNotification({
                type: "success",
                message: this.$l["success"],
            });
        }
        return success;
    }
    async successForm() {
        try {
            await (this.currDialog?.name === "save"
                ? this.editSubdivision()
                : this.addSubdivision());
        }
        catch (e) {
            console.log(e, "successForm");
        }
    }
};
__decorate([
    Watch("store.organizationDropdown.currentOrg", { immediate: true })
], AddSubdivision.prototype, "currentOrgChange", null);
__decorate([
    Watch("store.validationField.valid")
], AddSubdivision.prototype, "isValid", null);
__decorate([
    Emit("success")
], AddSubdivision.prototype, "addSubdivision", null);
__decorate([
    Emit("success")
], AddSubdivision.prototype, "editSubdivision", null);
AddSubdivision = __decorate([
    Component({
        components: { SearchApi, Column, Button, Input, Modal },
    })
], AddSubdivision);
export default AddSubdivision;
