import { __decorate } from "tslib";
import { Component, Ref, useModule, Vue, Watch } from "@/types";
import { Table } from "@/ui-kit";
import { AddSubdivision } from "@/components";
import "./subdivision.scss";
let Subdivisions = class Subdivisions extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.headerData = [{ name: "Подразделение", class: "name" }];
    }
    removeSubdivision(val) {
        if (val?.name === "remove") {
            let confirm = false;
            this.store.dialog.setDetails({
                callback: () => {
                    return this.store.subdivisions
                        .delete({ id: val.data.id, confirm: confirm })
                        .then((isSuccess) => {
                        if (isSuccess) {
                            this.reload(true);
                        }
                        if (this.store.subdivisions.errorList?.response?.status === 449) {
                            confirm = true;
                        }
                        return isSuccess;
                    });
                },
            });
        }
    }
    get showDialog() {
        return this.store.dialog.curr?.type === "form";
    }
    async reload(success) {
        if (success) {
            try {
                await this.table.updatePage();
            }
            catch (e) {
                console.log(e);
            }
        }
    }
};
__decorate([
    Ref("table")
], Subdivisions.prototype, "table", void 0);
__decorate([
    Watch("store.dialog.curr")
], Subdivisions.prototype, "removeSubdivision", null);
Subdivisions = __decorate([
    Component({
        components: { AddSubdivision, Table },
    })
], Subdivisions);
export default Subdivisions;
