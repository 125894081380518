import { createService } from "@/helpers/services";
import { client } from "./basic/axios";
/**Administration**/
import { Readers } from "@/services/administration/readers";
import { Zones } from "@/services/administration/zones";
import { AccessPoints } from "@/services/administration/access-points";
import { Users } from "@/services/administration/users";
import { UsersLogin } from "@/services/administration/users/usersLogin";
import { AccessSchedules } from "@/services/administration/access-schedules";
import { Organization } from "@/services/administration/organization";
/**FillingCabinet**/
import { Subdivision } from "@/services/filing-cabinet/subdivision";
import { Employees } from "@/services/filing-cabinet/employees";
import { GuestPasses } from "@/services/filing-cabinet/guestPasses";
/**Monitoring**/
import { Configurations } from "@/services/monitoring/configuration";
import { PassLog, PassMethods } from "@/services/monitoring/passLog";
import { PassEventsFilter } from "@/services/monitoring/passLog/passEventsFilter";
import { SysLog } from "@/services/monitoring/sysLog/sysLog";
import { SystemEventsFilter } from "@/services/monitoring/sysLog";
import { SystemEvents } from "@/services/monitoring/sysLog/systemEvents";
import { SystemEventsLevels } from "@/services/monitoring/sysLog/systemEventsLevels";
import { SystemEventsSources } from "@/services/monitoring/sysLog/systemEventsSources";
/**TimeAttendance**/
import { DayIntervals } from "@/services/time-attendance/day-intervals";
import { WorkSchedules } from "@/services/time-attendance/work-schedules";
import { Holidays } from "@/services/time-attendance/holidays";
import { Adjustments } from "@/services/time-attendance/adjustments";
import { Reports } from "@/services/time-attendance/reports";
import { Absences } from "@/services/time-attendance/absences";
export const service = {
    //Administration//
    readers: createService(Readers, client),
    zones: createService(Zones, client),
    accessPoints: createService(AccessPoints, client),
    users: createService(Users, client),
    usersLogin: createService(UsersLogin, client),
    accessSchedules: createService(AccessSchedules, client),
    organization: createService(Organization, client),
    //FilingCabinet//
    subdivision: createService(Subdivision, client),
    employees: createService(Employees, client),
    guestPasses: createService(GuestPasses, client),
    //Monitoring//
    configurations: createService(Configurations, client),
    passLog: createService(PassLog, client),
    passMethods: createService(PassMethods, client),
    passEventsFilter: createService(PassEventsFilter, client),
    sysLog: createService(SysLog, client),
    systemEventsFilter: createService(SystemEventsFilter, client),
    systemEvents: createService(SystemEvents, client),
    systemEventsLevels: createService(SystemEventsLevels, client),
    systemEventsSources: createService(SystemEventsSources, client),
    //TimeAttendance//
    dayIntervals: createService(DayIntervals, client),
    workSchedules: createService(WorkSchedules, client),
    holidays: createService(Holidays, client),
    adjustments: createService(Adjustments, client),
    reports: createService(Reports, client),
    absences: createService(Absences, client),
};
