import { __decorate } from "tslib";
import { Component, useModule, Vue } from "@/types";
import { Button, DropDown, Icon, Tooltip } from "@/ui-kit";
import { Storage } from "@/helpers/storage";
let HeaderDropdownsStorage = class HeaderDropdownsStorage extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        //id: 1 - Организация по умолчанию
        this.organization = [{ id: 1, name: "", accessSchedule: 1 }];
        this.holidaysType = [
            { id: 1, name: "Текущий год" },
            { id: 2, name: "Следующий год" },
        ];
        this.selectedHoliday = this.holidaysType[0].id;
        this.selectedOrganization = this.organization[0].id;
    }
    created() {
        if (this.initDropdownOrganization) {
            this.getOrganization();
        }
        if (this.$route.name === "Holidays") {
            const isHolidaysSave = Storage.fetch("holidays")
                ? Storage.fetch("holidays").id
                : this.selectedHoliday;
            this.setFilterDate({ id: isHolidaysSave, name: "" });
            this.selectedHoliday = isHolidaysSave;
        }
    }
    async getOrganization() {
        try {
            await this.store.organization.getBaseForUser({
                skip: 0,
            });
            this.organization = this.store.organization.listForUser;
            if (this.organization.length) {
                if (!Storage.fetch("organization")) {
                    this.store.orgList.setCurrentOrg({
                        id: this.organization[0].id,
                        name: this.organization[0].name,
                        accessSchedule: this.organization[0].accessSchedule,
                    });
                }
                if (Storage.fetch("organization")) {
                    const orgId = this.organization.map((item) => item.id);
                    if (!orgId.includes(Storage.fetch("organization").id)) {
                        this.store.orgList.setCurrentOrg({
                            id: this.organization[0].id,
                            name: this.organization[0].name,
                            accessSchedule: this.organization[0].accessSchedule,
                        });
                    }
                }
            }
            this.selectedOrganization = this.store.orgList.currentOrg?.id;
        }
        catch (e) {
            console.log(e, "organization");
        }
    }
    setFilterDate({ id, name }) {
        this.store.supFilter.setSupportFilter(`period eq ${id} `);
        Storage.install("holidays", {
            id: id,
        });
        this.selectedHoliday = id;
    }
    setFilterSelect({ id, name, accessSchedule }) {
        this.selectedOrganization = id;
        this.store.orgList.setCurrentOrg({ id, name, accessSchedule });
    }
    get initDropdownOrganization() {
        const parentRoute = this.$route.matched[0].name;
        return parentRoute === "FilingCabinet" || parentRoute === "TimeAttendance";
    }
    beforeDestroy() {
        this.store.supFilter.setSupportFilter("");
    }
};
HeaderDropdownsStorage = __decorate([
    Component({
        components: { DropDown, Button, Icon, Tooltip },
    })
], HeaderDropdownsStorage);
export default HeaderDropdownsStorage;
