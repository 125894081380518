import { __decorate } from "tslib";
import "./table-ws.scss";
import { Component, Prop, useModule, Watch, Ref, Vue, } from "@/types";
import { Icon, Pagination, Loader } from "@/ui-kit";
import { Confirm } from "@/components";
let Table = class Table extends Vue {
    constructor() {
        super(...arguments);
        this.store = useModule(this.$store);
        this.wsDataArray = [];
    }
    /**
     * Уведомления
     * @param message - TErrorsApp
     */
    notification(message) {
        this.store.notification.addNotification({
            type: "error",
            message: message,
        });
    }
    /**
     * Отрисовка таблицы WS или HTTPS - Рефактор нужен
     */
    get wsExist() {
        return this.wsData && Object.keys(this.wsData).length > 0;
    }
    /**
     * Данные api
     */
    /* eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types */
    resetWsData(newVal, OldVal) {
        if (!newVal) {
            this.wsDataArray = [];
        }
    }
    get list() {
        if (this.wsExist) {
            this.wsDataArray = [...this.wsDataArray, this.wsData].sort((a, b) => b.id - a.id);
        }
        return this.wsDataArray;
    }
};
__decorate([
    Ref("content")
], Table.prototype, "content", void 0);
__decorate([
    Prop({ type: Array, default: () => [], required: true })
], Table.prototype, "headerData", void 0);
__decorate([
    Prop({ type: [Object], default: null })
], Table.prototype, "wsData", void 0);
__decorate([
    Prop({ default: false, type: [Number, Boolean] })
], Table.prototype, "staticHeight", void 0);
__decorate([
    Watch("wsData", { immediate: true })
], Table.prototype, "resetWsData", null);
Table = __decorate([
    Component({
        components: { Loader, Confirm, Pagination, Icon },
    })
], Table);
export default Table;
