export function removeNull(data) {
    return Object.entries(data).reduce((acc, [key, val]) => {
        if (val === null) {
            delete acc[key];
        }
        else {
            acc[key] = val;
        }
        return acc;
    }, {});
}
